import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Hero from "./components/Hero";
import FAQsection from "./components/FAQsection";
import PlansSection from "./components/PlansSection";
import PricingLayout from "../../layouts/PricingLayout";
import PricingProvider from "./components/PricingProvider";
import Container from "../../components/Container/Container";
import EnterprisePackage from "./components/EnterprisePackage";
import SectionsStack from "../../components/SectionsStack/SectionsStack";
import { getUserPlans } from "../../redux/reducers/plansReducer";
import { getChangeSubscriptionsServer, getUserPlansServer } from "../../redux/actions/plansActions";

const PricingPage3 = () => {
  const dispatch = useDispatch();

  const plans = useSelector(getUserPlans);
  const activePlans = plans.filter((item) => !item.isCancelled);

  useEffect(() => {
    dispatch(getUserPlansServer());
  }, []);

  useEffect(() => {
    if (!activePlans.length) return;
    dispatch(getChangeSubscriptionsServer({ userLicenseId: activePlans[0].userLicenseID }));
  }, [plans]);

  return (
    <PricingLayout>
      <PricingProvider>
        <Hero />
        <Container>
          <SectionsStack>
            <PlansSection />
            <EnterprisePackage />
            <FAQsection />
            <CountdownTimer />
          </SectionsStack>
        </Container>
      </PricingProvider>
    </PricingLayout>
  );
};

const CountdownTimer = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div className="elfsight-app-87ad73b9-371e-40fb-b5a7-914818521fac" data-elfsight-app-lazy></div>
    </div>
  );
};
export default PricingPage3;
