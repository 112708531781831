import styled from "styled-components";
import { brands } from "../../../mocks/pricing";

const PricingTrustedBrands = () => {
  return (
    <TrustedBrandWrapper>
      <span>Our&nbsp;trusted&nbsp;brands</span>
      <BrandRow>
        {brands.map(({ key, logo, path }) => (
          <a title={key} key={key} href={path} aria-label={`Go to ${key} site`} target="blank" rel="nofollow">
            {logo}
          </a>
        ))}
      </BrandRow>
    </TrustedBrandWrapper>
  );
};

const TrustedBrandWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  span {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
  }

  @media (max-width: 1299px) {
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: 599px) {
    gap: 12px;

    span {
      font-size: 16px;
    }
  }

  @media (max-width: 420px) {
    align-items: flex-start;

    span {
      font-size: 12px;
    }
  }
`;

const BrandRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 56px;

  & > a {
    display: flex;
  }

  @media (max-width: 1299px) {
    gap: 24px;
  }

  @media (max-width: 999px) {
    width: 100%;
    justify-content: space-between;
    gap: 24px;

    svg {
      height: 38px;
      width: auto;
    }
  }

  @media (max-width: 799px) {
    gap: 12px;

    svg {
      height: 32px;
    }
  }

  @media (max-width: 619px) {
    gap: 8px;

    svg {
      height: 22px;
    }
  }

  @media (max-width: 420px) {
    svg {
      height: 16px;
    }
  }
`;

export default PricingTrustedBrands;
