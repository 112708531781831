import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import CircularProgress from "../Icons/CircularProgress";
import { SignUpPopupTypes } from "../../types/signUpPopupTypes";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { redirectToProject } from "../../redux/actions/projectAction";
import { getAutoSaveLoading } from "../../redux/reducers/projectReducer";
import { getFaceswapLoading } from "../../redux/reducers/faceswapReducer";
import { getHumansProjectLoading } from "../../redux/reducers/humansProjectReducer";
import { getGenerateVideoLoading } from "../../redux/reducers/videoTranslationReducer";
import { getSpeech2SpeechIsLoading, getSpeechTranslationIsLoading } from "../../redux/reducers/actorReducer";

const getLoadingFromType = (type: string) => {
  switch (type) {
    case "AIHumans":
      return getHumansProjectLoading;
    case "Text2Speech":
      return getAutoSaveLoading;
    case "Speech2Speech":
      return getSpeech2SpeechIsLoading;
    case "SpeechTranslation":
      return getSpeechTranslationIsLoading;
    case "VideoTranslation":
      return getGenerateVideoLoading;
    case "faceSwap":
      return getFaceswapLoading;
    case "talkingAvatar":
      return getFaceswapLoading;
    default:
      return () => null;
  }
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const SignUpPopup = ({ open, onClose }: Props) => {
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    type,
    link: { projectName, projectId },
    isClick = false,
    projectSave,
  }: SignUpPopupTypes = useSelector(getPrefilled);
  const isLoading = useSelector(getLoadingFromType(type));

  const handleSignUpPage = () => {
    if (projectSave) projectSave?.();
    else {
      navigate("/signup");
      onClose();
    }

    dispatch(redirectToProject({ projectName, projectId }));
  };

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const handleCloseClick = () => {
    console.log("Cannot close this popup");
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      if (!isLoading && !isClick) {
        navigate("/signup");
        handleClose();
      }
    }

    isFirstRender.current = false;
  }, [isLoading, isClick]);

  return (
    <Modal
      className="templates"
      open={open}
      onClose={handleCloseClick}
      closeIcon={false}
      maxWidth={420}
      maxHeight={704}
    >
      <Title>Signup to continue</Title>
      <Description>Right now you are using free trial access, signup to get a full-app access</Description>
      <ListTitle>With a free account you can:</ListTitle>
      <List>
        {lists.map((list, index) => (
          <ListItem key={index}>
            <Circle>
              <Checkmark />
            </Circle>
            <ListItemText>{list}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Button
        onClick={handleSignUpPage}
        disabled={isLoading as boolean}
        text={isLoading ? <CircularProgress /> : "Signup for free"}
      />
    </Modal>
  );
};

const lists = [
  "Save your projects",
  "Download Voice overs (Up to 5 minutes)",
  "Download Videos (Up to 5 minutes)",
  "Download Images (Up to 50 generations)",
  "Unlock features",
];

const Title = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText};

  span {
    text-decoration: underline;
    color: ${({ theme }) => theme.activeMenu};
    cursor: pointer;
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.primaryText};
  margin: 25px 0;
  font-size: 15px;
  line-height: 20px;
`;

const ListTitle = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 17px;
  line-height: 20px;
  margin: 10px 0 20px;
`;

const List = styled.ul`
  margin-bottom: 25px;
`;

const ListItem = styled.div`
  display: flex;
  column-gap: 10px;
  margin: 10px 0;
`;

const ListItemText = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 15px;
  line-height: 20px;
`;

const Circle = styled.div`
  position: relative;
  background: #8cc63e;
  border-radius: 50%;
  width: 18px;
  height: 18px;
`;

const Checkmark = styled.div`
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 35%;
  top: 45%;
  height: 6px;
  width: 3px;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
`;

export default SignUpPopup;
