import { useSelector } from "react-redux";
import { getCredits } from "../../../redux/reducers/profileReducer";
import styled from "styled-components";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { pages } from "../../../lib/routeUtils";

export enum CreditsServiceType {
  "AI_HUMANS" = "AI Humans",
  "AI_VOICES" = "AI Voices",
  "AI_IMAGES" = "AI Images",
  "AI_VIDEOS" = "AI Videos",
  "FACEFORM" = "FaceForm",
  "VIDEO_TRANSLATE" = "Video Translate",
}

export const CreditsTable = () => {
  const navigate = useNavigate();
  const {
    aiHumanMax,
    aiHumanUsed,
    aiVoiceMax,
    aiVoiceUsed,
    aiVideoMax,
    aiVideoUsed,
    faceformMax,
    faceformUsed,
    aiImageMax,
    aiImageUsed,
    videoTranslateMax,
    videoTranslateUsed,
  } = useSelector(getCredits);

  const dailyCredits = {
    [CreditsServiceType.AI_HUMANS]: {
      max: aiHumanMax,
      used: aiHumanUsed,
    },
    [CreditsServiceType.AI_VOICES]: {
      max: aiVoiceMax,
      used: aiVoiceUsed,
    },
    [CreditsServiceType.AI_IMAGES]: {
      max: aiImageMax,
      used: aiImageUsed,
    },
    [CreditsServiceType.AI_VIDEOS]: {
      max: aiVideoMax,
      used: aiVideoUsed,
    },
    [CreditsServiceType.FACEFORM]: {
      max: faceformMax,
      used: faceformUsed,
    },
    [CreditsServiceType.VIDEO_TRANSLATE]: {
      max: videoTranslateMax,
      used: videoTranslateUsed,
    },
  };

  return (
    <Wrapper>
      <MainCredits>
        <p>
          <span>{aiHumanMax - aiHumanUsed}</span>/{aiHumanMax} credits left under fair use
        </p>
        <p>Reset tomorrow</p>
        <Button text="Need more?" onClick={() => navigate(pages.pricingSubscriptions())} />
      </MainCredits>
      <InnerTable>
        {Object.keys(dailyCredits).map((key: string) => (
          <p key={key}>
            <span>
              {dailyCredits[key as CreditsServiceType].max - dailyCredits[key as CreditsServiceType].used}/
              {dailyCredits[key as CreditsServiceType].max}
            </span>{" "}
            {key} credits left
          </p>
        ))}
      </InnerTable>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainCredits = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 379px;
  background: ${({ theme }) => theme.creditsUpperBackground};

  p {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;

    span {
      font-size: 24px;
    }

    &:last-of-type {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: ${({ theme }) => theme.icon}aa;
    }
  }

  button {
    width: 118px;
    height: 32px;
    margin-top: 15px;
    border-radius: 5px;
    background: ${({ theme }) => theme.reviewBlue};
  }
`;

const InnerTable = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    padding: 7px 20px;
    border-top: 1px solid ${({ theme }) => theme.creditsUpperBackground};

    span {
      font-weight: 700;
    }
  }
`;
