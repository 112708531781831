import styled from "styled-components";
import { Fragment, useContext } from "react";

import SelectPlanButton from "./SelectPlanButton";
import SmartTooltip from "../../../components/Tooltip/SmartTooltip";
import Button, { Color } from "./Button";
import {
  creditsColumns,
  defaultColumns,
  dubbingTable,
  faceformTable,
  imageTable,
  labsTable,
  renderSpeedColumns,
  videoComparisonTable,
  videoTable,
  voicesTable,
} from "../../../mocks/plansTableMocks3";
import { PlanTypes } from "./PlanCard";
import { PricingContext } from "./PricingProvider";
import { IExternalLinks } from "../../../lib/routeUtils";
import { InfoIcon1818White } from "../../../components/Icons/Icons";

const PlansTableMobile = () => {
  const { isYearly } = useContext(PricingContext);

  return (
    <Table>
      <thead>
        <TableRow>
          {defaultColumns
            .slice(1)
            .map(({ header, yearlyPrice, monthlyPrice, yearlyTrigger, monthlyTrigger, planType, buttonColor }) => (
              <TableHeaderCell key={header}>
                <p className="th-title">{header}</p>
                <span className="th-price-button">
                  {isYearly ? (
                    <span className="th-price">${yearlyPrice}</span>
                  ) : (
                    <span className="th-price">${monthlyPrice}</span>
                  )}
                  {header !== "" ? (
                    header === "Free" ? (
                      <Button color={buttonColor as Color} linkPath={IExternalLinks.tryForFree} type="link">
                        Start for free
                      </Button>
                    ) : (
                      <SelectPlanButton
                        popupHash={isYearly ? yearlyTrigger! : monthlyTrigger!}
                        buttonColor={buttonColor}
                        planType={planType as PlanTypes}
                      />
                    )
                  ) : null}
                </span>
              </TableHeaderCell>
            ))}
        </TableRow>
      </thead>
      <tbody>
        <Credits>
          {creditsColumns.slice(0, 1).map(({ header, id }) => (
            <td key={id}>
              <p className="speedContentTitle">{header}</p>
            </td>
          ))}
        </Credits>
        <Credits>
          {creditsColumns.slice(1).map(({ header, tooltip, priority, id }) => (
            <td key={id}>
              <span className="speedContent mode">
                {header}
                {tooltip && <SmartTooltip title={tooltip} icon={<InfoIcon1818White />} />}
              </span>
              {priority && <p className="speedContent">{priority}</p>}
            </td>
          ))}
        </Credits>
        <RenderSpeed>
          {renderSpeedColumns.slice(0, 1).map(({ header, id }) => (
            <td key={id}>
              <p className="speedContentTitle">{header}</p>
            </td>
          ))}
        </RenderSpeed>
        <RenderSpeed>
          {renderSpeedColumns.slice(1).map(({ header, tooltip, priority, id }) => (
            <td key={id}>
              <span className="speedContent mode">
                {header}
                {tooltip && <SmartTooltip title={tooltip} icon={<InfoIcon1818White />} />}
              </span>
              {priority && <p className="speedContent">{priority}</p>}
            </td>
          ))}
        </RenderSpeed>
        <HeadingRow>
          <td>
            {voicesTable.heading.icon}
            {voicesTable.heading.title}
          </td>
        </HeadingRow>
        {voicesTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {voicesTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {voicesTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <HeadingRow>
          <td>
            {videoTable.heading.icon}
            {videoTable.heading.title}
          </td>
        </HeadingRow>
        {videoTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {videoTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {videoTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <HeadingRow>
          <td>
            {dubbingTable.heading.icon}
            {dubbingTable.heading.title}
          </td>
        </HeadingRow>
        {dubbingTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {dubbingTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {imageTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <HeadingRow>
          <td>
            {imageTable.heading.icon}
            {imageTable.heading.title}
          </td>
        </HeadingRow>
        {imageTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {imageTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {imageTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <HeadingRow>
          <td>
            {videoComparisonTable.heading.icon}
            {videoComparisonTable.heading.title}
          </td>
        </HeadingRow>
        {videoComparisonTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {videoComparisonTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {videoComparisonTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <HeadingRow>
          <td>
            {faceformTable.heading.icon}
            {faceformTable.heading.title}
          </td>
        </HeadingRow>
        {faceformTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {faceformTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {faceformTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <HeadingRow>
          <td>
            {labsTable.heading.icon}
            {labsTable.heading.title}
          </td>
        </HeadingRow>
        {labsTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {labsTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {labsTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <TableRow>
          {defaultColumns.slice(1).map(({ header, buttonColor, yearlyTrigger, monthlyTrigger, planType, id }) => (
            <TableCell key={id}>
              {header !== "Features" ? (
                header === "Free" ? (
                  <Button color={buttonColor as Color} linkPath={IExternalLinks.tryForFree} type="link">
                    Start for free
                  </Button>
                ) : (
                  <SelectPlanButton
                    popupHash={isYearly ? yearlyTrigger! : monthlyTrigger!}
                    buttonColor={buttonColor}
                    planType={planType as PlanTypes}
                  />
                )
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  display: none;

  @media (max-width: 767px) {
    border-spacing: 0;
    border: 1px solid #d2d2d9;

    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px 12px 0px 0px;
    background: #f0f0f3;
    overflow: hidden;

    tbody {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
`;

const TableRow = styled.tr`
  display: flex;
  width: 100%;

  th,
  td {
    border-spacing: 0;
    border-right: 1px solid #d2d2d9;

    width: 100%;

    :last-child {
      border-right: none;
    }
  }

  th {
    :last-child {
      background: #d8e9f6;
    }
  }
`;

const TableHeaderCell = styled.th`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .th-price-button {
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }

  .th-title {
    color: #191b1f;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .th-price {
    color: #6f7074;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }

  a {
    width: 100%;
  }

  .primaryButton {
    width: 100%;
    padding: 8px 7px;

    .primaryButtonText {
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
    }
  }

  @media (max-width: 420px) {
    .th-price {
      font-size: 10px;
    }
  }
`;

const Credits = styled.tr`
  border-spacing: 0;
  border-top: 1px solid #d2d2d9;

  width: 100%;
  display: flex;

  td {
    border-spacing: 0;
    border-right: 1px solid #d2d2d9;

    width: 100%;
    background: linear-gradient(0deg, #0063b4 10%, #6420ff 150%);
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    :first-child > .mode > .tooltipWrapper > .tooltip {
      right: unset;
      left: 0;
    }

    :nth-child(2) > .mode > .tooltipWrapper > .tooltip {
      right: unset;
      left: 0;
    }

    :last-child {
      border-right: none;
    }

    .speedContentTitle {
      align-self: center;
      flex-grow: 1;
    }

    .speedContent,
    .speedContentTitle {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      line-height: normal;
      position: relative;
    }

    .mode {
      max-width: calc(100% - 18px);
      font-size: 10px;

      .tooltipWrapper {
        margin-left: 4px;

        .tooltip {
          max-width: 200px;
        }

        @media (max-width: 420px) {
          right: -20px;
        }
      }
    }

    @media (max-width: 420px) {
      .speedContent {
        font-size: 10px;
      }
    }
  }
`;

const RenderSpeed = styled.tr`
  border-spacing: 0;
  border-top: 1px solid #d2d2d9;

  width: 100%;
  display: flex;

  td {
    border-spacing: 0;
    border-right: 1px solid #d2d2d9;

    width: 100%;
    background: linear-gradient(0deg, #0063b4 10%, #6420ff 150%);
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    :first-child > .mode > .tooltipWrapper > .tooltip {
      right: unset;
      left: 0;
    }

    :nth-child(2) > .mode > .tooltipWrapper > .tooltip {
      right: unset;
      left: 0;
    }

    :last-child {
      border-right: none;
    }

    .speedContentTitle {
      align-self: center;
      flex-grow: 1;
    }

    .speedContent,
    .speedContentTitle {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      line-height: normal;
      position: relative;
    }

    .mode {
      max-width: calc(100% - 18px);
      display: flex;
      align-items: center;
      gap: 4px;

      .tooltipWrapper {
        display: flex;

        .tooltip {
          max-width: 200px;
        }

        @media (max-width: 420px) {
          right: -20px;
        }
      }
    }

    @media (max-width: 420px) {
      .speedContent {
        font-size: 10px;
      }
    }
  }
`;

const TableCell = styled.td`
  border-spacing: 0;
  border-top: 1px solid #d2d2d9;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  justify-content: center;
  color: #191b1f;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;

  .checkedIcon {
    display: block;
    width: 15px;
    height: auto;
  }

  a {
    width: 100%;
  }

  .primaryButton {
    width: 100%;
    padding: 8px 7px;

    .primaryButtonText {
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const HeadingRow = styled.tr`
  width: 100%;

  td {
    border-spacing: 0;
    border-top: 1px solid #d2d2d9;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #eaeaf1;
    padding: 8px;

    color: #191b1f;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;

    svg {
      width: 16px;
      height: auto;
    }
  }
`;

const SubHeadingRow = styled.tr`
  width: 100%;

  td {
    border-spacing: 0;
    border-top: 1px solid #d2d2d9;

    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #f0f0f3;
    padding: 8px;

    color: #191b1f;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;

    svg {
      width: 18px;
      height: auto;
    }
  }
`;

export default PlansTableMobile;
