import styled from "styled-components";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import Modal from "../../../components/Modal/Modal";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { Text2Video } from "../../../types/text2Video";
import { getPrefilled } from "../../../redux/reducers/popupsReducer";
import { ArchiveTick, PopupUploadIcon } from "../../../components/Icons/Icons";
import { getVideoGalleryLoading } from "../../../redux/reducers/imagesReducer";
import { createGalleryVideoServer } from "../../../redux/actions/imagesActions";
import { SignUpPopupTypes } from "../../../types/signUpPopupTypes";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { downloadFile } from "../../../lib/downloadFile";
import { getProfile } from "../../../redux/reducers/profileReducer";

interface Props {
  open: boolean;
  onClose: () => void;
}

const Text2VideoPopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();

  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;
  const prefilled: Text2Video = useSelector(getPrefilled);
  const isLoading = useSelector(getVideoGalleryLoading);

  const [isCopied, setIsCopied] = useState(false);
  const [video, setVideo] = useState<Text2Video | null>({} as Text2Video);

  const handleSaveToAssets = () => {
    if (!video?.outputUrl) {
      toast.error("Image path not found");
      return;
    }

    dispatch(createGalleryVideoServer({ fileName: video.fileName, path: video?.outputUrl }));
  };

  const handleDownload = (e: any) => {
    if (!video?.id) return;

    if (isGuest) {
      const prefilled: SignUpPopupTypes = {
        type: "Video",
        link: {
          projectName: "ai-images",
          projectId: video?.id,
        },
      };

      dispatch(
        updatePopup({
          popup: Popups.text2VideoPopup,
          status: false,
        }),
      );
      dispatch(
        updatePopup({
          popup: Popups.signUpPopup,
          status: true,
          prefilled,
        }),
      );
      return;
    }

    downloadFile(e, video?.outputUrl as string);
  };

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const copyTextToClipboard = async () => {
    if ("clipboard" in navigator && video?.prompt) {
      return await navigator.clipboard.writeText(video?.prompt);
    } else {
      return document.execCommand("copy", true, video?.prompt);
    }
  };

  const handleCopyClick = () => {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setVideo(prefilled);
  }, [prefilled]);

  const size: { [key: string]: { maxWidth: number } } = {
    ["square"]: {
      maxWidth: 480,
    },
    ["vertical"]: {
      maxWidth: 330,
    },
    ["horizontal"]: {
      maxWidth: 792,
    },
  };

  return (
    <Modal
      maxWidth={size[video?.size ?? "square"]?.maxWidth}
      maxHeight={590}
      onClose={handleClose}
      open={open}
      closeIcon={false}
    >
      <Wrapper>
        <VideoWrapper>
          <StyledVideo
            src={video?.outputUrl}
            type={video?.size ?? "square"}
            preload="auto"
            autoPlay={true}
            loop={true}
          />

          {isLoading && (
            <Blur>
              <CircularProgress color="#009af7" />
            </Blur>
          )}
        </VideoWrapper>
        {video?.prompt && video?.prompt !== "-" && (
          <Prompt type={video.size ?? "square"} onClick={handleCopyClick}>
            <Tooltip
              text={isCopied ? "Copied!" : "Copy"}
              position="top"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
              reverseColor
              arrow
            />
            <div>{video?.prompt}</div>
          </Prompt>
        )}
        <ButtonWrapper>
          <DownloadButtonWrapper>
            <Button
              disabled={isLoading || !video?.id || !video?.outputUrl}
              icon={<PopupUploadIcon />}
              text="Download"
              onClick={handleDownload}
            />
          </DownloadButtonWrapper>
          {!video?.isView && (
            <SavedButtonWrapper>
              <Button
                icon={<ArchiveTick />}
                disabled={isLoading}
                text={isLoading ? "Loading..." : "Save to assets"}
                onClick={handleSaveToAssets}
                buttonTheme={ButtonThemes.Secondary}
              />
            </SavedButtonWrapper>
          )}
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  min-width: 400px;
`;

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledVideo = styled.video<{ type: string }>`
  cursor: pointer;
  max-width: 400px;
  height: 400px;
  width: 100%;
  object-fit: cover;

  ${({ type }) =>
    type === "vertical" &&
    `
      max-width: 250px;
    `}

  ${({ type }) =>
    type === "horizontal" &&
    `
      max-width: 711px;
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DownloadButtonWrapper = styled.div`
  margin-top: 16px;

  button svg {
    margin: 0px 10px 0px 0px;
  }
`;

const Prompt = styled.div<{ type: string }>`
  position: relative;
  max-width: 400px;
  margin: 4px auto;
  width: fit-content;

  & > div:last-of-type {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
    color: ${({ theme }) => theme.primaryText};
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    ${({ type }) =>
      type === "vertical" &&
      `
      max-width: 250px;
    `}
  }

  & > div:first-of-type {
    width: max-content;
  }

  &:hover > div:first-of-type {
    opacity: 1;
    visibility: visible;
  }
`;

const SavedButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button span {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  z-index: 2;
`;

export default Text2VideoPopup;
