import styled from "styled-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Tabs from "./components/Tabs";
import MyLibraryBar from "./components/MyLibraryBar";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import Navigation from "../../components/Navigation/Navigation";
import FaceSwapScreen from "./components/screens/FaceSwapScreen";
import TransformScreen from "./components/screens/TransformScreen";
import TalkingAvatarScreen from "./components/screens/TalkingAvatarScreen";
import ReplicateMediaScreen from "./components/screens/ReplicateMediaScreen";
import {
  getActorIdForAudio,
  getFaceswap,
  getFaceswapLoading,
  getFaceswapPageLoading,
  getFaceswapSource,
  getFaceswapSourceLoading,
  getFaceswapTarget,
  getFaceswapTargetLoading,
  getTextForAudio,
} from "../../redux/reducers/faceswapReducer";
import { BasePageLoader } from "../../components/Icons/Icons";
import { InfoIcon } from "../../components/Icons/FaceSwapIcons";
import { getProfile } from "../../redux/reducers/profileReducer";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";

export enum FaceswapProjectTab {
  "Transform" = "Transform",
  "Talking Avatar" = "Talking Avatar",
  "Faceswap" = "Faceswap",
  "Replicate Media" = "Replicate Media",
}

const tabs = [
  {
    label: "Transform",
    endAdornment: <InfoIcon />,
    tooltip: "Transform the face in an existing video completely with a new face by uploading a base image with face.",
  },
  {
    label: "Talking Avatar",
    endAdornment: <InfoIcon />,
    tooltip: "Convert any image of a face into a Talking Avatar.",
  },
  {
    label: "Faceswap",
    endAdornment: <InfoIcon />,
    tooltip: "Replace the face of a person in a photo or video with the face of another person.",
  },
  {
    label: "Replicate Media",
    endAdornment: <InfoIcon />,
    tooltip: "Turn any media into your own free copyright creation.",
  },
];

interface Props {
  activeTab: FaceswapProjectTab;
}

const FaceSwap = ({ activeTab }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);

  const project = useSelector(getFaceswap);
  const targetUrl = useSelector(getFaceswapTarget);
  const sourceUrl = useSelector(getFaceswapSource);
  const textFieldValue = useSelector(getTextForAudio);
  const selectVoice = useSelector(getActorIdForAudio);

  const isTargetLoading = useSelector(getFaceswapTargetLoading);
  const isSourceLoading = useSelector(getFaceswapSourceLoading);
  const projectLoading = useSelector(getFaceswapLoading);
  const pageLoading = useSelector(getFaceswapPageLoading);

  const { hasUnlimitedFaceswap, faceswapAllowed, faceswapUsed } = useSelector(getProfile);
  const credits = faceswapAllowed - faceswapUsed;

  const handleActiveMainTab = (tab: FaceswapProjectTab | string) => {
    if (activeTab === tab) return;

    const showToast = !project && (targetUrl || sourceUrl || textFieldValue || selectVoice);
    const currentTab = tab.replace(" ", "").replace(/^./, tab[0].toLowerCase());

    if (showToast) {
      dispatch(
        updatePopup({
          popup: Popups.faceswapReplaceProjectPopup,
          status: true,
          prefilled: {
            onCancel: () => null,
            onConfirm: () => navigate(`/${currentTab as FaceswapProjectTab}`),
          },
        }),
      );
      return;
    }

    navigate(`/${currentTab as FaceswapProjectTab}`);
  };

  const content = {
    [FaceswapProjectTab.Transform]: <TransformScreen />,
    [FaceswapProjectTab["Talking Avatar"]]: <TalkingAvatarScreen />,
    [FaceswapProjectTab.Faceswap]: <FaceSwapScreen />,
    [FaceswapProjectTab["Replicate Media"]]: <ReplicateMediaScreen />,
  };

  return (
    <>
      <Wrapper>
        <Content>
          <Navigation withThemeSwitcher={false} hasUnlimitedOnService={hasUnlimitedFaceswap} hasCredits={credits} />
          <TabsWrapper>
            <Tabs
              data={tabs}
              active={activeTab}
              handleActive={handleActiveMainTab}
              disabled={isTargetLoading || isSourceLoading || projectLoading}
            />
          </TabsWrapper>
          <UserActions isVisible={isVisible}>{content[activeTab]}</UserActions>
        </Content>
        <MyLibraryBar
          type={activeTab.replace(" ", "").replace(/^./, activeTab[0].toLowerCase())}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      </Wrapper>
      {pageLoading && (
        <CircularProgressWrapper>
          <BasePageLoader />
        </CircularProgressWrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: #191b1f;
  color: #ffffff;
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 32px;

  & div > div > a > svg {
    visibility: visible;
  }

  @media (max-width: 1000px) {
    & div > div > a > svg {
      visibility: hidden;
    }
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  margin: 0 auto 34px 0;
  height: 31px;
  border-radius: 52px;
  border: 0px;
  background: #292937;

  & > div {
    background: #292937;
    box-shadow: none;

    & > div {
      width: 190px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 52px;
      border: 0px;

      & > div {
        top: 40px;
        left: 140px;
        width: 290px;
        opacity: 0.6;
        border-radius: 7px;
        background: #292937;

        &::after {
          opacity: 0.6;
          border-color: transparent transparent #292937 transparent;
        }
      }

      &:hover {
        & div {
          opacity: 0.9;
        }
      }
    }

    button {
      box-shadow: none;
      background: #106cb6;

      &.not-active {
        opacity: 0.4;
      }
      &.not-active span {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1000px) {
    margin: 48px auto 18px 0;
  }
`;

const UserActions = styled.div<{ isVisible?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  column-gap: 18px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 5px 0 0;
  transition: margin-bottom 0.3s;
  margin-bottom: ${({ isVisible }) => (isVisible ? "290px" : "50px")};

  @media (max-width: 1200px) {
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 20px;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 37px 0;
  }
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: ${({ theme }) => theme.primaryBackground}4d;
  backdrop-filter: blur(6px);

  svg {
    height: 100px;
    width: 100px;
  }
`;

export default withPrivateRoute(FaceSwap);
