import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAudioRecorder } from "react-audio-voice-recorder";

import * as Styles from "../../index.styles";
import Loader from "../../../../components/Loader/Loader";
import PlayerForProjectAudio from "../PlayerForProjectAudio";
import useAudioPlayer from "../../../../hooks/useAudioPlayer";
import Button, { ButtonThemes } from "../../../../components/Button/Button";
import ActorsSidebar from "../../../../components/ActorsSidebar/ActorsSidebar";
import IconButton, { IconButtonThemes } from "../../../../components/Button/IconButton";
import {
  getActorsList,
  getActorsListLoading,
  getSpeechTranslation,
  getTextFromAudioLoading,
} from "../../../../redux/reducers/actorReducer";
import { IActor } from "../../../../types/actor";
import { IAudio } from "../../../../hooks/useAIVoices";
import {
  Speech2SpeechMicIcon,
  Speech2SpeechStopIcon,
  Speech2SpeechWaveIcon,
  SpeechTranslationDeleteIcon,
  SpeechTranslationPlayIcon,
} from "../../../../components/Icons/AIVoicesSpeechIcons";
import { CloudArrowUp } from "../../../../components/Icons/Icons";
import { formatNumberToDuration } from "../../../../lib/formatDuration";
import { getAudioTranslateProjectByIdServer } from "../../../../redux/actions/actorActions";

const ACCEPTED_FORMATS = "audio/mp3";
const MAX_SAMPLE_SIZE = 52428800;

interface Props {
  activeActor?: IActor;
  speechCategory: number;
  audioProjectRoute?: string;
  projectAudio: IAudio | null;
  targetLanguage?: string;
  translatedText?: string;
  createProject: () => void;
  signUpPopupOpen: (projectId?: number) => void;
  handleActiveActor: (actors: IActor[] | IActor) => void;
  handleProjectAudioChange: (audio: IAudio | null) => void;
}

const SpeechTranslation = ({
  activeActor,
  projectAudio,
  speechCategory,
  audioProjectRoute,
  targetLanguage,
  translatedText,
  createProject,
  signUpPopupOpen,
  handleActiveActor,
  handleProjectAudioChange,
}: Props) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const actorsList = useSelector(getActorsList);
  const isLoading = useSelector(getActorsListLoading);
  const speechTranslationProject = useSelector(getSpeechTranslation);
  const project = speechTranslationProject.data ? speechTranslationProject.data : null;
  const isProjectLoading = speechTranslationProject.isLoading;
  const isTextFromAudioLoading = useSelector(getTextFromAudioLoading);

  const [isRecord, setIsRecord] = useState(false);
  const [durationRecord, setDurationRecord] = useState(0);
  const [errorSizeSample, setErrorSizeSample] = useState<boolean>(false);

  const { audioPlayer, currentTime, duration, playing, onPlaying, toggleAudioPlay, onLoadedMetadata } =
    useAudioPlayer();

  const { startRecording, stopRecording, recordingBlob, isRecording, recordingTime } = useAudioRecorder();

  const addAudioElement = (blob: any) => {
    const src = URL.createObjectURL(blob);
    const oldSrc = !projectAudio?.oldSrc ? src : projectAudio.oldSrc;

    const audio = new Audio(URL.createObjectURL(blob));
    audio.onloadedmetadata = () => {
      handleProjectAudioChange({
        name: "Recorded audio",
        src,
        file: new File([blob], "recorded_audio.mp3", { type: "audio/mpeg" }),
        duration: durationRecord,
        oldSrc,
      });
    };

    setIsRecord(false);
  };

  const handleAudioUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    if (uploadedFile && uploadedFile.size > MAX_SAMPLE_SIZE) {
      setErrorSizeSample(true);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      setErrorSizeSample(false);
      const src = URL.createObjectURL(uploadedFile);
      const oldSrc = !projectAudio?.oldSrc ? src : projectAudio.oldSrc;
      const audio = new Audio(URL.createObjectURL(uploadedFile));
      audio.onloadedmetadata = () => {
        handleProjectAudioChange({
          name: uploadedFile.name,
          src,
          file: uploadedFile,
          duration: audio.duration,
          oldSrc,
        });
      };
    }
  };

  const deleteAudio = () => {
    const clearProjectAudio = {
      name: "",
      src: "",
      file: "",
      duration: 0,
      oldSrc: projectAudio?.src,
    };

    handleProjectAudioChange(clearProjectAudio);
  };

  useEffect(() => {
    if (!recordingBlob) return;

    addAudioElement(recordingBlob);
  }, [recordingBlob]);

  useEffect(() => {
    if (!recordingTime) return;

    setDurationRecord(recordingTime);
  }, [recordingTime]);

  useEffect(() => {
    let intervalId: any;

    if (project && project.audioTranslateProjectId && isProjectLoading) {
      intervalId = setInterval(() => {
        dispatch(getAudioTranslateProjectByIdServer(project.audioTranslateProjectId as number, 1, true));
      }, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isProjectLoading, project?.status]);

  return (
    <>
      <ActorsSidebar
        actors={actorsList}
        paragraphs={[]}
        active={activeActor?.actorId || actorsList[0]?.actorId}
        currentParagraphActor={activeActor || actorsList[0]}
        speechCategory={speechCategory}
        onClick={handleActiveActor}
        setActorActive={handleActiveActor}
        isSingleActorSelectionOnly={true}
        isLoading={isLoading}
        isNoFilters={true}
      />
      <Styles.Right>
        <Styles.ContentBlock>
          {isProjectLoading ? (
            <Loader width={3} />
          ) : isRecord ? (
            <>
              <Button
                buttonTheme={ButtonThemes.Secondary}
                icon={<img src="/images/arrow-left.svg" />}
                style={{
                  position: "absolute",
                  top: "18px",
                  left: "18px",
                }}
                onClick={() => setIsRecord(false)}
                text="Go to select"
              />
              <Styles.SpeechButtonsWrapper>
                <Button
                  onClick={() => {
                    setDurationRecord(0);
                    startRecording();
                  }}
                  buttonTheme={ButtonThemes.Transparent}
                  icon={<Speech2SpeechMicIcon />}
                  disabled={isRecording}
                />
                <Button
                  onClick={stopRecording}
                  buttonTheme={ButtonThemes.Transparent}
                  icon={<Speech2SpeechStopIcon />}
                  disabled={!isRecording}
                />
                <Styles.WaveWrapper>{isRecording && <Speech2SpeechWaveIcon />}</Styles.WaveWrapper>
              </Styles.SpeechButtonsWrapper>
            </>
          ) : projectAudio && projectAudio.src ? (
            <>
              <audio
                src={projectAudio.src}
                ref={audioPlayer}
                onTimeUpdate={onPlaying}
                onLoadedMetadata={onLoadedMetadata}
              />
              <Styles.FileListWrapper>
                <Styles.FileItem>
                  <Styles.FileInfo>
                    <p>{projectAudio?.name}</p>
                    <span>{formatNumberToDuration(projectAudio.duration)}</span>
                  </Styles.FileInfo>
                  <Styles.FileActions>
                    <IconButton
                      onClick={toggleAudioPlay}
                      iconButtonTheme={IconButtonThemes.Transparent}
                      icon={
                        !playing || currentTime === duration ? <SpeechTranslationPlayIcon /> : <Speech2SpeechStopIcon />
                      }
                    />
                    {!audioProjectRoute && (
                      <IconButton
                        disabled={isTextFromAudioLoading}
                        onClick={deleteAudio}
                        iconButtonTheme={IconButtonThemes.Transparent}
                        icon={<SpeechTranslationDeleteIcon />}
                      />
                    )}
                  </Styles.FileActions>
                </Styles.FileItem>
              </Styles.FileListWrapper>
            </>
          ) : (
            <Styles.DragAndDropWrapper>
              <input
                ref={fileInputRef}
                id="speechTranslation"
                name="speechTranslation"
                type="file"
                accept={ACCEPTED_FORMATS}
                onChange={handleAudioUpload}
              />
              <CloudArrowUp />
              <h3>Drag and drop your audio</h3>
              <Styles.SampleFormat sizeError={errorSizeSample}>
                File Supported: MP3. Maximum size: 50MB
              </Styles.SampleFormat>
              <Styles.ButtonsWrapper>
                <Button style={{ pointerEvents: "none" }} text="Upload audio" buttonTheme={ButtonThemes.Transparent} />
                <Button text="Record audio" buttonTheme={ButtonThemes.Transparent} onClick={() => setIsRecord(true)} />
              </Styles.ButtonsWrapper>
            </Styles.DragAndDropWrapper>
          )}
        </Styles.ContentBlock>
        <PlayerForProjectAudio
          buttonText="Play"
          tabName="SpeechTranslation"
          project={project}
          projectAudio={projectAudio}
          targetLanguage={targetLanguage}
          translatedText={translatedText}
          actorId={activeActor?.actorId || actorsList[0]?.actorId}
          createProject={createProject}
          signUpPopupOpen={signUpPopupOpen}
          handleProjectAudioChange={handleProjectAudioChange}
        />
      </Styles.Right>
    </>
  );
};

export default SpeechTranslation;
