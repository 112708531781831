import styled from "styled-components";
import { Link } from "react-router-dom";

import { IExternalLinks } from "../lib/routeUtils";
import { CubeIcon, Flash, ShapesIcon } from "../components/Icons/Icons";
import { PlanCardProps } from "../modules/PricingPage3/components/PlanCard";

const Label = styled.span`
  border-radius: 10px;
  background: linear-gradient(142deg, #0063b4 16.78%, #009af7 85.53%);
  padding: 3px 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 110%;
`;

const NewLabel = () => <Label>New</Label>;

export const planCardsMocks: PlanCardProps[] = [
  {
    plan: "PERSONAL",
    icon: <ShapesIcon />,
    greatFor: "Ideal for individuals seeking expanded access and early access to new AI tools.",
    yearly: {
      oldPrice: "",
      newPrice: "20",
      trigger: "#pkmodale895f1f871ddfd22553c80726188c551df2b4294",
    },
    monthly: {
      oldPrice: "",
      newPrice: "29",
      trigger: "#pkmodal029b7f78b3903a8fa53c0f93a4412dcf5844896c",
    },
    yearlyBilled: "243",
    list: [
      {
        text: "Access to all AI Services",
        tooltip: "",
      },
      {
        text: "900 credits/month",
        tooltip: (
          <>
            Each plan includes 900 Unified Credits per month, with 1 credit equal to 1 second of generation time. 1
            Image generation uses 1 credit, while a 4-second AI video consumes 40 credits. Credits automatically renew
            every month. For more details, please review our <Link to={IExternalLinks.terms}>Fair Use Policy</Link>
          </>
        ),
      },
      {
        text: "Basic Priority Rendering",
        tooltip: "",
      },
      {
        text: "Personal License",
        tooltip: "",
      },
    ],
  },
  {
    plan: "CREATOR",
    isLimited: true,
    icon: <CubeIcon />,
    greatFor: "Perfect for small teams seeking expanded access and early access to new AI tools.",
    yearly: {
      oldPrice: "99",
      newPrice: "41",
      trigger: "#pkmodal61112bdff3e11665c3400c2207075076526de8ba",
    },
    monthly: {
      oldPrice: "99",
      newPrice: "59",
      trigger: "#pkmodal7ff056c2adcd0049d1257aedd48d92b37950df38",
    },
    yearlyBilled: "495",
    list: [
      {
        text: "Access to all AI Services",
        tooltip: "",
      },
      {
        text: "2400 credits/month",
        tooltip: (
          <>
            Each plan includes 2400 Unified Credits per month, with 1 credit equal to 1 second of generation time. 1
            Image generation uses 1 credit, while a 4-second AI video consumes 40 credits. Credits automatically renew
            every month. For more details, please review our <Link to={IExternalLinks.terms}>Fair Use Policy</Link>
          </>
        ),
      },
      {
        text: "Fast Priority Rendering",
        tooltip: "",
      },
      {
        text: "Commercial License",
        tooltip: "",
      },
    ],
  },
  {
    plan: "BUSINESS UNLIMITED",
    isLimited: true,
    icon: <Flash />,
    greatFor: "Unlimited generation for ultimate flexibility and scalability.",
    yearly: {
      oldPrice: "198",
      newPrice: "69",
      trigger: "#pkmodal3052358a82dfc5607a2a167def3a5a20607d78bd",
    },
    monthly: {
      oldPrice: "130",
      newPrice: "99",
      trigger: "#pkmodal29ace0084cb963d1f6cacfa5ede1b98e806091cc",
    },
    yearlyBilled: "831",
    list: [
      {
        text: "Access to all AI Services",
        tooltip: "",
      },
      {
        text: "Unlimited credits/month",
        tooltip: (
          <>
            Each plan includes Unlimited Unified Credits per month, with 1 credit equal to 1 second of generation time.
            1 Image generation uses 1 credit, while a 4-second AI video consumes 40 credits. Credits automatically renew
            every month. For more details, please review our <Link to={IExternalLinks.terms}>Fair Use Policy</Link>
          </>
        ),
      },
      {
        text: "Turbo Priority Rendering",
        tooltip: "",
      },
      {
        text: "Commercial License",
        tooltip: "",
      },
    ],
  },
];
