import styled from "styled-components";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "../../modules/PricingSubscriptions/components/Tooltip";
import CircularProgress from "../Icons/CircularProgress";
import { EmptyIcon } from "../Icons/Icons";
import { PriceSection } from "../../types/pricing";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { getLoadingPlans, getUserPlans } from "../../redux/reducers/plansReducer";
import { getCheckoutLink, getPlanGroup } from "../../modules/PricingSubscriptions/DefaultUserPricing";

interface Props {
  data: Array<{
    [key: string]: any;
  }>;
  columns: Array<{
    id: number;
    header: string;
    accessor: string;
    productName?: string;
    size?: number;
  }>;
  isActiveGroup: boolean;
  activeSection: PriceSection;
}

const CustomTable: FC<Props> = ({ data, columns, isActiveGroup, activeSection }) => {
  const dispatch = useDispatch();

  const plans = useSelector(getUserPlans);
  const loadingPlans = useSelector(getLoadingPlans);

  const activeUserPlans = plans.filter((plan) => !plan.isCancelled);

  const handleChangeSubscription = (newPlanId: number) => {
    if (!activeUserPlans.length) return;
    const existingUserLicenseId =
      activeSection === PriceSection.studio
        ? activeUserPlans[0].userLicenseID
        : activeUserPlans.find((plan) => getPlanGroup(plan) === activeSection)?.userLicenseID;
    if (!existingUserLicenseId) return;
    // dispatch(updateSubscriptionServer({ existingUserLicenseId, newPlanId }));
    dispatch(
      updatePopup({
        popup: Popups.changeSubscriptionPopup,
        status: true,
        prefilled: { existingUserLicenseId, newPlanId },
      }),
    );
  };

  return (
    <Table>
      <thead>
        <tr>
          {columns.map(({ header, size }) => (
            <TableHeaderCell key={header} size={size}>
              {header}
            </TableHeaderCell>
          ))}
        </tr>
      </thead>
      <TBody>
        {data.map((item) => (
          <tr key={item.id}>
            {columns.map(({ accessor, id }) => {
              const text = item[accessor]?.text || item[accessor];
              const tooltip = item[accessor]?.tooltip;
              return (
                <TableBodyCell key={id}>
                  <TextWrapper>
                    <p>{text}</p>
                    {tooltip && <Tooltip title={tooltip} />}
                  </TextWrapper>
                </TableBodyCell>
              );
            })}
          </tr>
        ))}
        <tr>
          {columns.map(({ accessor, id, productName }, index) =>
            index && accessor !== "free" ? (
              isActiveGroup || (activeSection === PriceSection.studio && activeUserPlans.length) ? (
                activeUserPlans.some((plan) => plan.productName === productName) ? (
                  <TableBodyCell key={id}>
                    <ActivePlan>
                      <span>Active plan</span>
                    </ActivePlan>
                  </TableBodyCell>
                ) : (
                  <TableBodyCell key={id}>
                    {loadingPlans.includes(id) ? (
                      <CircularProgress color="#009af7" />
                    ) : (
                      <Button onClick={() => handleChangeSubscription(id)}>
                        <span>Select plan</span>
                      </Button>
                    )}
                  </TableBodyCell>
                )
              ) : (
                <TableBodyCell key={id}>
                  <a href={getCheckoutLink(id)} target="_blank" rel="noreferrer">
                    <Button>
                      <span>Select plan</span>
                    </Button>
                  </a>
                </TableBodyCell>
              )
            ) : (
              <TableBodyCell key={id}>
                <EmptyIcon />
              </TableBodyCell>
            ),
          )}
        </tr>
      </TBody>
    </Table>
  );
};

const Button = styled.button`
  padding: 14px 31px;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.buttonShadow};
  background: ${({ theme }) => theme.primaryBackground};
  max-width: 153px;

  span {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-family: Mulish;
    line-height: 20px; /* 125% */
    letter-spacing: -0.41px;
    background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const ActivePlan = styled.div`
  padding: 13px 31px;
  border-radius: 12px;
  background: ${({ theme }) => theme.primaryBackground};
  border: 1px solid #0063b4 !important;
  text-align: center;
  max-width: 153px;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-family: Mulish;
    line-height: 20px; /* 125% */
    letter-spacing: -0.41px;
    background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const TextWrapper = styled.div`
  position: relative;
  width: fit-content;

  &:hover .tooltip {
    opacity: 1;
  }
`;

const TBody = styled.tbody`
  tr {
    td:first-of-type {
      font-weight: 600;
      color: #191b1f;
    }
  }
  tr:not(:last-child) {
    td {
      border-bottom: 1px solid #c8cad3;
    }
  }

  tr td:last-child {
    .tooltip {
      bottom: -35px;
      left: -25px;
      max-width: 150px;
    }
  }
`;

const Table = styled.table`
  border: 1px solid #c8cad3;
  border-radius: 8px;
  border-spacing: 0;
  overflow: hidden;
`;

const TableHeaderCell = styled.th<{ size?: number }>`
  text-align: left;
  padding: 12px 16px;
  height: 44px;
  background: #e7e7ed;
  width: ${({ size }) => (size ? `${size}px` : "inherit")};
`;

const TableBodyCell = styled.td`
  height: 72px;
  padding: 24px 16px;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #4d4e54;
  }

  &:first-of-type {
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.primaryText};
    }
  }

  .primaryButton {
    width: 153px;
    padding: 14px 0;

    .primaryButtonText {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.41px;
    }

    @media (max-width: 1000px) {
      width: 105px;
    }
  }
`;

export default CustomTable;
