import styled from "styled-components";
import { useContext } from "react";

import SelectPlanButton from "./SelectPlanButton";
import SmartTooltip from "../../../components/Tooltip/SmartTooltip";
import Button, { Color } from "./Button";
import {
  creditsColumns,
  defaultColumns,
  dubbingTable,
  faceformTable,
  imageTable,
  labsTable,
  renderSpeedColumns,
  videoComparisonTable,
  videoTable,
  voicesTable,
} from "../../../mocks/plansTableMocks3";
import { PlanTypes } from "./PlanCard";
import { PricingContext } from "./PricingProvider";
import { IExternalLinks } from "../../../lib/routeUtils";
import { InfoIcon1818White } from "../../../components/Icons/Icons";

const PlansTableDesktop = () => {
  const { isYearly } = useContext(PricingContext);

  return (
    <Table>
      <thead>
        <TableRow>
          {defaultColumns.map(
            ({ header, yearlyPrice, monthlyPrice, yearlyTrigger, monthlyTrigger, planType, buttonColor, icon }) => (
              <TableHeaderCell key={header}>
                {header === "Features" ? (
                  <FeaturesWrapper>
                    {icon}
                    <p>{header}</p>
                  </FeaturesWrapper>
                ) : (
                  <>
                    <p className="th-title">{header}</p>
                    <div className="th-content-wrapper">
                      {isYearly
                        ? yearlyPrice && <p className="th-price">${yearlyPrice}</p>
                        : monthlyPrice && <p className="th-price">${monthlyPrice}</p>}
                      {header === "Free" ? (
                        <Button color={buttonColor as Color} linkPath={IExternalLinks.tryForFree} type="link">
                          Start for free
                        </Button>
                      ) : (
                        <SelectPlanButton
                          popupHash={isYearly ? yearlyTrigger! : monthlyTrigger!}
                          buttonColor={buttonColor}
                          planType={planType as PlanTypes}
                        />
                      )}
                    </div>
                  </>
                )}
              </TableHeaderCell>
            ),
          )}
        </TableRow>
      </thead>
      <tbody>
        <Credits>
          {creditsColumns.map(({ header, tooltip, priority, id }) => (
            <td key={id}>
              <p className="speedContent">
                {header}
                {tooltip && <SmartTooltip title={tooltip} icon={<InfoIcon1818White />} />}
              </p>
              {priority && <p className="speedContent">{priority}</p>}
            </td>
          ))}
        </Credits>
        <Credits>
          {renderSpeedColumns.map(({ header, tooltip, priority, id }) => (
            <td key={id}>
              <p className="speedContent">
                {header}
                {tooltip && <SmartTooltip title={tooltip} icon={<InfoIcon1818White />} />}
              </p>
              {priority && <p className="speedContent">{priority}</p>}
            </td>
          ))}
        </Credits>
        <HeadingRow>
          <td>
            {voicesTable.heading.icon}
            {voicesTable.heading.title}
          </td>
        </HeadingRow>
        {voicesTable.table.map((item) => (
          <TableRow key={item.id}>
            {voicesTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <HeadingRow>
          <td>
            {videoTable.heading.icon}
            {videoTable.heading.title}
          </td>
        </HeadingRow>
        {videoTable.table.map((item) => (
          <TableRow key={item.id}>
            {videoTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <HeadingRow>
          <td>
            {dubbingTable.heading.icon}
            {dubbingTable.heading.title}
          </td>
        </HeadingRow>
        {dubbingTable.table.map((item) => (
          <TableRow key={item.id}>
            {dubbingTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <HeadingRow>
          <td>
            {imageTable.heading.icon}
            {imageTable.heading.title}
          </td>
        </HeadingRow>
        {imageTable.table.map((item) => (
          <TableRow key={item.id}>
            {imageTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <HeadingRow>
          <td>
            {videoComparisonTable.heading.icon}
            {videoComparisonTable.heading.title}
          </td>
        </HeadingRow>
        {videoComparisonTable.table.map((item) => (
          <TableRow key={item.id}>
            {videoComparisonTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <HeadingRow>
          <td>
            {faceformTable.heading.icon}
            {faceformTable.heading.title}
          </td>
        </HeadingRow>
        {faceformTable.table.map((item) => (
          <TableRow key={item.id}>
            {faceformTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <HeadingRow>
          <td>
            {labsTable.heading.icon}
            {labsTable.heading.title}
          </td>
        </HeadingRow>
        {labsTable.table.map((item) => (
          <TableRow key={item.id}>
            {labsTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <TableRow>
          {defaultColumns.map(({ header, buttonColor, yearlyTrigger, monthlyTrigger, planType, id }) => (
            <TableCell key={id}>
              {header !== "Features" ? (
                header === "Free" ? (
                  <Button color={buttonColor as Color} linkPath={IExternalLinks.tryForFree} type="link">
                    Start for free
                  </Button>
                ) : (
                  <SelectPlanButton
                    popupHash={isYearly ? yearlyTrigger! : monthlyTrigger!}
                    buttonColor={buttonColor}
                    planType={planType as PlanTypes}
                  />
                )
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  background: #f0f0f3;
  overflow: hidden;
  box-shadow: -5px -5px 10px #ffffff, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1),
    inset 2px 2px 4px #ffffff;

  @media (max-width: 767px) {
    display: none;
  }
`;

const TableRow = styled.tr`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  th,
  td {
    border-spacing: 0;
    border-right: 1px solid #d2d2d9;

    :first-child {
      flex-grow: 1;
    }

    :not(:first-child) {
      flex-basis: 17.5%;
      flex-shrink: 0;
    }

    :last-child {
      border-right: none;

      background: #d8e9f6;
    }
  }

  td:first-child {
    justify-content: flex-start;
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.04em;

    color: rgba(25, 27, 31, 0.73);
  }
`;

const TableHeaderCell = styled.th`
  border-spacing: 0;
  padding: 18px 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;

  :first-child {
    justify-content: center;
  }

  .th-title {
    color: #191b1f;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    text-align: left;
    text-transform: uppercase;

    background: #0063b4;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .th-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .th-price {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;

    color: #191b1f;
  }

  a {
    width: 100%;
  }

  .primaryButton {
    width: 100%;
    padding: 10px;

    .primaryButtonText {
      font-size: 16px;
    }
  }

  @media (max-width: 1099px) {
    gap: 6px;

    .th-title {
      font-size: 14px;
    }

    .th-price {
      font-size: 12px;
    }

    .primaryButton {
      padding: 8px 12px;

      .primaryButtonText {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 999px) {
    gap: 4px;

    .th-title {
      font-size: 12px;
    }

    .primaryButton {
      padding: 8px;

      .primaryButtonText {
        font-size: 10px;
      }
    }
  }
`;

const Credits = styled.tr`
  border-spacing: 0;

  display: flex;
  width: 100%;
  justify-content: flex-end;
  background: linear-gradient(0deg, #0063b4 10%, #6420ff 150%);

  td {
    border-spacing: 0;
    border-right: 1px solid #d2d2d9;

    padding: 20px 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    :last-child {
      border-right: none;
    }

    .speedContent {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: -0.04em;
      position: relative;
      max-width: max-content;

      .tooltipWrapper {
        position: absolute;
        right: -24px;
        top: 2px;
        display: flex;

        .tooltip {
          max-width: 250px;
        }
      }
    }

    :first-child {
      flex-grow: 1;
      align-items: flex-start;
    }

    :not(:first-child) {
      flex-basis: 17.5%;
      flex-shrink: 0;
    }
  }

  @media (max-width: 1099px) {
    td {
      .speedContent {
        font-size: 10px;

        .tooltipWrapper {
          top: -1px;
        }
      }
    }
  }

  @media (max-width: 930px) {
    td {
      .speedContent {
        font-size: 8px;

        .tooltipWrapper {
          top: -2px;
        }
      }
    }
  }
`;

const TableCell = styled.td`
  border-spacing: 0;
  border-top: 1px solid #d2d2d9;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 14px;
  justify-content: center;
  color: rgba(25, 27, 31, 0.73);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;

  .checkedIcon {
    display: block;
    width: 24px;
    height: auto;
  }

  :first-child {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;

    .tooltipCell {
      display: inline;
    }

    .tooltipWrapper {
      display: inline-flex;
      margin-left: 4px;
      top: 4px;
      cursor: help;

      .tooltip {
        max-width: 250px;
      }
    }
  }

  a {
    width: 100%;

    .primaryButton {
      width: 100%;
      padding: 10px;

      .primaryButtonText {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1099px) {
    font-size: 12px;
  }

  @media (max-width: 999px) {
    a {
      .primaryButton {
        padding: 8px;

        .primaryButtonText {
          font-size: 10px;
        }
      }
    }
  }
`;

const HeadingRow = styled.tr`
  width: 100%;

  td {
    border-spacing: 0;
    border-top: 1px solid #d2d2d9;

    display: flex;
    align-items: center;
    gap: 10px;
    background: #eaeaf1;
    padding: 20px 16px;

    color: rgba(25, 27, 31, 0.73);
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: -0.96px;
  }

  @media (max-width: 1099px) {
    td {
      font-size: 20px;

      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  @media (max-width: 1099px) {
    td {
      font-size: 16px;
    }
  }
`;

export default PlansTableDesktop;
