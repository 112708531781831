import styled from "styled-components";
import React, { FC, useEffect, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useLocation, useNavigate } from "react-router-dom";

import Footer from "../components/Footer/Footer";
import Navigation from "../components/Navigation/Navigation";
import Button, { ButtonThemes } from "../components/Button/Button";
import { useMobile } from "../hooks/useResponsible";

interface PageLayoutProps {
  children: React.ReactNode;
}

const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  const href = window.location.pathname;
  const isMobile = useMobile({ width: 999 });
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      if (href === "/pricing" || href === "/pricing2" || href === "/pricing3") return;
      if (isMobile) return;

      gsap.to("#navigation-center", {
        scrollTrigger: {
          trigger: "#floating-menu",
          start: `bottom top+=68`,
          end: `bottom bottom`,
          scrub: 1,
        },
        visibility: "visible",
        opacity: 1,
      });
    });

    return () => context.revert();
  }, [href, isMobile]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const hadnleBackClick = () => {
    if (location?.state === null) {
      navigate("/");
      return null;
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <OuterWrapper>
        <Navigation
          withThemeSwitcher={false}
          startAdornment={
            <Button
              text="Back"
              onClick={hadnleBackClick}
              buttonTheme={ButtonThemes.Secondary}
              icon={<img src="/images/arrow-left.svg" />}
            />
          }
        />
      </OuterWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </>
  );
};

const OuterWrapper = styled.div`
  padding: 0px 40px;
`;

const ContentWrapper = styled.main`
  font-family: "Montserrat", sans-serif;
  box-shadow: inset 0 14px 10px -10px #e0e0e7;
  padding-top: 7px;
  background: #f0f0f3;
  overflow: hidden;
`;

export default PageLayout;
