import { useState } from "react";
import { toast } from "react-toastify";
import { client } from "../redux/store";
import {
  ADD_VIDEO_TRANSLATE_PROJECT_SERVER,
  updateVideoTranslateIsLoading,
} from "../redux/actions/videoTranslationActions";
import { useDispatch, useSelector } from "react-redux";
import { Popups, updatePopup } from "../redux/actions/popupsActions";
import { SignUpPopupTypes } from "../types/signUpPopupTypes";
import { getProfile } from "../redux/reducers/profileReducer";
import { VideoTranslationModules } from "../types/videoTranslation";
import Navigate from "../lib/Navigate";

export const useUploadForm = (url: string) => {
  const dispatch = useDispatch();
  const { roleId } = useSelector(getProfile);
  const isGuest = roleId === 3;
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadForm = async (formData: FormData) => {
    setIsLoading(true);

    try {
      const prefilled: SignUpPopupTypes = {
        type: "VideoTranslation",
        link: { projectName: "video-translation", projectId: 0 },
        isClick: true,
      };

      if (isGuest) {
        dispatch(updateVideoTranslateIsLoading({ module: VideoTranslationModules.generateVideo, isLoading: true }));
        dispatch(updatePopup({ popup: Popups.signUpPopup, status: true, prefilled }));
      }

      const response = await client.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: any) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          console.log(progress);
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent: any) => {
          if (progressEvent.lengthComputable) {
            const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
            console.log(progressEvent);
            setProgress(progress);
          } else {
            setProgress(99);
          }
        },
      });

      dispatch({ type: `${ADD_VIDEO_TRANSLATE_PROJECT_SERVER}_SUCCESS`, payload: { data: response.data } });
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Error generating video");
      dispatch({ type: `${ADD_VIDEO_TRANSLATE_PROJECT_SERVER}_FAIL` });
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return { uploadForm, isLoading, progress };
};
