import styled from "styled-components";

import Toggle from "./Toggle";
import PlanCard from "./PlanCard";
import RatingCard from "./RatingCard";
import PricingTrustedBrands from "./PricingTrustedBrands";
import Container from "../../../components/Container/Container";
import { G2logo } from "../../../components/Icons/G2logo";
import { planCardsMocks } from "../../../mocks/planCardsMocks3";

const Hero = () => {
  const mobilePlanCards = [...planCardsMocks].reverse();

  return (
    <Wrapper>
      <Container>
        <HeadingWrapper>
          <RaitingWrapper>
            <RatingCard />
            <RatingCard icon={<G2logo />} rating="4.5" />
          </RaitingWrapper>
          <TextWrapper>
            <Title>We Provide The Best, Simple & Transparent Pricing!</Title>
            <Subtitle>
              Get Access to all AI Services with the lower plan—push your boundaries freely{" "}
              <span>
                <img src="/images/pricing/rocket.webp" alt="rocket" />
              </span>
            </Subtitle>
          </TextWrapper>
          <Toggle />
        </HeadingWrapper>
      </Container>
      <DesktopCardsWrapper>
        {planCardsMocks.map((card, idx) => (
          <PlanCard key={idx} {...card} />
        ))}
      </DesktopCardsWrapper>
      <MobileCardsWrapper>
        {mobilePlanCards.map((card, idx) => (
          <PlanCard key={idx} {...card} />
        ))}
      </MobileCardsWrapper>
      <Container addClass="trustedBrands">
        <PricingTrustedBrands />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  padding: 48px 0 40px;
  background: linear-gradient(180deg, #0f5e9f 0%, #231f20 97.46%);
  display: flex;
  flex-direction: column;
  align-items: center;

  .trustedBrands {
    margin-top: 60px;
  }

  @media (max-width: 767px) {
    padding: 32px 0;
  }
`;

const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @media (max-width: 767px) {
    gap: 16px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (max-width: 767px) {
    gap: 12px;
  }
`;

const RaitingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const Title = styled.h1`
  color: #ffffff;

  font-size: 48px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -0.04em;
  text-align: center;

  @media (max-width: 999px) {
    font-size: 42px;
  }

  @media (max-width: 767px) {
    font-size: 32px;
    margin-bottom: 10px;
  }

  @media (max-width: 501px) {
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  @media (max-width: 420px) {
    font-size: 20px;
    text-align: left;
  }
`;

const Subtitle = styled.p`
  color: #ffffff;

  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;

  span {
    margin-left: 4px;

    img {
      display: inline-block;
      width: 20px;
      height: auto;
    }
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const DesktopCardsWrapper = styled.div`
  width: 100%;
  max-width: 1179px;

  display: flex;
  align-items: stretch;
  gap: 42px;

  padding: 20px 30px 15px;
  margin: 24px -30px -15px;
  overflow-x: auto;

  @media screen and (max-width: 999px) {
    display: none;
  }
`;

const MobileCardsWrapper = styled.div`
  display: none;

  @media screen and (max-width: 999px) {
    display: flex;
    align-items: stretch;
    gap: 24px;
    width: 100%;
    max-width: 1250px;
    padding: 20px 30px 15px;
    margin: 24px -30px -15px;
    overflow-x: auto;
  }

  @media screen and (max-width: 440px) {
    padding: 20px 16px 15px;
    margin: 12px -16px -15px;
  }
`;

const FreePlan = styled.p`
  margin-top: 60px;

  font-size: 22px;
  line-height: 1.38;

  color: #ffffff;

  a {
    font-weight: 700;
    font-size: inherit;
    line-height: inherit;
    text-decoration-line: underline;

    color: #ff9500;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export default Hero;
