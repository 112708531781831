import styled from "styled-components";
import { FC, ReactElement } from "react";

import SmartLink from "../../../components/SmartLink/SmartLink";
import { CheckIcon } from "../../../components/Icons/CheckIcon";

type Size = "small" | "medium" | "large";
export type Color = "default" | "blue" | "pricingBlue" | "pricingOutlineWhite" | "pricingOutlineBlack" | "pricingBlack";

interface IButton {
  size?: Size;
  color?: Color;
  children: string;
  icon?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: any;
  type?: string;
  linkPath?: string;
  linkLabel?: string;
}

interface IStyledProps {
  itemSize: Size;
  itemColor: Color;
}

const wrapperStyles = (itemSize: IStyledProps["itemSize"], itemColor: IStyledProps["itemColor"]) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "4px",
  border: "none",
  width: "fit-content",
  borderRadius: "13px",
  cursor: "pointer",
  transition: "opacity 0.5s ",

  "& span": {
    transition: "opacity 0.5s ",
  },

  ...getCustomSize()[itemSize],
  ...getCustomColor()[itemColor],
});

const Button: FC<IButton> = ({
  children,
  icon = false,
  size = "small",
  color = "default",
  disabled,
  className = "newButtonClass",
  onClick,
  type = "button",
  linkPath,
  linkLabel,
  ...props
}): ReactElement => {
  return type === "link" ? (
    <SmartLink url={linkPath} label={linkLabel || children}>
      <LinkWrapper
        itemSize={size}
        itemColor={color}
        onClick={onClick}
        className={`primaryButton ${className}`}
        {...props}
      >
        {icon && <CheckIcon />}
        <span className="primaryButtonText">{children}</span>
      </LinkWrapper>
    </SmartLink>
  ) : (
    <ButtonWrapper
      itemSize={size}
      itemColor={color}
      disabled={disabled}
      onClick={onClick}
      className={`primaryButton ${className}`}
      {...props}
    >
      {icon && <CheckIcon />}
      <span className="primaryButtonText">{children}</span>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled("button")<{ itemSize: IStyledProps["itemSize"]; itemColor: IStyledProps["itemColor"] }>(
  ({ itemSize, itemColor }) => ({
    ...wrapperStyles(itemSize, itemColor),
  }),
);

const LinkWrapper = styled("span")<{ itemSize: IStyledProps["itemSize"]; itemColor: IStyledProps["itemColor"] }>(
  ({ itemSize, itemColor }) => ({
    ...wrapperStyles(itemSize, itemColor),
  }),
);

const getCustomSize = () => ({
  small: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "22px",
    padding: "2px 14px",
  },
  medium: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "15px",
    padding: "8px 12px",
  },
  large: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
  },
});

const getCustomColor = () => ({
  default: {
    backgroundColor: "#F0F0F3",
    span: {
      background: "linear-gradient(142deg, #0063b4 17%, #009af7 86%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      "background-clip": "text",
    },

    boxShadow:
      "-4px -4px 8px #FFFFFF, 4px 4px 8px rgba(174, 174, 192, 0.3), inset -2px -2px 3px rgba(0, 0, 0, 0.1), inset 2px 2px 3px #FFFFFF",

    "@media (hover: hover) and (pointer: fine)": {
      "&:hover": {
        span: {
          opacity: 0.65,
        },
      },
    },

    "&:disabled": {
      "-webkit-text-fill-color": "unset",
      background: "#E4E4E8",
      color: "#B9B9C6",
    },
  },
  blue: {
    background: "linear-gradient(142deg, #0063B4 17%, #009AF7 86%)",
    color: "#fff",
    boxShadow:
      "-4px -4px 8px #FFFFFF, 4px 4px 8px rgba(174, 174, 192, 0.3), inset -2px -2px 3px rgba(0, 0, 0, 0.1), inset 2px 2px 3px #FFFFFF",

    "@media (hover: hover) and (pointer: fine)": {
      "&:hover": {
        opacity: 0.8,
      },
    },

    "&:disabled": {
      background: "#E4E4E8",
      color: "#B9B9C6",
    },
  },
  pricingBlue: {
    border: "1.6px solid transparent",
    background: "#0063B4",
    color: "#fff",

    "@media (hover: hover) and (pointer: fine)": {
      "&:hover": {
        opacity: 0.8,
      },
    },

    "&:disabled": {
      background: "#E4E4E8",
      color: "#B9B9C6",
    },
  },
  pricingOutlineWhite: {
    border: "1.6px solid #FFFFFF",
    background: "transparent",
    color: "#fff",

    "@media (hover: hover) and (pointer: fine)": {
      "&:hover": {
        opacity: 0.65,
      },
    },

    "&:disabled": {
      background: "#E4E4E8",
      color: "#B9B9C6",
    },
  },
  pricingOutlineBlack: {
    border: "1.6px solid #191B1F",
    background: "transparent",
    color: "#191B1F",

    "@media (hover: hover) and (pointer: fine)": {
      "&:hover": {
        opacity: 0.65,
      },
    },

    "&:disabled": {
      background: "#E4E4E8",
      color: "#B9B9C6",
    },
  },
  pricingBlack: {
    border: "1.6px solid transparent",
    background: "#040404",
    color: "#FFFFFF",

    "@media (hover: hover) and (pointer: fine)": {
      "&:hover": {
        opacity: 0.65,
      },
    },

    "&:disabled": {
      background: "#E4E4E8",
      color: "#B9B9C6",
    },
  },
});

export default Button;
