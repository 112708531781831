export const VideoIcon2626 = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.664 15.816L18.621 12.9994L21.664 10.1838V15.816ZM16.2487 17.3328C16.2487 17.9308 15.7634 18.4161 15.1654 18.4161H5.41536C4.81736 18.4161 4.33203 17.9308 4.33203 17.3328V8.6661C4.33203 8.06918 4.81736 7.58276 5.41536 7.58276H15.1654C15.7634 7.58276 16.2487 8.06918 16.2487 8.6661V17.3328ZM22.7624 7.74085C22.0842 7.4451 21.2977 7.57402 20.7571 8.07235L18.416 10.239V8.66602C18.416 6.87418 16.9579 5.41602 15.166 5.41602H5.41602C3.62418 5.41602 2.16602 6.87418 2.16602 8.66602V17.3327C2.16602 19.1256 3.62418 20.5827 5.41602 20.5827H15.166C16.9579 20.5827 18.416 19.1256 18.416 17.3327V15.7608L20.7571 17.9264C21.1038 18.2481 21.5534 18.416 22.0116 18.416C22.2651 18.416 22.5197 18.3651 22.7624 18.2589C23.4221 17.9708 23.8327 17.3544 23.8327 16.6513V9.34852C23.8327 8.64543 23.4221 8.02902 22.7624 7.74085Z"
      fill="#191B1F"
    />
    <mask id="mask0_1_365" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="2" y="5" width="22" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.664 15.816L18.621 12.9994L21.664 10.1838V15.816ZM16.2487 17.3328C16.2487 17.9308 15.7634 18.4161 15.1654 18.4161H5.41536C4.81736 18.4161 4.33203 17.9308 4.33203 17.3328V8.6661C4.33203 8.06918 4.81736 7.58276 5.41536 7.58276H15.1654C15.7634 7.58276 16.2487 8.06918 16.2487 8.6661V17.3328ZM22.7624 7.74085C22.0842 7.4451 21.2977 7.57402 20.7571 8.07235L18.416 10.239V8.66602C18.416 6.87418 16.9579 5.41602 15.166 5.41602H5.41602C3.62418 5.41602 2.16602 6.87418 2.16602 8.66602V17.3327C2.16602 19.1256 3.62418 20.5827 5.41602 20.5827H15.166C16.9579 20.5827 18.416 19.1256 18.416 17.3327V15.7608L20.7571 17.9264C21.1038 18.2481 21.5534 18.416 22.0116 18.416C22.2651 18.416 22.5197 18.3651 22.7624 18.2589C23.4221 17.9708 23.8327 17.3544 23.8327 16.6513V9.34852C23.8327 8.64543 23.4221 8.02902 22.7624 7.74085Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1_365)"></g>
  </svg>
);

export const VideoIcon2626Landing = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00049 7.49951C7.00049 4.74251 9.24349 2.49951 12.0005 2.49951C14.7575 2.49951 17.0005 4.74251 17.0005 7.49951C17.0005 10.2565 14.7575 12.4995 12.0005 12.4995C9.24349 12.4995 7.00049 10.2565 7.00049 7.49951ZM16.1175 13.5706C15.5585 13.4286 14.9505 13.5086 14.4475 13.7886C12.9295 14.6326 11.0715 14.6326 9.55347 13.7886C9.05147 13.5096 8.44248 13.4306 7.88348 13.5706C5.89148 14.0736 4.50049 15.8816 4.50049 17.9666V18.9775C4.50049 19.6155 4.67047 20.2394 4.99347 20.7834C5.25647 21.2254 5.7495 21.5005 6.2785 21.5005H17.7215C18.2505 21.5005 18.7435 21.2265 19.0065 20.7825C19.3295 20.2395 19.4995 19.6155 19.4995 18.9775V17.9666C19.5005 15.8816 18.1095 14.0736 16.1175 13.5706Z"
      fill="#41416E"
    />
  </svg>
);
