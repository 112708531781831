import styled from "styled-components";
import { PopupButton } from "@typeform/embed-react";

import EnterprisePackageList from "./EnterprisePackageList";

const EnterprisePackage = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <HeadingWrapper>
          <Title>Are you looking for an enterprise package?</Title>
          <EnterprisePackageList listClass="mobileList" />
          <PopupButton id="PiL3jzpL">
            <span>Let&apos;s talk</span>
          </PopupButton>
        </HeadingWrapper>
        <EnterprisePackageList listClass="desktopList" />
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 68px;
  padding: 56px 40px 80px;
  border-radius: 16px;
  background: linear-gradient(191.79deg, #0f5e9f 34.77%, #231f20 117.65%);
  box-shadow: -5px -5px 10px #ffffff, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1),
    inset 2px 2px 4px #ffffff;

  @media (max-width: 1199px) {
    gap: 48px;
  }

  @media (max-width: 999px) {
    padding: 48px 40px;
    gap: 32px;
  }

  @media (max-width: 767px) {
    padding: 32px 24px;
  }

  @media (max-width: 420px) {
    gap: 24px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media screen and (max-width: 1199px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    .desktopList {
      display: none;
    }
  }
`;

const HeadingWrapper = styled.div`
  max-width: 415px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  button {
    width: 280px;
    height: 48px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #ffffff;
    filter: drop-shadow(-5px -5px 10px #0b7bc8) drop-shadow(5px 5px 10px rgba(174, 174, 192, 0.3));
    transition: opacity 0.3s;

    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: -0.41px;
      color: rgba(0, 99, 180, 0.2);
      background: #0063b4;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media (hover: hover) and (pointer: fine) {
      :hover {
        opacity: 0.8;
      }
    }
  }

  .mobileList {
    display: none;
  }

  @media (max-width: 999px) {
    max-width: 345px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    align-items: center;
    gap: 24px;

    .mobileList {
      display: flex;
    }
  }

  @media (max-width: 420px) {
    align-items: flex-start;
    gap: 16px;

    button {
      margin-top: 8px;
      width: 100%;
    }
  }
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 38px;
  font-weight: 700;
  line-height: 1.37;
  letter-spacing: -1.52px;

  @media (max-width: 999px) {
    font-size: 32px;
  }

  @media screen and (max-width: 767px) {
    text-align: center;
  }

  @media (max-width: 420px) {
    font-size: 24px;
    letter-spacing: -0.96px;
    text-align: left;
  }
`;

export default EnterprisePackage;
