import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ProgressBar from "./ProgressBar";
import PlayPauseButton from "./PlayPauseButton";
import Tooltip from "../../../components/Tooltip/Tooltip";
import CircularProgress from "../../../components/Icons/CircularProgress";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import { FaceswapProjectTab } from "..";
import { pages } from "../../../lib/routeUtils";
import {
  getFaceswap,
  getFaceswapLoading,
  getFaceswapSource,
  getFaceswapTarget,
  getPercent,
  getTextForAudio,
} from "../../../redux/reducers/faceswapReducer";
import {
  clearFaceswap,
  createAIServiceServer,
  createFaceswapServer,
  getAIServiceByIdServer,
  getFaceswapServer,
  setPercent,
} from "../../../redux/actions/faceswapActions";
import { isImageUrl } from "../../../lib/isImageUrl";
import { downloadFile } from "../../../lib/downloadFile";
import { parsePercent } from "../../../lib/parsePercent";
import { FaceSwapType } from "../../../types/faceSwapType";
import { formatDateString } from "../../../lib/formatDateString";
import { BasePageLoader } from "../../../components/Icons/BasePageLoader";
import { CheckIcon, DownloadBoxIcon, InfoIcon, LiveVideoIcon } from "../../../components/Icons/FaceSwapIcons";
import { HelpIcon } from "../../../components/Icons/HelpIcon";
import { getProfile } from "../../../redux/reducers/profileReducer";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { SignUpPopupTypes } from "../../../types/signUpPopupTypes";
import Navigate from "../../../lib/Navigate";

interface Props {
  type: FaceswapProjectTab;
  activeTab: string;
  generateButtonText: string;
}

const VideoCard = ({ type, activeTab, generateButtonText }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const project = useSelector(getFaceswap);
  const targetUrl = useSelector(getFaceswapTarget);
  const sourceUrl = useSelector(getFaceswapSource);
  const isLoading = useSelector(getFaceswapLoading);
  const prompt = useSelector(getTextForAudio);
  const percentServer = useSelector(getPercent);
  const profile = useSelector(getProfile);

  const videoRef = useRef(document.createElement("video"));
  const [playing, setPlaying] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [imageLoad, setImageLoad] = useState(true);

  const isSave = !project || !project?.faceswapProjectId;
  const target = project?.target || targetUrl;
  const source = project?.source || sourceUrl;
  const isImageTab = activeTab === "Image";
  const isGuest = profile.roleId === 3;

  const disabledSubmitButton = {
    [FaceswapProjectTab.Transform]: isSave ? isLoading || !source || !target : false,
    [FaceswapProjectTab["Talking Avatar"]]: isSave ? isLoading || !source || !target : false,
    [FaceswapProjectTab.Faceswap]: isSave ? isLoading || !source || !target : false,
    [FaceswapProjectTab["Replicate Media"]]: isSave ? isLoading || !prompt || (!source && !target) : false,
  };

  const handleLoad = () => setImageLoad(false);

  const actions = {
    [FaceswapProjectTab.Transform]: {
      createProject: createAIServiceServer({ serviceTypeID: 1, image: source, video: target }),
      getProject: getAIServiceByIdServer({ id: project?.faceswapProjectId as number }),
    },
    [FaceswapProjectTab["Talking Avatar"]]: {
      createProject: createAIServiceServer({ serviceTypeID: 2, image: source, audio: target }),
      getProject: getAIServiceByIdServer({ id: project?.faceswapProjectId as number }),
    },
    [FaceswapProjectTab.Faceswap]: {
      isSave: !project || !project?.faceswapProjectId,
      createProject: createFaceswapServer({
        faceswapType: isImageUrl(target) ? FaceSwapType.image : FaceSwapType.video,
        target: target,
        source: source,
      }),
      getProject: getFaceswapServer({ id: project?.faceswapProjectId as number }),
    },
    [FaceswapProjectTab["Replicate Media"]]: {
      createProject: isImageTab
        ? createAIServiceServer({
            serviceTypeID: 5,
            prompt,
            image: source,
          })
        : createAIServiceServer({
            serviceTypeID: 4,
            prompt,
            video: target,
          }),
      getProject: getAIServiceByIdServer({ id: project?.faceswapProjectId as number }),
    },
  };

  const tooltip = {
    [FaceswapProjectTab.Transform]: "Transformed video will appear here.",
    [FaceswapProjectTab["Talking Avatar"]]: "Video with Talking Avatar will appear here.",
    [FaceswapProjectTab.Faceswap]:
      "Final Render. Note: Faceswap will merge the facial features of both the faces and create a new unique face.",
    [FaceswapProjectTab["Replicate Media"]]: `Replicated ${isImageTab ? "image" : "video"} will appear here.`,
  };

  const generateCardText = {
    [FaceswapProjectTab.Transform]: 'Click on "Generate video" to start transforming',
    [FaceswapProjectTab["Talking Avatar"]]: 'Click on "Generate video" to start creating the Talking Avatar',
    [FaceswapProjectTab.Faceswap]: 'Click "Swap Face Now" button',
    [FaceswapProjectTab["Replicate Media"]]: `Click on "Regenerate ${isImageTab ? "image" : "video"}" button`,
  };

  const swapFace = () => {
    dispatch(actions[type].createProject);
  };

  const createNewProject = () => {
    dispatch(setPercent(0, "", 0));
    setImageLoad(true);
    dispatch(clearFaceswap());

    switch (type) {
      case FaceswapProjectTab.Faceswap:
        return navigate(`${pages.faceswap()}`);
      case FaceswapProjectTab["Talking Avatar"]:
        return navigate(`${pages.talkingAvatar()}`);
      case FaceswapProjectTab.Transform:
        return navigate(`${pages.transform()}`);
      default:
        return;
    }
  };

  const prefilledData = {
    [FaceswapProjectTab["Talking Avatar"]]: { type: "talkingAvatar", projectName: "talkingAvatar" },
    [FaceswapProjectTab.Faceswap]: { type: "faceSwap", projectName: "faceswap" },
    [FaceswapProjectTab.Transform]: { type: "transform", projectName: "transform" },
    [FaceswapProjectTab["Replicate Media"]]: { type: "replicateMedia", projectName: "replicateMedia" },
  };

  useEffect(() => {
    let intervalId: any;

    if (project && isLoading) {
      intervalId = setInterval(() => {
        dispatch(actions[type].getProject);
      }, 15000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isLoading, project?.status]);

  const togglePlayed = () => {
    setIsPlayed(!isPlayed);
    setPlaying(false);
    videoRef.current.currentTime = 0;
  };

  const togglePlay = () => {
    setPlaying(!playing);
    if (playing === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const signUpPopupOpen = () => {
    const prefilled: SignUpPopupTypes = {
      type: prefilledData[type]?.type || "faceSwap",
      link: { projectName: prefilledData[type]?.projectName || "faceswap", projectId: project?.faceswapProjectId },
      isClick: true,
    };

    dispatch(actions[type].createProject);
    dispatch(updatePopup({ popup: Popups.signUpPopup, status: true, prefilled }));
  };

  useEffect(() => {
    if (isGuest && project?.faceswapProjectId) {
      const prefilled: SignUpPopupTypes = {
        type: prefilledData[type]?.type || "faceSwap",
        link: { projectName: prefilledData[type]?.projectName || "faceswap", projectId: project?.faceswapProjectId },
        isClick: true,
      };

      dispatch(updatePopup({ popup: Popups.signUpPopup, status: true, prefilled }));
    }
  }, [project?.faceswapProjectId]);

  useEffect(() => {
    if (project) {
      if (project?.status === 3) {
        dispatch(setPercent(100, type, project.faceswapProjectId));
        setImageLoad(true);
        setTimeout(() => {
          dispatch(setPercent(0, "", 0));
        }, 1000);
      } else if (project.status === 4) {
        dispatch(setPercent(0, "", 0));
      }
    }
  }, [project?.status]);

  useEffect(() => {
    if (project?.status === 1 || project?.status === 2) {
      const intervalId = setInterval(() => {
        const lastUpdateTime = formatDateString(project?.insertDateTime);

        const timeElapsed = (Number(new Date()) - Number(lastUpdateTime)) / 1000;
        const percentComplete = (timeElapsed / Number(project?.estimatedTime)) * 100 || 1;

        dispatch(setPercent(percentComplete, type, project.faceswapProjectId));
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [project?.status]);

  return (
    <Card>
      <Title>
        <LiveVideoIcon />
        <Text>{isImageTab ? "Image" : "Video"}</Text>
        <Info>
          <InfoIcon />
          <Tooltip
            text={tooltip[type]}
            style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
            position="bottom"
          />
        </Info>
      </Title>
      <CardContent>
        {isLoading ? (
          <VideoCardRendering
            isImageTab={isImageTab}
            generateCardText={generateCardText[type]}
            percent={parsePercent(percentServer.percent < 1 ? 1 : percentServer.percent)}
          />
        ) : project && project.outputUrl ? (
          <>
            {isImageUrl(project.outputUrl) ? (
              <ImageWrapper>
                <img src={project.outputUrl} onLoad={handleLoad} style={{ display: imageLoad ? "none" : "block" }} />
                {imageLoad && <CircularProgress color="#fff" />}
              </ImageWrapper>
            ) : (
              <VideoWrapper>
                <video
                  ref={videoRef}
                  onLoadedData={handleLoad}
                  onEnded={togglePlayed}
                  style={{ display: imageLoad ? "none" : "block" }}
                >
                  <source src={project.outputUrl} type="video/mp4" />
                </video>
                {!imageLoad ? (
                  <PlayPauseButton isPlay={playing} handlePlayChange={() => togglePlay()} />
                ) : (
                  <CircularProgress color="#fff" />
                )}
              </VideoWrapper>
            )}
          </>
        ) : (
          <StartVideoCard
            isImageTab={isImageTab}
            generateCardText={generateCardText[type]}
            percent={parsePercent(percentServer.percent < 1 ? 1 : percentServer.percent)}
          />
        )}
      </CardContent>
      <ButtonsWrapper>
        <Button
          text={isLoading ? "Generation..." : "Download"}
          icon={<DownloadBoxIcon />}
          buttonTheme={ButtonThemes.Transparent}
          onClick={(e: any) => downloadFile(e, project!.outputUrl)}
          disabled={!project || !project?.outputUrl}
        />
        {/* <Button
            text={"Share"}
            icon={<ShareLinkIcon />}
            onClick={() => {
              return;
            }}
            buttonTheme={ButtonThemes.Transparent}
            style={{ zIndex: "1" }}
            disabled={!data || !data?.outputUrl}
          /> */}
      </ButtonsWrapper>
      <Delimiter />
      <SwapFaceButton>
        <Button
          onClick={!isGuest ? (isSave ? () => swapFace() : () => createNewProject()) : () => signUpPopupOpen()}
          disabled={disabledSubmitButton[type]}
        >
          {!isGuest ? (isSave ? generateButtonText : "Create new project") : "Sign up to generate"}
        </Button>
      </SwapFaceButton>
    </Card>
  );
};

interface VideoCardProps {
  isImageTab: boolean;
  generateCardText: string;
  percent: string | number;
}

const StartVideoCard = ({ isImageTab, generateCardText }: VideoCardProps) => (
  <SecondCard>
    <ActionInfo>
      <CircleWrapper>
        <CheckIcon />
      </CircleWrapper>
      <ActionTitle>Your generated {isImageTab ? "image" : "video"} will appear here!</ActionTitle>
      <ActionText>{generateCardText}</ActionText>
    </ActionInfo>
  </SecondCard>
);

const VideoCardRendering = ({ isImageTab, percent }: VideoCardProps) => (
  <SecondCard>
    <ActionInfo>
      <BasePageLoader />
      <ActionRenderingText>{isImageTab ? "Image" : "Video"} is rendering...</ActionRenderingText>
      <ProgressBar percent={percent} />
    </ActionInfo>
  </SecondCard>
);

const Card = styled.div`
  flex: 2;
  min-width: 451px;
  max-width: 100%;
  background: #15171a;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  padding: 17px 18px;
  margin-bottom: 18px;

  @media (max-width: 1200px) {
    padding: 10px 18px 17px;
  }
`;

const SecondCard = styled.div`
  width: 100%;
  max-height: 100%;
  max-width: 451px;
  background: #15171a;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 192px;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  gap: 10px;

  & > svg {
    width: 25px;
    height: 25px;
  }

  & > :first-child {
    width: 32px;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  margin-right: auto;
`;

const Info = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 15px;
  font-weight: 800;
  line-height: 18.83px;

  :hover > div {
    left: -120px;
    top: 30px;
    padding: 12px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    width: 280px;
    box-shadow: none;
    opacity: 0.9;
    visibility: visible;
    background: #292937;

    &::after {
      opacity: 0.6;
      border-color: transparent transparent #292937 transparent;
    }

    span {
      font-weight: 500;
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  margin: 16px 0 20px;

  & button {
    height: 30px;
    max-width: 140px;
    padding: 0px 18px;
    gap: 10px;
    border-radius: 50px;
    border: 1px solid #6f7074;
    justify-content: center;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 400px;

  & img {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 400px;

  & video {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const SwapFaceButton = styled.div`
  padding: 26px 0;

  button {
    background: #0063b4;
    color: #ffffff;
    height: 48px;
    min-width: 170px;
    padding: 14px 48px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.41px;
  }
`;

const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 360px;
  border: 1px solid #ffffff;

  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
  }
`;

const ActionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  svg {
    width: 90px;
    height: 90px;

    @media (max-width: 1200px) {
      width: 55px;
      height: 55px;
    }
  }
`;

const ActionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`;

const ActionText = styled.div`
  color: #959595;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
`;

const ActionRenderingText = styled(ActionText)`
  font-size: 15px;
`;

const Delimiter = styled.div`
  width: 100%;
  height: 1px;
  background: #3e4453;
`;

export default VideoCard;
