export const MicIcon2626 = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9983 4.33352C11.8033 4.33352 10.8316 5.30527 10.8316 6.50019V11.9169C10.8316 13.1118 11.8033 14.0835 12.9983 14.0835C14.1932 14.0835 15.1649 13.1118 15.1649 11.9169V6.50019C15.1649 5.30527 14.1932 4.33352 12.9983 4.33352ZM17.3324 11.9167C17.3324 14.3066 15.3889 16.2501 12.9991 16.2501C10.6093 16.2501 8.66577 14.3066 8.66577 11.9167V6.50008C8.66577 4.11025 10.6093 2.16675 12.9991 2.16675C15.3889 2.16675 17.3324 4.11025 17.3324 6.50008V11.9167ZM19.4991 10.8334C20.0982 10.8334 20.5824 11.3187 20.5824 11.9167C20.5824 15.7301 17.7517 18.8847 14.0824 19.4134V21.6667H16.3629C16.8969 21.6667 17.3324 22.1022 17.3324 22.6363V22.8638C17.3324 23.3979 16.8969 23.8334 16.3629 23.8334H9.63535C9.10127 23.8334 8.66577 23.3979 8.66577 22.8638V22.6363C8.66577 22.1022 9.10127 21.6667 9.63535 21.6667H11.9158V19.4134C8.24652 18.8847 5.41577 15.7301 5.41577 11.9167C5.41577 11.3187 5.90002 10.8334 6.4991 10.8334C7.09819 10.8334 7.58244 11.3187 7.58244 11.9167C7.58244 14.9035 10.0124 17.3334 12.9991 17.3334C15.9859 17.3334 18.4158 14.9035 18.4158 11.9167C18.4158 11.3187 18.9 10.8334 19.4991 10.8334Z"
      fill="#3B3D47"
    />
    <mask id="mask0_1_314" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="5" y="2" width="16" height="22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9983 4.33352C11.8033 4.33352 10.8316 5.30527 10.8316 6.50019V11.9169C10.8316 13.1118 11.8033 14.0835 12.9983 14.0835C14.1932 14.0835 15.1649 13.1118 15.1649 11.9169V6.50019C15.1649 5.30527 14.1932 4.33352 12.9983 4.33352ZM17.3324 11.9167C17.3324 14.3066 15.3889 16.2501 12.9991 16.2501C10.6093 16.2501 8.66577 14.3066 8.66577 11.9167V6.50008C8.66577 4.11025 10.6093 2.16675 12.9991 2.16675C15.3889 2.16675 17.3324 4.11025 17.3324 6.50008V11.9167ZM19.4991 10.8334C20.0982 10.8334 20.5824 11.3187 20.5824 11.9167C20.5824 15.7301 17.7517 18.8847 14.0824 19.4134V21.6667H16.3629C16.8969 21.6667 17.3324 22.1022 17.3324 22.6363V22.8638C17.3324 23.3979 16.8969 23.8334 16.3629 23.8334H9.63535C9.10127 23.8334 8.66577 23.3979 8.66577 22.8638V22.6363C8.66577 22.1022 9.10127 21.6667 9.63535 21.6667H11.9158V19.4134C8.24652 18.8847 5.41577 15.7301 5.41577 11.9167C5.41577 11.3187 5.90002 10.8334 6.4991 10.8334C7.09819 10.8334 7.58244 11.3187 7.58244 11.9167C7.58244 14.9035 10.0124 17.3334 12.9991 17.3334C15.9859 17.3334 18.4158 14.9035 18.4158 11.9167C18.4158 11.3187 18.9 10.8334 19.4991 10.8334Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1_314)"></g>
  </svg>
);

export const MicIcon2626Landing = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 10.75V6.75C7.5 4.269 9.519 2.25 12 2.25C14.481 2.25 16.5 4.269 16.5 6.75V10.75C16.5 13.231 14.481 15.25 12 15.25C9.519 15.25 7.5 13.231 7.5 10.75ZM20 10.75C20 10.197 19.552 9.75 19 9.75C18.448 9.75 18 10.197 18 10.75C18 14.059 15.309 16.75 12 16.75C8.691 16.75 6 14.059 6 10.75C6 10.197 5.552 9.75 5 9.75C4.448 9.75 4 10.197 4 10.75C4 14.822 7.06 18.1859 11 18.6809V19.75H8C7.448 19.75 7 20.197 7 20.75C7 21.303 7.448 21.75 8 21.75H16C16.552 21.75 17 21.303 17 20.75C17 20.197 16.552 19.75 16 19.75H13V18.6809C16.94 18.1859 20 14.822 20 10.75Z"
      fill="#41416E"
    />
  </svg>
);
