import styled from "styled-components";

import Tooltip from "./Tooltip";
import { InfoIcon1818Grey } from "../../../components/Icons/Icons";

interface TooltipCellProps {
  text: React.ReactNode;
  tooltip?: string;
  toLeft?: boolean;
}
const TooltipCell = ({ text, tooltip, toLeft }: TooltipCellProps) => (
  <Wrapper className="tooltipCell">
    {text}
    {tooltip && (
      <span className="tooltipWrapper">
        <InfoIcon1818Grey />
        <Tooltip title={tooltip} toRight={toLeft ? false : true} />
      </span>
    )}
  </Wrapper>
);

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  a {
    text-decoration: underline;
    outline: 2px solid transparent;
    transition: all 0.25s;

    @media (hover: hover) and (pointer: fine) {
      :hover {
        background-color: #b0d4f5;
        outline-color: #b0d4f5;
      }
    }
  }

  .tooltipWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: help;

    :hover .tooltip {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    .tooltipWrapper {
      .tooltip {
        left: unset;
        right: 0;
        max-width: 200px;
      }
    }
  }
`;

export default TooltipCell;
