import { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { ContentState, EditorState, SelectionState } from "draft-js";

import EditorSelect from "../EditorSelect";
import * as Styles from "../../index.styles";
import Player from "../../../../components/Player/Player";
import Button from "../../../../components/Button/Button";
import TextBlock from "../../../../components/TextBlock/TextBlock";
import ActorsSidebar from "../../../../components/ActorsSidebar/ActorsSidebar";
import { IActor } from "../../../../types/actor";
import { Paragraphs, Zone } from "../../../../types/project";
import { editorSelectOptions } from "../../../../mocks/actors";
import { calcParagraphsLength } from "../../../../lib/calcParagraphsLength";
import { getActorsList, getActorsListLoading } from "../../../../redux/reducers/actorReducer";
import { DropdownIconThin, SearchFilterIcon, SearchIcon } from "../../../../components/Icons/Icons";
import { getAutoSaveLoading, getProject, getProjectListLoading } from "../../../../redux/reducers/projectReducer";
import { useDispatch } from "react-redux";
import { Popups, updatePopup } from "../../../../redux/actions/popupsActions";
import CircularProgress from "../../../../components/Icons/CircularProgress";

interface Props {
  route: string;
  search: string;
  styleMap: any;
  selectedZone?: Zone;
  lastSel: SelectionState;
  editorSelect: string;
  editorContent: EditorState[];
  speechCategory: number;
  paragraphs: Paragraphs[];
  paragraphActive?: number;
  updateProject: () => void;
  createProject: () => void;
  handleOpenPopup: () => void;
  signUpPopupOpen: (projectId?: number) => void;
  setActorActive: (actors: IActor[]) => void;
  setLastSel: (lastSel: SelectionState) => void;
  handleSearchChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  setFeatureActive: (featureActive: number) => void;
  handleActorSidebarClick: (actor: IActor) => void;
  setParagraphs: (paragraphs: Paragraphs[]) => void;
  handleParagraphActive: (paragraphActive: number) => void;
  setEditorContent: (editorContent: EditorState[]) => void;
  handleEditorSelectValue: (editorSelect: string) => void;
}

const Text2Speech = ({
  route,
  search,
  lastSel,
  styleMap,
  editorSelect,
  selectedZone,
  editorContent,
  speechCategory,
  paragraphs,
  paragraphActive,
  setLastSel,
  updateProject,
  createProject,
  setParagraphs,
  setActorActive,
  handleOpenPopup,
  signUpPopupOpen,
  setFeatureActive,
  setEditorContent,
  handleSearchChange,
  handleParagraphActive,
  handleActorSidebarClick,
  handleEditorSelectValue,
}: Props) => {
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const actorsList = useSelector(getActorsList);
  const isLoading = useSelector(getActorsListLoading);
  const autoSaveLoading = useSelector(getAutoSaveLoading);
  const projectListLoading = useSelector(getProjectListLoading);

  const actorId = paragraphs?.find((paragraph) => paragraph.order === paragraphActive)?.actorId;
  const actor = actorsList?.find((actor) => actor.actorId === actorId);
  const paragraphActor = paragraphs[(paragraphActive as number) - 1]?.actor;
  const paragraphActorsList = paragraphs[(paragraphActive as number) - 1]?.actorsList;

  const handleEditorSelectChanges = (newEditorSelect: string) => {
    const showToast =
      paragraphs.length > 1 ||
      paragraphs.some((paragraph) => paragraph.data && paragraph.data.some((zone) => zone.text));

    if (showToast) {
      dispatch(
        updatePopup({
          popup: Popups.aiVoicesText2SpeechReplaceProjectPopup,
          status: true,
          prefilled: {
            onCancel: () => handleEditorSelectValue(editorSelect),
            onConfirm: () => {
              const newParagraphs = paragraphs
                ?.filter((_, index) => index === 0)
                .map((paragraph) => ({ ...paragraph, data: [{ text: "", features: [] }] }));

              setParagraphs(newParagraphs);
              setEditorContent([
                EditorState.moveFocusToEnd(EditorState.createWithContent(ContentState.createFromText(""))),
              ]);
              handleParagraphActive(1);
              handleEditorSelectValue(newEditorSelect);
            },
          },
        }),
      );
      return;
    }

    handleEditorSelectValue(newEditorSelect);
  };

  return (
    <>
      <ActorsSidebar
        actors={actorsList}
        paragraphs={paragraphs}
        active={actorId}
        currentParagraphActor={actor || paragraphActor}
        currentParagraphActorsList={paragraphActorsList || []}
        speechCategory={speechCategory}
        onClick={handleActorSidebarClick}
        setActorActive={setActorActive}
        isLoading={isLoading}
      />
      <Styles.Right>
        <Styles.Column>
          <TextBlock
            projectId={project?.projectId}
            route={route}
            actors={actorsList}
            paragraphs={paragraphs}
            setParagraphs={setParagraphs}
            actorActive={actorId}
            setActorActive={setActorActive}
            paragraphActive={paragraphActive}
            setParagraphActive={handleParagraphActive}
            isLoading={projectListLoading || (!route && isLoading)}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            setFeatureActive={setFeatureActive}
            lastSel={lastSel}
            setLastSel={setLastSel}
            calcParagraphsLength={calcParagraphsLength(paragraphs)}
            styleMap={styleMap}
            editorSelect={editorSelect}
            speechCategory={speechCategory}
          />
          <EditorSelect
            optionsList={editorSelectOptions}
            value={editorSelect}
            onChange={handleEditorSelectChanges}
            actionIcon={<DropdownIconThin />}
          />
        </Styles.Column>
        <Player
          projectId={project?.projectId}
          selectedZone={selectedZone}
          paragraphs={paragraphs}
          editorSelect={editorSelect}
          signUpPopupOpen={signUpPopupOpen}
        />
        <Styles.MobileOnly>
          <Button
            text={
              autoSaveLoading ? (
                <CircularProgress color="#fff" />
              ) : project?.projectId ? (
                "Update project"
              ) : (
                "Save project"
              )
            }
            onClick={project?.projectId ? updateProject : createProject}
          />
        </Styles.MobileOnly>
      </Styles.Right>
    </>
  );
};

export default Text2Speech;
