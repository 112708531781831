import styled, { createGlobalStyle } from "styled-components";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useContext, ReactElement, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/Button";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import DashboardLayout from "../../layouts/DashboardLayout";
import UploadVideoStep from "./components/steps/UploadVideoStep";
import DownloadVideoStep from "./components/steps/DownloadVideoStep";
import SelectVideoOptionsStep from "./components/steps/SelectVideoOptionsStep";
import RecentlyGeneratedVideosDrawer from "./components/RecentlyGeneratedVideosDrawer";
import { VideoTranslationContext } from "../../App";
import {
  getVideoTranslateProjectByIdServer,
  getVideoTranslateProjectsServer,
  updateSingleVideoData,
} from "../../redux/actions/videoTranslationActions";
import {
  getVideoTranslateProjects,
  getVideoTranslateProjectsLoading,
} from "../../redux/reducers/videoTranslationReducer";
import { LogoIcon } from "../../components/Icons/Icons";
import { stepsOptions } from "../../mocks/videoTranslation";
import { getProfile } from "../../redux/reducers/profileReducer";
import { VideoTranslationSteps } from "../../types/videoTranslationContext";

const VideoTranslationPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { roleId, hasVideoTranslateLicense, hasUnlimitedVideoTranslate, videoTranslateAllowed, videoTranslateUsed } =
    useSelector(getProfile);
  const isGuest = roleId === 3;
  const videoTranslateProjects = useSelector(getVideoTranslateProjects);
  const videoTranslateProjectsLoading = useSelector(getVideoTranslateProjectsLoading);
  const { currentStep, setCurrentStep } = useContext(VideoTranslationContext);
  const isScrollPage = currentStep === VideoTranslationSteps.SelectVideoOptions;
  const credits = videoTranslateAllowed - videoTranslateUsed;

  const steps: Record<number, ReactElement> = {
    [VideoTranslationSteps.UploadVideo]: <UploadVideoStep />,
    [VideoTranslationSteps.SelectVideoOptions]: <SelectVideoOptionsStep />,
    [VideoTranslationSteps.DownloadVideo]: <DownloadVideoStep />,
  };

  const fetchData = (isLoading?: boolean) => {
    dispatch(getVideoTranslateProjectsServer({ isLoading }));
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => fetchData(false), 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!videoTranslateProjects.length) return;
    if (!id) return;
    if (videoTranslateProjectsLoading) return;

    const projectFound = videoTranslateProjects.find((item: any) => item.videoTranslateProjectId.toString() === id);

    if (projectFound) {
      setCurrentStep(stepsOptions.length - 1);
      dispatch(updateSingleVideoData({ data: null }));
      dispatch(getVideoTranslateProjectByIdServer({ id: parseInt(id) }));
    } else {
      toast.error(`No project with ID ${id} found!`, { toastId: "no-video-translation-project-toast" });
      navigate("/video-translation/");
      dispatch(updateSingleVideoData({ data: null }));
    }
  }, [videoTranslateProjectsLoading, videoTranslateProjects.length, id]);

  return (
    <>
      <GlobalStyle />
      <Wrapper isScrollPage={isScrollPage}>
        <DashboardLayout
          childrenClassName="childrenClassName"
          startAdornment={
            <NavLink to="/">
              <LogoIcon />
            </NavLink>
          }
          navActions={
            !hasVideoTranslateLicense && (
              <StyledButton text="Pricing" onClick={() => navigate("/transparent-pricing")} />
            )
          }
          hasUnlimitedOnService={hasUnlimitedVideoTranslate}
          hasCredits={credits}
        >
          <CenterContent isScrollPage={isScrollPage}>{steps[currentStep]}</CenterContent>
          {!isGuest && currentStep !== VideoTranslationSteps.SelectVideoOptions && (
            <RecentlyGeneratedVideosDrawer data={videoTranslateProjects} isLoading={videoTranslateProjectsLoading} />
          )}
        </DashboardLayout>
      </Wrapper>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }: any) => theme.primaryBackground};
  }
`;

const Wrapper = styled("div")<{ isScrollPage?: boolean }>`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  & > div {
    padding: 0 32px 0 20px;
    flex: 1;
    height: 100%;
  }

  ${({ isScrollPage }) =>
    isScrollPage &&
    `
      & > div {
        padding: 0;

        & > div:first-of-type {
          padding: 24px 40px 0 32px;
        }

        & > div:last-of-type {
          padding: 0 40px 15px 32px;
        }
      }

      .childrenClassName {
        overflow-y: auto;
      }
    `}

  @media (max-width: 900px) {
    .childrenClassName {
      overflow-y: auto;
    }
  }

  @media (max-width: 1001px) {
    & .navigation {
      visibility: unset;
      height: 88px;
    }
  }
`;

const NavLink = styled(Link)`
  display: flex;

  svg {
    width: 132px;
    height: 32px;
  }
`;

const CenterContent = styled.div<{ isScrollPage?: boolean }>`
  height: 100%;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto 10px;

  ${({ isScrollPage }) =>
    isScrollPage &&
    `
    height: auto;
  `}

  @media (max-width: 900px) {
    height: auto;
  }
`;

const StyledButton = styled(Button)`
  padding: 8px 10px;
  height: auto;
  min-width: 150px;
`;

export default withPrivateRoute(VideoTranslationPage);
