import {
  ContentState,
  DraftInlineStyleType,
  EditorState,
  RawDraftInlineStyleRange,
  SelectionState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MutableRefObject, useEffect, useRef, useState } from "react";

import { sum } from "../lib/editorSum";
import {
  audioToTranslatedTextServer,
  audioTranslateProjectAddServer,
  clearCurrentSpeech2SpeechProject,
  clearCurrentSpeechTranslationProject,
  generateVoiceServer,
  getAudioTranslateProjectByIdServer,
} from "../redux/actions/actorActions";
import {
  ProjectType,
  clearCurrentProject,
  createProjectServer,
  getProjectServer,
  updateProjectLoading,
  updateProjectServer,
} from "../redux/actions/projectAction";
import { IActor } from "../types/actor";
import { dataToState } from "../lib/projectUtils";
import { sidebarBoxes } from "../mocks/sidebarBoxes";
import { setInlineStyle } from "../lib/setInlineStyle";
import { SentryErrors, sentryErrors } from "../lib/sentry";
import { GenerateAudioParams } from "../types/generateAudio";
import { SignUpPopupTypes } from "../types/signUpPopupTypes";
import { generateEditorStyle } from "../lib/generateEditorStyle";
import { EditorSelect, editorSelectOptions } from "../mocks/actors";
import { Popups, updatePopup } from "../redux/actions/popupsActions";
import { checkIfZoneCached, checkIfZoneMatchNoAuthor, getAllZones } from "../lib/editorUtils";
import { getProject, getProjectAudio, getProjectList } from "../redux/reducers/projectReducer";
import { AudioTranslateProject, Paragraphs, Project, ProjectModules, Zone } from "../types/project";
import { getActorsList, getSpeechTranslation, getTextFromAudio } from "../redux/reducers/actorReducer";

const initialParagraphsData = [
  {
    actorId: 1,
    actor: {
      actorId: 1,
      actorTypeId: 1,
      flagPath: "",
      isBookmarked: false,
      language: "en-US",
      name: "Sonia",
      photo: "",
      audioSampleLink: "",
      brief: "",
      categoryType: "",
      content: "",
      mood: "",
      region: "",
      voiceAge: "",
      voiceName: "",
    },
    order: 1,
    data: [
      {
        text: "",
        features: [
          {
            key: "",
            value: "",
          },
        ],
      },
    ],
  },
];

export enum AIVoicesMainTabs {
  "Text2Speech" = "Text2Speech",
  "Speech2Speech" = "Speech2Speech",
  "SpeechTranslation" = "SpeechTranslation",
}

export const AIVoicesMainTabsLabels = {
  [AIVoicesMainTabs.Text2Speech]: "Text to Speech",
  [AIVoicesMainTabs.Speech2Speech]: "Speech to Speech",
  [AIVoicesMainTabs.SpeechTranslation]: "Speech to Translation",
};

export enum AIVoicesSidebarTabs {
  "Settings" = "Settings",
  "History" = "History",
}

export interface IAudio {
  name: string;
  src: string;
  oldSrc?: string;
  file: any;
  duration: number;
}

export const AiVoicesSidebarTabs = [{ label: AIVoicesSidebarTabs.Settings }, { label: AIVoicesSidebarTabs.History }];

export const AiVoicesMainTabs = [
  { value: AIVoicesMainTabs.Text2Speech, label: AIVoicesMainTabsLabels.Text2Speech },
  { value: AIVoicesMainTabs.Speech2Speech, label: AIVoicesMainTabsLabels.Speech2Speech },
  { value: AIVoicesMainTabs.SpeechTranslation, label: AIVoicesMainTabsLabels.SpeechTranslation },
];

export const useAIVoicesTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState<AIVoicesMainTabs>(AiVoicesMainTabs[0].value);
  const [activeSidebarTab, setActiveSidebarTab] = useState<AIVoicesSidebarTabs>(AiVoicesSidebarTabs[0].label);

  const handleActiveMainTab = (tab: AIVoicesMainTabs | string) => {
    setActiveTab(tab as AIVoicesMainTabs);
    searchParams.set("tab", AIVoicesMainTabs[tab as AIVoicesMainTabs]);
    navigate(`?${searchParams.toString()}`);
  };

  const handleActiveSidebarTab = (tab: AIVoicesSidebarTabs | string) => {
    setActiveSidebarTab(tab as AIVoicesSidebarTabs);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab) {
      setActiveTab(AIVoicesMainTabs[tab as AIVoicesMainTabs]);
    }
  }, [location]);

  return {
    activeTab,
    activeSidebarTab,
    handleActiveMainTab,
    handleActiveSidebarTab,
  };
};

interface UseAIVoicesControlRightSidebarProps {
  paragraphs: Paragraphs[];
  paragraphActive?: number;
  setParagraphs: (paragraphs: Paragraphs[]) => void;
}

export const useAIVoicesControlSidebar = ({
  paragraphs,
  paragraphActive,
  setParagraphs,
}: UseAIVoicesControlRightSidebarProps) => {
  const data = useSelector(getTextFromAudio);

  const [rightSidebarOpen, setRightSidebarOpen] = useState(true);
  const [language, setLanguage] = useState<string>("");
  const [translatedText, setTranslatedText] = useState("");
  const [activeActorForAudio, setActiveActorForAudio] = useState<{ [key: string]: IActor | undefined }>({
    [AIVoicesMainTabs.Speech2Speech]: undefined,
    [AIVoicesMainTabs.SpeechTranslation]: undefined,
  });

  const handleRightSidebarOpen = () => setRightSidebarOpen(!rightSidebarOpen);

  const handleLanguageSelect = (newValue: any) => setLanguage(newValue);
  const handleTranslatedTextChange = (e: any) => setTranslatedText(e.target.value);

  const handleActiveActorForAudio = (tab: string, actors?: IActor[] | IActor) => {
    const selectedActor = Array.isArray(actors) ? actors?.[0] : actors;

    setActiveActorForAudio({
      ...activeActorForAudio,
      [tab]: selectedActor,
    });
  };

  // this will change actve actor immediatelye
  const handleActorSidebarClick = (actor: IActor) => {
    setParagraphs(
      paragraphs?.map((paragraph) => {
        const isActorExist = paragraph?.actorsList?.find((item) => item.actorId === actor.actorId);

        if (paragraph.order === paragraphActive) {
          const newActorsList = isActorExist ? paragraph?.actorsList : [actor, ...(paragraph?.actorsList || [])];
          return {
            ...paragraph,
            actorId: actor.actorId,
            actor,
            actorsList: newActorsList,
          };
        } else {
          const newActorsList = isActorExist ? paragraph?.actorsList : [...(paragraph?.actorsList || []), actor];

          return {
            ...paragraph,
            actorsList: newActorsList,
          };
        }
      }),
    );
  };

  // this will add actor to array
  const setActorActive = (actors: IActor[]) => {
    const selectedActor = actors?.[0];
    setParagraphs(
      paragraphs?.map((paragraph) => {
        if (paragraph.order === paragraphActive) {
          return {
            ...paragraph,
            actorId: selectedActor.actorId,
            actor: selectedActor,
            actorsList: actors,
          };
        } else {
          // we need to make sure that actors[0].id === selectedActor.actorId;
          // in this else it will definitely != so we need to parse it.
          const mutatedActors = [...actors];
          let newMutatedActors = [...mutatedActors];
          let savedItem = null;

          const indexOfSelectedActor = mutatedActors.findIndex((item) => {
            return item.actorId === paragraph.actorId;
          });

          if (indexOfSelectedActor > -1) {
            savedItem = mutatedActors[indexOfSelectedActor];
            mutatedActors.splice(indexOfSelectedActor, 1);
          }

          if (savedItem) {
            newMutatedActors = [savedItem, ...mutatedActors];
          }
          return {
            ...paragraph,
            actorsList: newMutatedActors,
          };
        }
      }),
    );
  };

  useEffect(() => {
    if (window.innerWidth <= 1000) setRightSidebarOpen(false);
    else setRightSidebarOpen(true);
  }, []);

  useEffect(() => {
    if (data) {
      const e = { target: { value: data } };
      handleTranslatedTextChange(e);
    }
  }, [data]);

  return {
    language,
    translatedText,
    rightSidebarOpen,
    activeActorForAudio,
    setActorActive,
    handleLanguageSelect,
    handleRightSidebarOpen,
    handleActorSidebarClick,
    handleActiveActorForAudio,
    handleTranslatedTextChange,
  };
};

interface UseAIVoicesProjectProps {
  paragraphs: Paragraphs[];
  selectedZone?: Zone;
  editorSelect?: string;
}

export const useAIVoicesProject = ({ paragraphs, selectedZone, editorSelect }: UseAIVoicesProjectProps) => {
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const projectList = useSelector(getProjectList);
  const { cachedZonesAudio } = useSelector(getProjectAudio);
  const { Simple } = EditorSelect;

  const removeEmptyParagrahs = (paragraphs: Paragraphs[]) => {
    const newParagraphs = paragraphs.filter((paragraph) => paragraph.data.some((data) => data.text.length));

    if (!newParagraphs.length) return paragraphs.slice(0, 1);

    return newParagraphs;
  };

  const removeEmptyZones = (paragraphst: Paragraphs[]) => {
    paragraphst.forEach((paragraph) => (paragraph.data = paragraph.data.filter((data) => data.text !== "")));

    return paragraphst;
  };

  const passCachedZonesToApi = (paragraphs: Paragraphs[]) => {
    const parapgarphsWithAudioPath = paragraphs.map((paragraph: Paragraphs) => {
      const data = paragraph.data.map((zone: Zone) => {
        const zoneWithActor = { ...zone, actorId: paragraph.actorId };
        return {
          ...zone,
          audioPath:
            checkIfZoneCached(zoneWithActor, cachedZonesAudio)?.outputUrl ||
            checkIfZoneCached(zoneWithActor, cachedZonesAudio)?.audioPath,
        };
      });
      return { ...paragraph, data };
    });
    return parapgarphsWithAudioPath;
  };

  const generateAllAudios = ({ onSuccess, withSelectedZone = true }: GenerateAudioParams) => {
    // here we need to pick zones starting from 'selected paragraph'
    const paragraphsModified = removeEmptyParagrahs(removeEmptyZones(paragraphs));
    let allZones = getAllZones(paragraphsModified);
    if (selectedZone && withSelectedZone) {
      const currentZoneIndex = allZones.findIndex((zone) => checkIfZoneMatchNoAuthor(zone, selectedZone));
      allZones = allZones.slice(currentZoneIndex);
    }
    const nonCachedZones: Zone[] = [];
    // const nonCachedZones = allZones.filter((zone) => !checkIfZoneCached(zone, cachedZonesAudio));
    allZones.forEach((zone, index) => {
      if (!checkIfZoneCached(zone, cachedZonesAudio)) {
        nonCachedZones.push(zone);
      }
    });
    if (nonCachedZones.length > 0) {
      dispatch(generateVoiceServer({ data: nonCachedZones }));
    } else {
      onSuccess?.();
    }
  };

  const handleProjectCreate = (newData: Project) => {
    newData.paragraphs = removeEmptyZones(JSON.parse(JSON.stringify(newData.paragraphs)));
    newData.paragraphs = removeEmptyParagrahs(newData.paragraphs);
    newData.paragraphs = passCachedZonesToApi(newData.paragraphs);
    if (newData.paragraphs.length === 0) {
      alert("Warning! Autosave disabled, please refresh the page and contact support with code 1007");
      return;
    }
    dispatch(createProjectServer(newData));
  };

  const handleProjectUpdate = (newData: Project) => {
    newData.paragraphs = removeEmptyZones(JSON.parse(JSON.stringify(newData.paragraphs)));
    newData.paragraphs = removeEmptyParagrahs(newData.paragraphs);
    newData.paragraphs = passCachedZonesToApi(newData.paragraphs);
    if (newData.paragraphs.length === 0) {
      // alert("Warning! Autosave disabled, please refresh the page and contact support with code 1007");
      return;
    }
    if (newData.paragraphs[0].data.length === 0) {
      // toast.error("Autosave dsiabled, please contact support with code 1008 if you can't save the project");
      return;
    }
    dispatch(
      updateProjectServer(
        newData.projectTypeId,
        newData.projectId,
        newData.title,
        newData.paragraphs,
        newData.isSimple,
        "projectSavingEvent",
      ),
    );
  };

  const handleAudioTranslateProjectCreate = (newData: AudioTranslateProject) => {
    if (!newData.file) {
      toast.error("Please upload or record your audio!");
      return;
    }

    const formData = new FormData();
    formData.append("file", newData.file);
    formData.append("Title", "Audio Translate");
    formData.append("TargetLanguage", newData.targetLanguage || "en");
    formData.append("ConversionType", `${newData.conversionType}`);
    formData.append("Actor", `${JSON.stringify(newData.actor)}`);
    formData.append("ActorID", `${newData.actorID}`);
    formData.append("TranslatedText", newData.translatedText || "");

    dispatch(audioTranslateProjectAddServer(formData, newData.conversionType));
  };

  const createProject = () =>
    handleProjectCreate({
      projectTypeId: ProjectType.TTI,
      title: `Project ${projectList?.length + 1}`,
      paragraphs,
      isSimple: editorSelect === Simple,
    });

  const updateProject = () =>
    handleProjectUpdate({
      projectId: project?.projectId,
      projectTypeId: ProjectType.TTI,
      title: project?.title || "",
      paragraphs,
      isSimple: editorSelect === Simple,
    });

  return {
    createProject,
    updateProject,
    generateAllAudios,
    handleAudioTranslateProjectCreate,
  };
};

interface UseAIVoicesEditorProps {
  route: string;
  paragraphs: Paragraphs[];
  isRouteHydrated: boolean;
  paragraphActive?: number;
  isProjectRestored: MutableRefObject<boolean>;
  setParagraphs: (paragraphs: Paragraphs[]) => void;
  setIsProjectHydrated: (isRouteHydrated: boolean) => void;
}

export const useAIVoicesEditor = ({
  route,
  paragraphs,
  isRouteHydrated,
  paragraphActive,
  isProjectRestored,
  setParagraphs,
  setIsProjectHydrated,
}: UseAIVoicesEditorProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const speechTranslationProject = useSelector(getSpeechTranslation);
  const isSpeechTranslationProjectLoading = speechTranslationProject.isLoading;
  const { cachedZonesAudio, loadingZonesAudio } = useSelector(getProjectAudio);
  const { Simple, Advanced } = EditorSelect;

  const [projectAudio, setProjectAudio] = useState<{ [key: string]: IAudio | null }>({
    [AIVoicesMainTabs.Speech2Speech]: null,
    [AIVoicesMainTabs.SpeechTranslation]: null,
  });
  const [editorSelect, setEditorSelect] = useState<string>(editorSelectOptions[0].value);
  const [editorContent, setEditorContent] = useState([
    EditorState.moveFocusToEnd(EditorState.createWithContent(ContentState.createFromText(""))),
  ]);

  const [lastSel, setLastSel] = useState(SelectionState.createEmpty(""));
  const [styleMap, setStyleMap] = useState<any>({
    pause: {
      backgroundColor: "#e859ff",
      borderRadius: "20px",
      width: "20px",
      color: "#fff",
      userModify: "read-only",
      padding: "2px 5px",
      fontSize: "11px",
      position: "relative",
      top: "-2px",
    },
    isActive_active: {
      borderRadius: "5px",
      border: "1px solid #009AF7",
    },
    isLoading_loading: {
      backgroundImage: "url('/images/editor/loading.gif')",
      backgroundRepeat: "no-repeat, no-repeat",
      paddingRight: "40px",
      backgroundSize: "23px 90%",
      backgroundPosition: "100% 2px, 0 2px",
    },
    isGenerated_generated: {
      borderRadius: "5px",
      border: "1px dashed #009AF7",
    },
  });

  const editorObj = editorContent.find((editor: any, index: number) => index + 1 === paragraphActive);

  const handleEditorSelectValue = (newValue: string) => setEditorSelect(newValue);

  const handleEditorContent = (index?: number) => (newEditorContent: any) => {
    setEditorContent(
      editorContent.map((content: any, order: number) => (order === index ? newEditorContent : content)),
    );
  };

  const handleProjectAudioChange = (tab: string, audio: IAudio | null) => {
    setProjectAudio({ ...projectAudio, [tab]: audio });
  };

  const getSelectedZone = () => {
    if (paragraphs.length === 0) {
      sentryErrors({
        errorType: SentryErrors.PROJECT_WITH_EMPTY_PARAGRAPHS,
        details: {
          projectId: `${project?.projectId}`,
        },
      });
      toast.error(SentryErrors.PROJECT_WITH_EMPTY_PARAGRAPHS.toast);
      navigate("/");
      return;
    }

    const activeKey = lastSel.getAnchorKey();

    if (editorSelect === Advanced) {
      if (paragraphActive && activeKey) {
        const state = editorContent[paragraphActive - 1];
        let content = convertToRaw(state?.getCurrentContent());

        const index = content.blocks.findIndex((block) => block.key === activeKey) / 2;
        const zone = paragraphs[paragraphActive - 1].data[index];
        if (!zone) return;
        return { ...zone, actorId: paragraphs[paragraphActive - 1].actorId };
      }
    } else {
      const zone = paragraphs[0].data[0];
      if (!zone) return;
      return { ...zone, actorId: paragraphs[0].actorId };
    }
  };

  const selectedZone = getSelectedZone();

  const setZonesStatus = (state: EditorState, order: number) => {
    // return state;
    let content = convertToRaw(state.getCurrentContent());
    const allZones = getAllZones(paragraphs[order] ? [paragraphs[order]] : []);
    content.blocks = content.blocks.map((block, blockIndex) => {
      if (blockIndex % 2 === 1) return block;

      let zone = allZones[blockIndex / 2];
      let statusStyles: RawDraftInlineStyleRange | null = null;

      if (zone) {
        zone.features = zone.features.filter((feature) => feature.key !== "isLoading");
        block.inlineStyleRanges = block.inlineStyleRanges.filter(
          (inline) => inline.style !== ("isLoading_loading" as DraftInlineStyleType),
        );
      }
      if (zone && checkIfZoneCached(zone, loadingZonesAudio))
        statusStyles = { offset: 0, length: block.text.length, style: "isLoading_loading" as DraftInlineStyleType };

      if (zone && checkIfZoneCached(zone, cachedZonesAudio)) {
        statusStyles = { offset: 0, length: block.text.length, style: "isGenerated_generated" as DraftInlineStyleType };
      }
      if (statusStyles) block.inlineStyleRanges.push(statusStyles);

      return block;
    });

    const newState = EditorState.forceSelection(
      EditorState.createWithContent(convertFromRaw(content)),
      state.getSelection(),
    );

    return newState;
  };

  useEffect(() => {
    if (isRouteHydrated && !isProjectRestored.current && project && route) {
      const newEditorContent = project?.paragraphs?.map((paragraph) => dataToState(paragraph.data));
      if (newEditorContent.length) {
        let firstEditor = newEditorContent[0];
        firstEditor = EditorState.moveFocusToEnd(firstEditor);
        newEditorContent[0] = EditorState.forceSelection(
          EditorState.createWithContent(
            convertFromRaw(
              setInlineStyle(convertToRaw(firstEditor.getCurrentContent()), firstEditor.getSelection().getAnchorKey(), {
                isActive: "active",
              }),
            ),
          ),
          firstEditor.getSelection(),
        );
        setLastSel(firstEditor.getSelection());
      }

      setEditorSelect(!project.isSimple ? Advanced : Simple);
      setEditorContent(newEditorContent);
      setParagraphs(
        project?.paragraphs.map((paragraph, index) => {
          paragraph.order = index + 1;
          return paragraph;
        }),
      );

      isProjectRestored.current = true;
      setIsProjectHydrated(true);
    }
  }, [project, route, isRouteHydrated]);

  useEffect(() => {
    for (let i = 0; i < editorContent.length; i++) {
      const content = convertToRaw(editorContent[i].getCurrentContent());
      content.blocks.forEach((block) => {
        const element = document.querySelector(`span[data-offset-key="${block.key}-0-0"]`) as HTMLElement;
        let paddings: number[] = [];
        let bgSizes: string[] = [];

        const url = block.inlineStyleRanges
          .reduce((acc: any, elem: any) => {
            if (styleMap[elem.style] && styleMap[elem.style]?.backgroundImage) {
              const img = new Image();
              img.src = styleMap[elem.style].backgroundImage.split("'")[1];
              img.width *= 28 / img.height;

              acc.push(styleMap[elem.style].backgroundImage);
              paddings.push(parseInt(styleMap[elem.style].paddingRight.replace("px", "")));
              bgSizes.push(styleMap[elem.style].backgroundSize);
            }

            return acc;
          }, [])
          .join(",");

        if (element && paddings.length) {
          block.inlineStyleRanges.forEach((inline) => {
            if (styleMap[inline.style]) {
              Object.entries(styleMap[inline.style]).forEach(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (style) => (element.style[style[0]] = style[1]),
              );
            }
          });
          element.style.backgroundImage = url;
          element.style.paddingRight = `${paddings.reduce((acc, pd) => (acc += pd), 0)}px`;
          element.style.backgroundPosition = paddings
            .map((pd, index, inital) => `calc(100% - ${sum(inital.slice(0, index))}px) 2px`, 0)
            .join(",");
          element.style.backgroundSize = bgSizes.join(",");
        }
      });
    }
  }, [editorContent]);

  useEffect(() => {
    let generatedStyledMap = {};

    const styles = sidebarBoxes.reduce((acc, box, index) => {
      box.values.forEach((value) => {
        acc[`${box.title}_${value.text}`] = generateEditorStyle(value.text, box.background);
      });

      return acc;
    }, {} as any);

    generatedStyledMap = {
      ...styleMap,
      ...styles,
    };

    setStyleMap(generatedStyledMap);
  }, []);

  useEffect(() => {
    setEditorContent((prev) => prev.map((state, index) => setZonesStatus(state, index)));
  }, [cachedZonesAudio, loadingZonesAudio]);

  useEffect(() => {
    if (
      projectAudio[AIVoicesMainTabs.SpeechTranslation] &&
      projectAudio[AIVoicesMainTabs.SpeechTranslation]?.file &&
      !isSpeechTranslationProjectLoading
    ) {
      const formData = new FormData();
      formData.append("file", projectAudio[AIVoicesMainTabs.SpeechTranslation].file);

      dispatch(audioToTranslatedTextServer({ data: formData }));
    }
  }, [projectAudio[AIVoicesMainTabs.SpeechTranslation]]);

  return {
    lastSel,
    styleMap,
    editorObj,
    projectAudio,
    selectedZone,
    editorSelect,
    editorContent,
    setLastSel,
    setEditorContent,
    handleEditorContent,
    handleEditorSelectValue,
    handleProjectAudioChange,
  };
};

interface UseAIVoicesPopupProps {
  actorId?: number;
  activeTab: string;
  activeActor?: IActor;
  speechCategory: number;
  paragraphs: Paragraphs[];
  paragraphActor?: IActor;
  paragraphActorsList?: IActor[];
  createProject: () => void;
  setActorActive: (actors: IActor[]) => void;
  handleActiveActor: (actors: IActor[] | IActor) => void;
}

export const useAIVoicesPopup = ({
  actorId,
  activeTab,
  activeActor,
  speechCategory,
  paragraphs,
  paragraphActor,
  paragraphActorsList,
  createProject,
  setActorActive,
  handleActiveActor,
}: UseAIVoicesPopupProps) => {
  const dispatch = useDispatch();
  const actorsList = useSelector(getActorsList);

  const { Text2Speech } = AIVoicesMainTabs;

  const actor = actorsList?.find((actor) => actor.actorId === actorId);

  const handleOpenPopup = () => {
    let prefilled = {};

    if (activeTab === Text2Speech) {
      prefilled = {
        actors: actorsList,
        active: actorId,
        paragraphs,
        currentParagraphActor: actor || paragraphActor,
        currentParagraphActorsList: paragraphActorsList || [],
        speechCategory,
        setActorActive,
      };
    } else {
      prefilled = {
        actors: actorsList,
        active: activeActor?.actorId || actorsList[0]?.actorId,
        paragraphs: [],
        currentParagraphActor: activeActor || actorsList[0],
        speechCategory,
        isSingleActorSelectionOnly: true,
        isNoFilters: true,
        setActorActive: handleActiveActor,
      };
    }

    dispatch(
      updatePopup({
        popup: Popups.characterPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const signUpPopupOpen = (projectId?: number) => {
    const isText2Speech = activeTab === Text2Speech;

    let prefilled: SignUpPopupTypes = {
      type: activeTab,
      link: {
        projectName: isText2Speech ? "actors" : `actors?tab=${activeTab}`,
        projectId,
      },
    };

    if (isText2Speech) {
      prefilled = {
        ...prefilled,
        projectSave: createProject,
      };
    }

    dispatch(
      updatePopup({
        popup: Popups.signUpPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleCloseEstimatedPopup = () => {
    dispatch(updatePopup({ popup: Popups.estimatedPopup, status: false }));
  };

  return {
    signUpPopupOpen,
    handleOpenPopup,
    handleCloseEstimatedPopup,
  };
};

interface Route {
  projectId: string;
  audioProjectId: string;
  actor: string;
  customActor: string;
  customActorId: string;
  tab: string;
}

export const useAIVoice = (handleActiveMainTab: (tab: string) => void) => {
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const actorsList = useSelector(getActorsList);

  const isProjectRestored = useRef(false);

  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [route, setRoute] = useState<Route>({
    projectId: "",
    audioProjectId: "",
    actor: "",
    customActor: "",
    customActorId: "",
    tab: "",
  });

  const [isRouteHydrated, setIsRouteHydrated] = useState(false);
  const [isProjectHydrated, setIsProjectHydrated] = useState(false);

  const [paragraphs, setParagraphs] = useState<Paragraphs[]>(initialParagraphsData);
  const [paragraphActive, setParagraphActive] = useState<number>();
  const [featureActive, setFeatureActive] = useState<number>();

  const handleParagraphActive = (id: number) => setParagraphActive(id);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  useEffect(() => {
    setRoute({
      projectId: searchParams.get("projectId") || "",
      audioProjectId: searchParams.get("audioProjectId") || "",
      actor: searchParams.get("actor") || "",
      customActor: searchParams.get("actor") || "",
      customActorId: searchParams.get("actorId") || "",
      tab: searchParams.get("tab") || "",
    });
    setIsRouteHydrated(true);
  }, [searchParams]);

  useEffect(() => {
    dispatch(clearCurrentProject());

    if (route.projectId) {
      dispatch(updateProjectLoading({ module: ProjectModules.projectList, isLoading: true }));
      dispatch(getProjectServer(parseInt(route.projectId)));
    }
  }, [route.projectId]);

  useEffect(() => {
    if (!route.projectId && !route.audioProjectId && !project && route.tab) {
      handleActiveMainTab(route.tab);
    }
  }, [route.tab]);

  useEffect(() => {
    dispatch(clearCurrentSpeech2SpeechProject());
    dispatch(clearCurrentSpeechTranslationProject());

    if (route.audioProjectId) {
      dispatch(getAudioTranslateProjectByIdServer(parseInt(route.audioProjectId)));
    }
  }, [route.audioProjectId]);

  useEffect(() => {
    setParagraphActive(1);
  }, [project]);

  useEffect(() => {
    if (
      isRouteHydrated &&
      !isProjectRestored.current &&
      !project &&
      !route.projectId &&
      actorsList &&
      actorsList.length &&
      !route.audioProjectId
    ) {
      setParagraphs(
        paragraphs.map((paragraph) => {
          if (paragraph.order === 1) {
            const newActor = actorsList.find((actor) => actor.name === route.actor) || actorsList?.[0];
            return { ...paragraph, actor: newActor, actorsList: [newActor], actorId: newActor?.actorId };
          } else {
            return paragraph;
          }
        }),
      );
      setIsProjectHydrated(true);
      isProjectRestored.current = true;
    }
  }, [project, route.projectId, actorsList, isRouteHydrated]);

  return {
    route,
    search,
    paragraphs,
    featureActive,
    paragraphActive,
    isRouteHydrated,
    isProjectRestored,
    setParagraphs,
    setFeatureActive,
    handleSearchChange,
    setIsProjectHydrated,
    handleParagraphActive,
  };
};
