export const ImageIcon2424 = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10C8.828 10 9.5 9.328 9.5 8.5C9.5 7.672 8.828 7 8 7C7.172 7 6.5 7.672 6.5 8.5C6.5 9.328 7.172 10 8 10ZM17.9995 18.9999H6.56055L13.5655 13.1549C13.8115 12.9459 14.2575 12.9469 14.4985 13.1539L18.9995 16.9939V17.9999C18.9995 18.5519 18.5515 18.9999 17.9995 18.9999ZM6 5H18C18.552 5 19 5.448 19 6V14.364L15.797 11.632C14.807 10.79 13.258 10.79 12.277 11.626L5 17.698V6C5 5.448 5.448 5 6 5ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"
      fill="#191B1F"
    />
  </svg>
);

export const ImageIcon2424Landing = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 3H7C4.243 3 2 5.243 2 8V16C2 18.757 4.243 21 7 21H17C19.757 21 22 18.757 22 16V8C22 5.243 19.757 3 17 3ZM7 5H17C18.654 5 20 6.346 20 8V12.5859L16.417 9.00293C15.636 8.22193 14.364 8.22193 13.583 9.00293L9 13.5859L8.41701 13.0029C7.63601 12.2219 6.36399 12.2219 5.58299 13.0029L4 14.5859V8C4 6.346 5.346 5 7 5ZM6.5 9C6.5 8.172 7.172 7.5 8 7.5C8.828 7.5 9.5 8.172 9.5 9C9.5 9.828 8.828 10.5 8 10.5C7.172 10.5 6.5 9.828 6.5 9Z"
      fill="#41416E"
    />
  </svg>
);
