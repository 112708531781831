import { Link } from "react-router-dom";

import SmartLink from "../components/SmartLink/SmartLink";
import { IExternalLinks } from "../lib/routeUtils";

export enum ActiveTabs {
  cancellation = "cancellation",
  products = "products",
}

export const faqMocks = {
  [ActiveTabs.cancellation]: [
    {
      id: 1,
      title: "Do you have a free Plan?",
      details: (
        <>
          Yes, we do have a <a href="#CompareAllPlans">Free plan</a>
        </>
      ),
    },
    {
      id: 2,
      title: "Can I cancel my plan anytime?",
      details:
        "Yes, you can cancel your plan anytime. However, yearly plans are paid upfront and no     refund will be issued for the unused months. The subscription will remain active until the end of the subscription period.",
    },
    {
      id: 3,
      title: "How can I cancel my subscription?",
      details: [
        <>
          You can cancel your plan anytime either through the &quot;My Plan&quot; section inside your Synthesys account
          or by contacting our support team at <a href="mailto:support@synthesys.io">support@synthesys.io.</a>
        </>,
        "However, all plans are paid upfront and no refund will be issued for the unused duration of your subscription period. The subscription will remain active until the end of the subscription period.",
      ],
    },
  ],
  [ActiveTabs.products]: [
    {
      id: 7,
      title: "Will this work on Mac and PC?",
      details:
        "Yes! Synthesys Studio is 100% cloud based and works on any operating platform on any internet connected device. It runs inside your internet browser so you can access it on any computer with an Internet connection. Nothing to install; nothing to update.",
    },
    {
      id: 8,
      title: "What languages are supported?",
      details: (
        <>
          We are now able to support 140 languages with over 300 different voices plus 40 different ultra realistic
          voices. You can find the <SmartLink url={IExternalLinks.supportedLanguages}>list</SmartLink>.
        </>
      ),
    },
    {
      id: 9,
      title: "Do you provide Full HD 1080p video rendering?",
      details: "Yes, all paid plans have 1080p video rendering.",
    },
    {
      id: 10,
      title: "How do I reach the Synthesys Team?",
      details:
        "We will be there every step of the way to ensure you are happy using Synthesys AI Studio. Send us an email to our support desk and we will get back to you ASAP! support@synthesys.io",
    },
  ],
};
