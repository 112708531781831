import styled, { css } from "styled-components";
import { FC } from "react";

import Accordion from "../../../components/Accordion/Accordion";
import AccordionSummary from "../../../components/Accordion/AccordionSummary";
import AccordionDetails from "../../../components/Accordion/AccordionDetails";
import SectionSubTitle from "../../../components/TextComponents/SectionSubTitle";
import { ActiveTabs, faqMocks } from "../../../mocks/faqMocks3";

interface FaqRightProps {
  activeTab: ActiveTabs;
}
const entries = Object.entries(faqMocks);

const FaqRight: FC<FaqRightProps> = ({ activeTab }) => {
  return (
    <Wrapper>
      {entries.map(([key, value]) =>
        value.map((elem) => (
          <AccordionWrapper key={elem.id} isHidden={key !== activeTab}>
            <Accordion>
              <AccordionSummary>
                <SummaryText>{elem.title}</SummaryText>
              </AccordionSummary>
              <AccordionDetails>
                <SectionSubTitle subtitle={elem.details} />
              </AccordionDetails>
            </Accordion>
          </AccordionWrapper>
        )),
      )}
    </Wrapper>
  );
};

const AccordionWrapper = styled.div<{ isHidden?: boolean }>`
  opacity: 1;
  animation: fadeIn 0.3s;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const SummaryText = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #191b1f;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  flex: 1;
`;

export default FaqRight;
