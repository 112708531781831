import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "./components/Tabs";
import * as Styles from "./index.styles";
import Alert from "../AIHumans/components/Alert";
import ChatPopup from "../Chat/components/ChatPopup";
import HistoryTab from "./components/tabs/HistoryTab";
import ChatTrigger from "../Chat/components/ChatTrigger";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import IconButton from "../../components/Button/IconButton";
import DashboardLayout from "../../layouts/DashboardLayout";
import Textfield from "../../components/Textfield/Textfield";
import Text2SpeechScreen from "./components/tabs/Text2Speech";
import Speech2SpeechScreen from "./components/tabs/Speech2Speech";
import EstimatedPopup from "../../components/Popups/EstimatedPopup";
import Button, { ButtonThemes } from "../../components/Button/Button";
import SpeechTranslationScreen from "./components/tabs/SpeechTranslation";
import ProjectAudioSettingsTab from "./components/tabs/ProjectAudioSettingsTab";
import ProfileActorSidebar from "../../components/ProfileActorSidebar/ProfileActorSidebar";
import {
  AiVoicesMainTabs,
  AIVoicesMainTabs,
  AIVoicesSidebarTabs,
  AiVoicesSidebarTabs,
  useAIVoice,
  useAIVoicesControlSidebar,
  useAIVoicesEditor,
  useAIVoicesPopup,
  useAIVoicesProject,
  useAIVoicesTab,
} from "../../hooks/useAIVoices";
import {
  getActorsList,
  getActorsListLoading,
  getSpeech2Speech,
  getSpeechTranslation,
} from "../../redux/reducers/actorReducer";
import { navActions } from "./index.styles";
import {
  getAutoSaveLoading,
  getProject,
  getProjectAudio,
  getProjectLoading,
} from "../../redux/reducers/projectReducer";
import { pages } from "../../lib/routeUtils";
import { sidebarBoxes } from "../../mocks/sidebarBoxes";
import { AudioTranslateProject } from "../../types/project";
import { getProfile } from "../../redux/reducers/profileReducer";
import { Status } from "../../redux/actions/humansProjectActions";
import { default as SidebarTabs } from "../AIImages/components/Tabs";
import { getProjectListServer } from "../../redux/actions/projectAction";
import { getEstimatedPopupIsOpen } from "../../redux/reducers/popupsReducer";
import { ArrowRight, BasePageLoader, PricingIcon, SearchFilterIcon, SearchIcon } from "../../components/Icons/Icons";
import { clearActors, clearVoice, getActorsServer, getVoiceoverHistoryServer } from "../../redux/actions/actorActions";

const speechCategory = {
  [AIVoicesMainTabs.Text2Speech]: 1,
  [AIVoicesMainTabs.Speech2Speech]: 2,
  [AIVoicesMainTabs.SpeechTranslation]: 3,
};

const ActorsPage = () => {
  const dispatch = useDispatch();
  const { Settings, History } = AIVoicesSidebarTabs;
  const {
    Text2Speech: Text2SpeechTab,
    Speech2Speech: Speech2SpeechTab,
    SpeechTranslation: SpeechTranslationTab,
  } = AIVoicesMainTabs;
  const { activeTab, activeSidebarTab, handleActiveMainTab, handleActiveSidebarTab } = useAIVoicesTab();

  const [disabledMainTabsLabels, setDisabledMainTabsLabels] = useState<AIVoicesMainTabs[]>([]);

  const project = useSelector(getProject);
  const projectLoading = useSelector(getProjectLoading);
  const actorsList = useSelector(getActorsList);
  const isLoading = useSelector(getActorsListLoading);
  const estimatedPopupIsOpen = useSelector(getEstimatedPopupIsOpen);
  const { cachedZonesAudio } = useSelector(getProjectAudio);
  const speech2SpeechProject = useSelector(getSpeech2Speech);
  const speechTranslationProject = useSelector(getSpeechTranslation);
  const speech2Speech = speech2SpeechProject.data;
  const speechTranslation = speechTranslationProject.data;
  const isProjectLoading = speech2SpeechProject.isLoading || speechTranslationProject.isLoading;

  const {
    route,
    search,
    paragraphs,
    featureActive,
    paragraphActive,
    isRouteHydrated,
    isProjectRestored,
    setParagraphs,
    setFeatureActive,
    handleSearchChange,
    setIsProjectHydrated,
    handleParagraphActive,
  } = useAIVoice(handleActiveMainTab);

  const actorId = paragraphs?.find((paragraph) => paragraph.order === paragraphActive)?.actorId;
  const actor = actorsList?.find((actor) => actor.actorId === actorId);
  const paragraphActor = paragraphs[(paragraphActive as number) - 1]?.actor;
  const paragraphActorsList = paragraphs[(paragraphActive as number) - 1]?.actorsList;

  const {
    lastSel,
    styleMap,
    editorObj,
    projectAudio,
    selectedZone,
    editorSelect,
    editorContent,
    setLastSel,
    setEditorContent,
    handleEditorContent,
    handleEditorSelectValue,
    handleProjectAudioChange,
  } = useAIVoicesEditor({
    route: route.projectId,
    paragraphs,
    isRouteHydrated,
    paragraphActive,
    isProjectRestored,
    setParagraphs,
    setIsProjectHydrated,
  });

  const {
    language,
    translatedText,
    rightSidebarOpen,
    activeActorForAudio,
    setActorActive,
    handleLanguageSelect,
    handleRightSidebarOpen,
    handleActorSidebarClick,
    handleActiveActorForAudio,
    handleTranslatedTextChange,
  } = useAIVoicesControlSidebar({ paragraphs, paragraphActive, setParagraphs });

  const { createProject, updateProject, generateAllAudios, handleAudioTranslateProjectCreate } = useAIVoicesProject({
    paragraphs,
    selectedZone,
    editorSelect,
  });

  const { signUpPopupOpen, handleOpenPopup, handleCloseEstimatedPopup } = useAIVoicesPopup({
    actorId,
    activeTab,
    activeActor: activeActorForAudio[activeTab],
    speechCategory: speechCategory[activeTab],
    paragraphs,
    paragraphActor,
    paragraphActorsList,
    createProject: () =>
      activeTab === Text2SpeechTab
        ? createProject()
        : handleAudioTranslateProjectCreate({
            file: projectAudio[activeTab]?.file,
            targetLanguage: activeTab === Speech2SpeechTab ? "" : language,
            conversionType: activeTab === Speech2SpeechTab ? 2 : 1,
            actor: activeActorForAudio[activeTab] || actorsList[0],
            actorID: activeActorForAudio[activeTab]?.actorId || actorsList[0]?.actorId,
            translatedText: activeTab === Speech2SpeechTab ? "" : translatedText,
          }),
    setActorActive,
    handleActiveActor: (actors) => handleActiveActorForAudio(activeTab, actors),
  });

  const autoSaveLoading = useSelector(getAutoSaveLoading);

  useEffect(() => {
    dispatch(clearActors());
    dispatch(clearVoice());
    dispatch(
      getActorsServer({
        keyword: route.customActor,
        pageNumber: 1,
        categoryType: [],
        voiceAge: [],
        isFeMale: null,
        mood: [],
        content: [],
        region: [],
        language: [],
        country: [],
        popular: true,
        SpeechCategory: speechCategory[activeTab],
      }),
    );
    dispatch(getProjectListServer({ keyword: "" }));
    const newActorId = parseInt(route.customActorId);
    setActorActive([
      {
        actorId: newActorId,
        name: route.customActor,
        photo: "/images/actors/user.jpg",
      },
    ]);
  }, [route.customActor]);

  useEffect(() => {
    dispatch(clearActors());
    dispatch(clearVoice());
    dispatch(
      getActorsServer({
        keyword: search,
        pageNumber: 1,
        categoryType: [],
        voiceAge: [],
        isFeMale: null,
        mood: [],
        content: [],
        region: [],
        language: [],
        country: [],
        popular: true,
        SpeechCategory: speechCategory[activeTab],
      }),
    );
    dispatch(getProjectListServer({ keyword: "" }));
  }, [search, activeTab]);

  useEffect(() => {
    dispatch(getVoiceoverHistoryServer(1, true));
  }, []);

  useEffect(() => {
    if (isRouteHydrated && !isProjectRestored.current && route.audioProjectId) {
      const hydrateProject = (
        projectType: "Speech2Speech" | "SpeechTranslation",
        projectData: AudioTranslateProject,
        mainTab: AIVoicesMainTabs,
      ) => {
        handleActiveMainTab(mainTab);
        setDisabledMainTabsLabels(Object.values(AIVoicesMainTabs).filter((item) => item !== mainTab));
        handleActiveActorForAudio(mainTab, projectData.actor);
        handleProjectAudioChange(mainTab, {
          name: projectData.title as string,
          src: projectData.audioSrc as string,
          file: projectData.file,
          duration: projectData.totalDuration as number,
          oldSrc: projectData.audioSrc as string,
        });

        if (projectType === "SpeechTranslation") {
          const e = { target: { value: projectData?.translatedText } };
          handleLanguageSelect(projectData?.targetLanguage);
          handleTranslatedTextChange(e);
        }

        isProjectRestored.current = true;
        setIsProjectHydrated(true);
      };

      if (speech2Speech) {
        hydrateProject("Speech2Speech", speech2Speech, Speech2SpeechTab);
      } else if (speechTranslation) {
        hydrateProject("SpeechTranslation", speechTranslation, SpeechTranslationTab);
      }
    }
  }, [speech2Speech, speechTranslation, route.audioProjectId, isRouteHydrated]);

  useEffect(() => {
    if (!projectAudio[SpeechTranslationTab] || !projectAudio[SpeechTranslationTab]?.src) {
      handleLanguageSelect("");
      handleTranslatedTextChange({ target: { value: "" } });
    }
  }, [projectAudio[SpeechTranslationTab]]);

  useEffect(() => {
    if (project && route.projectId && isRouteHydrated) {
      setDisabledMainTabsLabels(Object.values(AIVoicesMainTabs).filter((item) => item !== Text2SpeechTab));
    }
  }, [project, isRouteHydrated, route.projectId]);

  useEffect(() => {
    handleActiveSidebarTab(Settings);
  }, [activeTab]);

  const content = {
    [Text2SpeechTab]: (
      <Text2SpeechScreen
        {...{ createProject, updateProject }}
        {...{ signUpPopupOpen, handleOpenPopup, speechCategory: speechCategory[activeTab] }}
        {...{
          lastSel,
          styleMap,
          selectedZone,
          editorSelect,
          editorContent,
          setLastSel,
          setEditorContent,
          handleEditorSelectValue,
        }}
        {...{
          route: route.projectId,
          search,
          paragraphs,
          paragraphActive,
          setParagraphs,
          setFeatureActive,
          handleSearchChange,
          handleParagraphActive,
        }}
        {...{
          setActorActive,
          handleActorSidebarClick,
        }}
      />
    ),
    [Speech2SpeechTab]: (
      <Speech2SpeechScreen
        {...{ audioProjectRoute: route.audioProjectId }}
        {...{
          activeActor: activeActorForAudio[activeTab],
          handleActiveActor: (actors) => handleActiveActorForAudio(activeTab, actors),
        }}
        {...{
          projectAudio: projectAudio[activeTab],
          handleProjectAudioChange: (audio) => handleProjectAudioChange(activeTab, audio),
        }}
        {...{ signUpPopupOpen, speechCategory: speechCategory[activeTab] }}
        {...{
          createProject: () =>
            handleAudioTranslateProjectCreate({
              file: projectAudio[activeTab]?.file,
              conversionType: 2,
              actor: activeActorForAudio[activeTab] || actorsList[0],
              actorID: activeActorForAudio[activeTab]?.actorId || actorsList[0].actorId,
            }),
        }}
      />
    ),
    [SpeechTranslationTab]: (
      <SpeechTranslationScreen
        {...{ audioProjectRoute: route.audioProjectId }}
        {...{
          activeActor: activeActorForAudio[activeTab],
          handleActiveActor: (actors) => handleActiveActorForAudio(activeTab, actors),
        }}
        {...{
          projectAudio: projectAudio[activeTab],
          handleProjectAudioChange: (audio) => handleProjectAudioChange(activeTab, audio),
        }}
        {...{ signUpPopupOpen, speechCategory: speechCategory[activeTab] }}
        {...{ translatedText, targetLanguage: language }}
        {...{
          createProject: () =>
            handleAudioTranslateProjectCreate({
              file: projectAudio[activeTab]?.file,
              targetLanguage: language,
              translatedText,
              conversionType: 1,
              actor: activeActorForAudio[activeTab] || actorsList[0],
              actorID: activeActorForAudio[activeTab]?.actorId || actorsList[0].actorId,
            }),
        }}
      />
    ),
  };

  const currentProjectStatus =
    speech2Speech || speechTranslation ? (speech2Speech || speechTranslation)?.status : project?.status;
  const profile = useSelector(getProfile);

  const mainTabs = AiVoicesMainTabs.map((tab) =>
    disabledMainTabsLabels?.includes(tab.value) ? { ...tab, disabled: true } : { ...tab },
  );
  return (
    <>
      {currentProjectStatus === Status.isError && <Alert status={Status.isError} />}
      <Styles.Wrapper active={rightSidebarOpen}>
        <Styles.PageWrapper active={rightSidebarOpen}>
          <DashboardLayout
            withThemeSwitcher={false}
            navActions={navActions(
              search,
              activeTab,
              isLoading,
              project,
              autoSaveLoading,
              isProjectLoading,
              rightSidebarOpen,
              createProject,
              updateProject,
              handleOpenPopup,
              handleActiveMainTab,
              handleSearchChange,
              disabledMainTabsLabels,
            )}
          >
            <Styles.Content>
              <Styles.MobileOnly>
                <Styles.MobileCol>
                  <Styles.MobileRow>
                    <Link to="/my-projects?filter=actors" style={{ marginRight: "30px", marginTop: "10px" }}>
                      <Button
                        buttonTheme={ButtonThemes.Secondary}
                        icon={<img src="/images/arrow-left.svg" />}
                        text="Back"
                        style={{
                          position: "relative",
                          zIndex: "100",
                        }}
                      />
                    </Link>
                    <Styles.TabsWrapper rightSidebarOpen={rightSidebarOpen}>
                      <Tabs
                        data={mainTabs}
                        active={activeTab}
                        handleActive={handleActiveMainTab}
                        disabled={isLoading || isProjectLoading}
                      />
                    </Styles.TabsWrapper>
                    <Styles.MobileOnly>
                      {!profile.hidePricing && (
                        <Link to={pages.pricingSubscriptions()}>
                          <Styles.PricingButtonWrapper>
                            <Button buttonTheme={ButtonThemes.Transparent}>
                              <PricingIcon />
                              <span>Pricing</span>
                            </Button>
                          </Styles.PricingButtonWrapper>
                        </Link>
                      )}
                    </Styles.MobileOnly>
                  </Styles.MobileRow>
                  <Styles.MobileRow>
                    <Styles.SearchBox>
                      <Textfield
                        value={search}
                        onChange={handleSearchChange}
                        placeholder="Search voice actors"
                        startAdornment={<SearchIcon />}
                        endAdornment={<SearchFilterIcon />}
                        endAdornmentClick={handleOpenPopup}
                      />
                    </Styles.SearchBox>
                  </Styles.MobileRow>
                </Styles.MobileCol>
              </Styles.MobileOnly>
              {content[activeTab]}
            </Styles.Content>
          </DashboardLayout>
          <Styles.DesktopOnly>
            <Styles.IconButtonWrapper active={rightSidebarOpen}>
              <IconButton icon={<ArrowRight />} onClick={handleRightSidebarOpen} />
            </Styles.IconButtonWrapper>
          </Styles.DesktopOnly>
        </Styles.PageWrapper>
        <Styles.SidebarOpener active={rightSidebarOpen}>
          <Styles.Sidebar>
            <Styles.SidebarTabsWrapper>
              <SidebarTabs
                data={AiVoicesSidebarTabs}
                active={activeSidebarTab}
                handleActive={handleActiveSidebarTab}
                disabled={isLoading}
              />
            </Styles.SidebarTabsWrapper>
            <Styles.SidebarContent>
              {activeSidebarTab === Settings ? (
                activeTab === Text2SpeechTab ? (
                  <ProfileActorSidebar
                    featureActive={featureActive}
                    editorContent={editorObj}
                    setEditorContent={handleEditorContent(((paragraphActive as number) || 1) - 1)}
                    active={true}
                    actor={actor}
                    sidebarBoxes={sidebarBoxes}
                    paragraphActive={paragraphActive}
                    paragraphs={paragraphs}
                    setParagraphs={setParagraphs}
                    lastSel={lastSel}
                    paragraphActor={paragraphActor}
                    selectedZone={selectedZone}
                    editorSelect={editorSelect}
                  />
                ) : (
                  <ProjectAudioSettingsTab
                    activeTab={activeTab}
                    audioProjectRoute={route.audioProjectId}
                    actor={activeActorForAudio[activeTab] || actorsList[0]}
                    language={language}
                    translatedText={translatedText}
                    projectAudio={projectAudio[activeTab]}
                    handleLanguageSelect={handleLanguageSelect}
                    handleTranslatedTextChange={handleTranslatedTextChange}
                  />
                )
              ) : (
                activeSidebarTab === History && <HistoryTab />
              )}
            </Styles.SidebarContent>
          </Styles.Sidebar>
        </Styles.SidebarOpener>
        <Styles.MobileOnly>
          <Styles.IconButtonWrapper active={rightSidebarOpen}>
            <IconButton icon={<ArrowRight />} onClick={handleRightSidebarOpen} />
          </Styles.IconButtonWrapper>
        </Styles.MobileOnly>
        {/* <Autosave
          isProjectHydrated={isProjectHydrated}
          data={autosaveData}
          onProjectCreate={handleProjectCreate}
          onProjectUpdate={handleProjectUpdate}
        /> */}
      </Styles.Wrapper>
      {estimatedPopupIsOpen && (
        <EstimatedPopup
          paragraphs={paragraphs}
          cachedZonesAudio={cachedZonesAudio}
          open={estimatedPopupIsOpen}
          onClose={handleCloseEstimatedPopup}
          generateAudio={generateAllAudios}
        />
      )}
      <ChatTrigger />
      <ChatPopup />
      {projectLoading && (
        <Styles.CircularProgressWrapper>
          <BasePageLoader />
        </Styles.CircularProgressWrapper>
      )}
    </>
  );
};

export default withPrivateRoute(ActorsPage);
