import styled from "styled-components";

import PlansTableDesktop from "./PlansTableDesktop";
import PlansTableMobile from "./PlansTableMobile";
import PageSection from "../../../components/PageSection/PageSection";

const PlansSection = () => {
  return (
    <PageSection sectionId="CompareAllPlans">
      <TextWrapper>
        <Title>All Features & Services Unlocked—For Everyone</Title>
        <Subtitle>
          Empowering creativity without barriers—because the best ideas shouldn&lsquo;t have limits{" "}
          <span>
            <img src="/images/pricing/smile.webp" alt="rocket" />
          </span>
        </Subtitle>
      </TextWrapper>
      <PlansTableMobile />
      <PlansTableDesktop />
    </PageSection>
  );
};

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  @media (max-width: 767px) {
    gap: 10px;
  }
`;

const Title = styled.h2`
  color: rgba(25, 27, 31, 0.73);

  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: -0.04em;
  text-align: center;

  @media (max-width: 999px) {
    font-size: 22px;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 501px) {
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  @media (max-width: 420px) {
    font-size: 16px;
    text-align: left;
  }
`;

const Subtitle = styled.p`
  color: rgba(25, 27, 31, 0.73);

  font-size: 20px;
  font-weight: 300;
  line-height: 1.25;
  text-align: center;

  span {
    margin-left: 4px;

    img {
      display: inline-block;
      width: 20px;
      height: auto;
    }
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export default PlansSection;
