import { ReactNode } from "react";
import styled from "styled-components";

import Navigation from "../components/Navigation/Navigation";

interface Props {
  children: ReactNode;
  navActions?: ReactNode;
  tabActions?: ReactNode;
  startAdornment?: ReactNode;
  alert?: ReactNode;
  childrenClassName?: string;
  withThemeSwitcher?: boolean;
  hasUnlimitedOnService?: boolean;
  hasCredits?: number;
}

const DashboardLayout = ({
  children,
  alert,
  navActions,
  tabActions,
  startAdornment,
  childrenClassName,
  withThemeSwitcher = true,
  hasUnlimitedOnService = false,
  hasCredits,
}: Props) => (
  <Wrapper startAdornment>
    {alert}
    <Navigation
      startAdornment={startAdornment}
      tabActions={tabActions}
      withThemeSwitcher={withThemeSwitcher}
      hasUnlimitedOnService={hasUnlimitedOnService}
      hasCredits={hasCredits}
    >
      {navActions}
    </Navigation>
    <Content className={childrenClassName}>{children}</Content>
  </Wrapper>
);

const Wrapper = styled("div")<{ startAdornment?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px 40px 32px;
  row-gap: 32px;
  flex: 1;
  overflow: hidden;
  position: relative;

  ${({ startAdornment }) =>
    startAdornment &&
    `
      row-gap: 24px;
      height: 100%;
      padding: 24px 15px 15px;
    `}

  .ai-images {
    height: calc(100% - 200px);
    overflow: visible;
  }

  @media (max-width: 1000px) {
    overflow: hidden auto;

    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${({ theme }) => theme.activeMenu};
    }
    ::-webkit-scrollbar-track {
      margin: 15px 0;
    }
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  @media (max-width: 1000px) {
    overflow: visible;
  }
`;

export default DashboardLayout;
