import styled from "styled-components";
import { useState } from "react";

import FaqLeft from "./FaqLeft";
import FaqRight from "./FaqRight";
import PageSection from "../../../components/PageSection/PageSection";
import { ActiveTabs } from "../../../mocks/faqMocks3";

const FAQsection = () => {
  const [activeTab, setActiveTab] = useState<ActiveTabs>(ActiveTabs.cancellation);

  return (
    <PageSection title="FAQ">
      <Content>
        <FaqLeft activeTab={activeTab} setActiveTab={setActiveTab} />
        <FaqRight activeTab={activeTab} />
      </Content>
    </PageSection>
  );
};

const Content = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export default FAQsection;
