import styled from "styled-components";
import { useContext } from "react";

import { PricingContext } from "./PricingProvider";

const Toggle = () => {
  const { toggleYearly, isYearly } = useContext(PricingContext)!;

  return (
    <Wrapper onClick={toggleYearly}>
      <WhiteBackground isYearly={isYearly} />
      <Button>
        <Period white={!isYearly}>Yearly</Period>
        <Save>Save&nbsp;30%</Save>
      </Button>
      <Button>
        <Period white={isYearly}>Monthly</Period>
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: 72px;
  border: 1px solid #4782b3;
  cursor: pointer;

  @media (max-width: 420px) {
    width: 100%;
  }
`;

const Button = styled.button`
  display: flex;
  width: 139px;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 52px;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  @media (max-width: 420px) {
    width: calc(100% / 2);
  }
`;

const WhiteBackground = styled.span<{ isYearly: boolean }>`
  background-color: #ffffff;
  border-radius: 52px;
  width: 139px;
  height: 36px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: transform 0.3s;
  transform: ${({ isYearly }) => (isYearly ? "translateX(0%)" : "translateX(100%)")};

  @media (max-width: 420px) {
    width: calc((100% - 8px) / 2);
  }
`;

const Period = styled.span<{ white: boolean }>`
  z-index: 1;
  color: ${({ white }) => (white ? "#ffffff" : "#1c8cd8")};
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: -0.41px;
  transition: color 0.3s;
`;

const Save = styled.span`
  z-index: 1;
  padding: 6px;
  border-radius: 60px;

  background: #af52de;
  color: #ffffff;

  text-align: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.2;
`;

export default Toggle;
