import styled from "styled-components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";

import CircularProgress from "../../components/Icons/CircularProgress";
import { pages } from "../../lib/routeUtils";
import { registerGuestUserServer } from "../../redux/actions/authActions";
import { getIsAuthorized, getIsHydrated, registerGuestUserLoading } from "../../redux/reducers/authReducer";

enum Pages {
  aiVoices = "ai-voices",
  aiHumans = "ai-humans",
  aiImages = "ai-images",
  videoTranslation = "video-translation",
  faceSwap = "faceswap",
  talkingAvatar = "talkingAvatar",
}

const FreeTrial = () => {
  const isAuthorized = useSelector(getIsAuthorized);
  const hydrated = useSelector(getIsHydrated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(registerGuestUserLoading);

  const { page } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const pageNavigation = {
    [Pages.aiVoices]: "actors",
    [Pages.aiHumans]: "ai-humans",
    [Pages.aiImages]: "ai-images",
    [Pages.videoTranslation]: "video-translation",
    [Pages.faceSwap]: "faceswap",
    [Pages.talkingAvatar]: "talkingAvatar",
  };

  useEffect(() => {
    let params: Record<string, any> = [];

    searchParams.forEach((value, key) => {
      params.push({ [key]: value });
    });

    const otherParamsParsed = params.reduce((acc: Record<string, any>, item: Record<string, any>) => {
      return { ...acc, ...item };
    }, {});

    if (!hydrated) return;
    if (isAuthorized) {
      if (page && pageNavigation[page as Pages]) {
        navigate(pages.main() + pageNavigation[page as Pages] + "?" + createSearchParams(otherParamsParsed).toString());
      } else {
        navigate(pages.main() + "?" + createSearchParams(otherParamsParsed).toString());
      }
    } else {
      dispatch(registerGuestUserServer({ page: pageNavigation[page as Pages], otherParams: params }));
    }
  }, [hydrated]);

  if (isAuthorized) return null;

  return <Wrapper>{isLoading ? <CircularProgress color="#009af7" /> : <div />}</Wrapper>;
};

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default FreeTrial;
