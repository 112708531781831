import styled, { css } from "styled-components";
import { ReactNode, useContext } from "react";

import SelectPlanButton from "./SelectPlanButton";
import SmartTooltip from "../../../components/Tooltip/SmartTooltip";
import {
  ArrowDown1616,
  CheckGreenIcon,
  CrossedMark,
  InfoIcon1818Grey,
  InfoIcon1818White,
  PercentLimited,
} from "../../../components/Icons/Icons";
import { PricingContext } from "./PricingProvider";

type PlanPrices = {
  oldPrice: string;
  newPrice: string;
  trigger: string;
};

export type PlanTypes = "FREE" | "PERSONAL" | "CREATOR" | "BUSINESS UNLIMITED" | "LIMITED";

export interface PlanCardProps {
  plan: PlanTypes;
  isLimited?: boolean;
  icon: ReactNode;
  greatFor: string;
  yearly: PlanPrices;
  monthly: PlanPrices;
  yearlyBilled: string;
  list: {
    text: ReactNode;
    important?: boolean;
    tooltip: ReactNode;
  }[];
}

const PlanCard = ({ plan, isLimited, icon, greatFor, yearly, monthly, yearlyBilled, list }: PlanCardProps) => {
  const { isYearly, toggleYearly } = useContext(PricingContext);

  const business = plan === "BUSINESS UNLIMITED";
  const initialHref = isYearly ? yearly.trigger : monthly.trigger;

  return (
    <Wrapper>
      {business && (
        <BusinessWrapper>
          <Prefer>
            <img src="/images/pricing/prefer.png" alt="people who prefer" width={78} height={24} />
            <span>72% of professionals prefer it</span>
          </Prefer>
        </BusinessWrapper>
      )}
      {isLimited && (
        <LimitedLable business={business}>
          <PercentLimited />
          <span>Limited Time 50% OFF </span>
        </LimitedLable>
      )}
      <CardWrapper isBusiness={business}>
        <TopInfo>
          <TopWrapper>
            <Heading>
              <Title isBusiness={business}>
                {plan} <span>{icon}</span>
              </Title>
              <SubTitle isBusiness={business}>{greatFor}</SubTitle>
            </Heading>
            <PriceWrapper>
              <MonthlyPrice isBusiness={business}>
                {isYearly
                  ? yearly.oldPrice && (
                      <span className="oldPrice">
                        ${yearly.oldPrice}
                        <span className="crossedMark">
                          <CrossedMark />
                        </span>
                      </span>
                    )
                  : monthly.oldPrice && (
                      <span className="oldPrice">
                        ${monthly.oldPrice}
                        <span className="crossedMark">
                          <CrossedMark />
                        </span>
                      </span>
                    )}
                <span className="newPrice">
                  ${isYearly ? yearly.newPrice : monthly.newPrice}
                  {plan !== "FREE" && <span className="month">/m</span>}
                </span>
              </MonthlyPrice>
              {isYearly ? (
                <YearlyPrice yearlyBilled={yearlyBilled} isBusiness={business}>
                  ${yearlyBilled} Billed Yearly <span onClick={toggleYearly}>Switch to Monthly</span>
                </YearlyPrice>
              ) : (
                <YearlyPrice yearlyBilled={yearlyBilled} isBusiness={business}>
                  Save 30% with <span onClick={toggleYearly}>Yearly plan</span>
                </YearlyPrice>
              )}
            </PriceWrapper>
          </TopWrapper>
          <SelectPlanButton
            planType={plan}
            popupHash={initialHref}
            buttonColor={business ? "pricingBlue" : "pricingBlack"}
            buttonText="Start Now"
          />
        </TopInfo>
        <Separator isBusiness={business} />
        <BottomInfo>
          <ProsWrapper>
            {list.map(({ text, tooltip, important }, idx) => (
              <Pros isBusiness={business} isImportant={important} key={idx}>
                <CheckGreenIcon />
                <ProsText isBusiness={business}>{text}</ProsText>
                {tooltip && (
                  <SmartTooltip title={tooltip} icon={business ? <InfoIcon1818Grey /> : <InfoIcon1818White />} />
                )}
              </Pros>
            ))}
          </ProsWrapper>
          <GoToFeatures isBusiness={business}>
            <Separator isBusiness={business} />
            <a href="#CompareAllPlans">
              See all our standard features <ArrowDown1616 />
            </a>
          </GoToFeatures>
        </BottomInfo>
      </CardWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: calc((100% - 84px) / 3);
  min-width: 333px;
  position: relative;
  padding-top: 56px;

  @media screen and (max-width: 440px) {
    min-width: 300px;
  }
`;

const LimitedLable = styled.div<{ business: boolean }>`
  position: absolute;
  top: ${({ business }) => (business ? "-20px" : "36px")};
  left: 50%;
  transform: translateX(-50%);

  width: max-content;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 80px;
  background: #32ac25;

  z-index: 1;

  span {
    color: #ffffff;

    font-size: 13px;
    font-weight: 600;
    line-height: 1.4;
  }
`;

const BusinessWrapper = styled.div`
  position: absolute;
  width: calc(100% + 16px);
  height: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 16px;
  padding-top: 16px;
  background: #0063b4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Prefer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  img {
    display: block;
    width: 60px;
    height: auto;
  }

  span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
  }

  @media screen and (max-width: 440px) {
    span {
      font-size: 12px;
    }
  }
`;

const CardWrapper = styled.div<{ isBusiness: boolean }>`
  width: 100%;
  height: ${({ isBusiness }) => (isBusiness ? "calc(100% - 8px)" : "100%")};
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 20px;
  border-radius: 16px;
  background: ${({ isBusiness }) => (isBusiness ? "#F0F0F3" : "#0063B4")};
  box-shadow: ${({ isBusiness }) => (isBusiness ? "unset" : "0px 4px 4px rgba(0, 0, 0, 0.25)")};
  position: relative;

  @media screen and (max-width: 440px) {
    padding: 16px;
  }
`;

const TopInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  z-index: 1;

  .primaryButton {
    width: 100%;
    height: 48px;

    .primaryButtonText {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: -0.41px;
    }
  }

  @media screen and (max-width: 440px) {
    gap: 20px;
  }
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Heading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 440px) {
    gap: 8px;
  }
`;

const Title = styled.p<{ isBusiness: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ isBusiness }) => (isBusiness ? "#0063B4" : "#ffffff")};

  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  text-transform: uppercase;

  span {
    width: 20px;
    height: 20px;
    position: relative;

    svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 440px) {
    font-weight: 700;
    letter-spacing: -0.96px;
  }
`;

const SubTitle = styled.p<{ isBusiness: boolean }>`
  width: ${({ isBusiness }) => (isBusiness ? "255px" : "100%")};
  color: ${({ isBusiness }) => (isBusiness ? "#6F7074" : "#ffffff")};

  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;

  @media screen and (max-width: 440px) {
    font-size: 12px;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MonthlyPrice = styled.div<{ isBusiness: boolean }>`
  display: flex;
  gap: 16px;
  align-items: baseline;

  .oldPrice {
    color: ${({ isBusiness }) => (isBusiness ? "#6F7074" : "#f3f4f9")};
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    position: relative;

    .crossedMark {
      position: absolute;
      top: 0;
      left: 5%;
      opacity: ${({ isBusiness }) => (isBusiness ? "0.4" : "0.5")};

      svg {
        fill: ${({ isBusiness }) => (isBusiness ? "#3b3d47" : "#ffffff")};
        width: 55px;
        height: auto;
      }
    }
  }

  .newPrice {
    color: ${({ isBusiness }) => (isBusiness ? "rgba(25, 27, 31, 0.73)" : "#ffffff")};
    font-size: 40px;
    font-weight: 600;
    line-height: 1.35;

    .month {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
    }
  }
`;

const YearlyPrice = styled.p<{ yearlyBilled: string; isBusiness: boolean }>`
  visibility: ${({ yearlyBilled }) => (yearlyBilled !== "" ? "visible" : "hidden")};
  color: ${({ isBusiness }) => (isBusiness ? "#3B3D47" : "#ffffff")};

  font-size: 12px;
  font-weight: 500;
  line-height: 2;

  span {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const Separator = styled.div<{ isBusiness: boolean }>`
  background: ${({ isBusiness }) => (isBusiness ? "#6F7074" : "#458ac4")};

  width: 100%;
  min-height: 1px;
  z-index: 1;
`;

const BottomInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  z-index: 1;
`;

const ProsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 440px) {
    gap: 12px;
  }
`;

const Pros = styled.li<{ isBusiness: boolean; isImportant?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  width: 100%;

  .tooltipWrapper {
    .tooltip {
      max-width: 190px;
    }

    svg {
      width: 17px;
      height: auto;
    }
  }

  ${({ isImportant, isBusiness }) =>
    isImportant &&
    css`
      ::before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 4px;
        background: ${isBusiness ? "#DDF0FF" : "rgba(255, 255, 255, 0.2)"};
      }
    `}

  @media screen and (max-width: 440px) {
    .tooltipWrapper {
      top: 2px;

      svg {
        width: 15px;
        height: auto;
      }
    }
  }
`;

const ProsText = styled.p<{ isBusiness: boolean }>`
  max-width: 85%;

  color: ${({ isBusiness }) => (isBusiness ? "rgba(25, 27, 31, 0.73)" : "#ffffff")};

  font-size: 13px;
  font-weight: 500;
  line-height: 1.69;
  position: relative;

  @media screen and (max-width: 440px) {
    font-size: 12px;
  }
`;

const GoToFeatures = styled.div<{ isBusiness: boolean }>`
  width: 100%;
  max-width: 256px;
  margin-top: auto;
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  a {
    display: flex;
    align-items: center;
    gap: 8px;

    color: ${({ isBusiness }) => (isBusiness ? "#191B1F" : "#ffffff")};

    font-size: 14px;
    font-weight: 600;
    line-height: 22px;

    svg {
      stroke: ${({ isBusiness }) => (isBusiness ? "#191b1f" : "#ffffff")};
    }
  }
`;

export default PlanCard;
