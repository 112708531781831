import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ReactNode, useContext, useEffect, useRef } from "react";

import EmptyHistory from "./EmptyHistory";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { IImage, PromptToVideoList } from "../../../types/image";
import {
  getCurrentPageHistoryImages,
  getCurrentPagePromptToVideoList,
  getHasMoreHistoryImages,
  getHasMorePromptToVideoList,
  getHistoryImages,
  getHistoryImagesLoading,
  getPromptToVideoList,
  getPromptToVideoListLoading,
} from "../../../redux/reducers/imagesReducer";
import {
  clearHistoryImages,
  clearPromptToVideoList,
  getAllHistoryImagesServer,
  getPromptToVideoListServer,
} from "../../../redux/actions/imagesActions";
import { AIImagesContext } from "../../../App";
import { AIImagesSidebarTabs } from "../../../hooks/useAiImages";
import { EnlargedPicture } from "../../../types/enlargedPicture";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { Text2Video } from "../../../types/text2Video";

const searchTimeoutDelay = 500;

const RecentSection = () => {
  const dispatch = useDispatch();

  const { activeTab } = useContext(AIImagesContext);

  const images = useSelector(getHistoryImages);
  const imagesLoading = useSelector(getHistoryImagesLoading);
  const imagesCurrentPage = useSelector(getCurrentPageHistoryImages);
  const imagesHasMore = useSelector(getHasMoreHistoryImages);

  const videos = useSelector(getPromptToVideoList);
  const videosLoading = useSelector(getPromptToVideoListLoading);
  const videosCurrentPage = useSelector(getCurrentPagePromptToVideoList);
  const videosHasMore = useSelector(getHasMorePromptToVideoList);

  const debounceHandleGetImages = useRef(
    debounce(async () => {
      dispatch(
        getAllHistoryImagesServer({
          keyword: "",
          pageNumber: 1,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  const debounceHandleGetVideos = useRef(
    debounce(async () => {
      dispatch(
        getPromptToVideoListServer({
          keyword: "",
          pageNumber: 1,
          pageSize: 16,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  const handleImageClick = (image: IImage) => {
    const prefilled: EnlargedPicture = {
      pic: image,
      isView: false,
    };
    dispatch(updatePopup({ popup: Popups.enlargedImagePopup, status: true, prefilled }));
  };

  const handleVideoClick = (video: PromptToVideoList) => {
    const prefilled: Text2Video = {
      id: video.prompt2VideoProjectId,
      prompt: video.prompt,
      size: video.size,
      outputUrl: video.outputUrl,
      fileName: video.title,
    };
    dispatch(updatePopup({ popup: Popups.text2VideoPopup, status: true, prefilled }));
  };

  const fetchMore = () => {
    if (activeTab === AIImagesSidebarTabs.Text2Image) {
      dispatch(
        getAllHistoryImagesServer({
          keyword: "",
          pageNumber: imagesCurrentPage + 1,
        }),
      );
    } else {
      dispatch(
        getPromptToVideoListServer({
          keyword: "",
          pageNumber: videosCurrentPage + 1,
          pageSize: 16,
        }),
      );
    }
  };

  useEffect(() => {
    if (activeTab === AIImagesSidebarTabs.Text2Image) {
      dispatch(clearHistoryImages());
      debounceHandleGetImages();
    } else {
      dispatch(clearPromptToVideoList());
      debounceHandleGetVideos();
    }
  }, [activeTab]);

  const content: { [key: string]: ReactNode } = {
    [AIImagesSidebarTabs.Text2Image]: (
      <ImagesWrapper id="scrollableHistoryImagesDiv">
        {imagesLoading && !images?.length ? (
          <CircularProgressWrapper>
            <CircularProgress color="#009af7" />
          </CircularProgressWrapper>
        ) : !imagesLoading && !images?.length ? (
          <EmptyHistory type={AIImagesSidebarTabs.Text2Image} />
        ) : (
          <InfiniteScroll
            next={fetchMore}
            hasMore={imagesHasMore}
            loader={
              imagesLoading ? (
                <CircularProgressWrapper>
                  <CircularProgress color="#009af7" />
                </CircularProgressWrapper>
              ) : null
            }
            dataLength={images?.length}
            style={{ display: "contents" }}
            scrollableTarget="scrollableHistoryImagesDiv"
          >
            {images.map((image) => (
              <StyledImg key={image.visualHistoryID} onClick={() => handleImageClick(image)} src={image.path} />
            ))}
          </InfiniteScroll>
        )}
      </ImagesWrapper>
    ),
    [AIImagesSidebarTabs.Text2Video]: (
      <VideoWrapper id="scrollableHistoryVideosDiv">
        {videosLoading && !videos?.length ? (
          <CircularProgressWrapper>
            <CircularProgress color="#009af7" />
          </CircularProgressWrapper>
        ) : !videosLoading && !videos?.length ? (
          <EmptyHistory type={AIImagesSidebarTabs.Text2Video} />
        ) : (
          <InfiniteScroll
            next={fetchMore}
            hasMore={videosHasMore}
            loader={
              videosLoading ? (
                <CircularProgressWrapper>
                  <CircularProgress color="#009af7" />
                </CircularProgressWrapper>
              ) : null
            }
            dataLength={videos?.length}
            style={{ display: "contents" }}
            scrollableTarget="scrollableHistoryVideosDiv"
          >
            {videos.map((video) => (
              <StyledVideo
                key={video.prompt2VideoProjectId}
                onClick={() => handleVideoClick(video)}
                src={video.outputUrl}
              />
            ))}
          </InfiniteScroll>
        )}
      </VideoWrapper>
    ),
  };

  return (
    <>
      <TitleWrapper>
        <Title>History</Title>
      </TitleWrapper>
      <Wrapper>
        <ImagesContainer>{content[activeTab]}</ImagesContainer>
      </Wrapper>
    </>
  );
};

export default RecentSection;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 12px 0px;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 240px;
  overflow: hidden;
  height: 100%;
  box-shadow: -5px -5px 10px #ffffff, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1),
    inset 2px 2px 4px #ffffff;
  border-radius: 16px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f0f3;
  box-shadow: -5px -5px 10px #ffffff, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1),
    inset 2px 2px 4px #ffffff;
  border-radius: 16px;
  max-width: 480px;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 16px 0;
`;

const ImagesWrapper = styled.div`
  display: flex;
  overflow: auto;
  height: 100%;
  flex-wrap: wrap;
  padding-left: 16px;
  justify-content: flex-start;
  align-content: start;
  gap: 6px;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background: rgba(25, 27, 31, 0.06);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
    height: 10px;
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  overflow: auto;
  height: 100%;
  flex-wrap: wrap;
  padding: 0 16px;
  justify-content: flex-start;
  align-content: start;
  gap: 6px;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background: rgba(25, 27, 31, 0.06);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
    height: 10px;
  }
`;

const StyledImg = styled.img`
  cursor: pointer;
  height: 52px;
  width: 46px;
  object-fit: cover;
  border-radius: 4px;
`;

const StyledVideo = styled.video`
  cursor: pointer;
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  background: #e6e6ec;
`;

const Title = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #191b1f;
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
