import React, { useEffect } from "react";
import { VoiceoverHistoryItem } from "../../../../types/actor";
import { convertToRelativeTime } from "../../../../lib/convertToRelativeTime";
import Button, { ButtonThemes } from "../../../../components/Button/Button";
import { downloadFile } from "../../../../lib/downloadFile";
import { ImportLightIcon } from "../../../../components/Icons/ImportLightIcon";
import styled from "styled-components";
import useAudioPlayer from "../../../../hooks/useAudioPlayer";
import { toast } from "react-toastify";
import { PlayIcon } from "../../../../components/Icons/PlayIcon";
import { PauseIcon } from "../../../../components/Icons/PauseIcon";
import { useSelector } from "react-redux";
import { getProfile } from "../../../../redux/reducers/profileReducer";

interface Props {
  historyItem: VoiceoverHistoryItem;
  handleChangeOneAudioPlaying: (output: string) => void;
  oneAudioPlaying: string;
}

const HistoryTabItem = ({ historyItem, handleChangeOneAudioPlaying, oneAudioPlaying }: Props) => {
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;
  const { audioPlayer, playing, onPlaying, toggleAudioPlay, onLoadedMetadata } = useAudioPlayer();

  const handlePlay = () => {
    if (!playing) {
      const mediaFormats = ["mp3", "wav", "ogg", "flac", "aac", "m4a", "weba", "mp4", "webm", "ogv"];

      const extension = historyItem.output.split(".").pop()?.toLowerCase();

      if (!extension || !mediaFormats.includes(extension)) {
        return toast.info("Incorrect format");
      }
    }
    toggleAudioPlay();
    handleChangeOneAudioPlaying(historyItem.output);
  };

  useEffect(() => {
    if (oneAudioPlaying === historyItem.output) return;
    if (playing) {
      toggleAudioPlay();
    }
  }, [oneAudioPlaying]);

  return (
    <Item>
      <Info>
        <ItemType>{historyItem.category}</ItemType>
        <ItemName>{historyItem.title}</ItemName>
        <ItemDate>{convertToRelativeTime(historyItem.insertDateTime)}</ItemDate>
      </Info>
      <Controls>
        <Button
          onClick={handlePlay}
          buttonTheme={ButtonThemes.Transparent}
          icon={<PlayIconWrapper>{playing ? <PauseIcon /> : <PlayIcon />}</PlayIconWrapper>}
          className="play-button"
        />
        <audio
          src={historyItem.output}
          ref={audioPlayer}
          onTimeUpdate={onPlaying}
          onLoadedMetadata={onLoadedMetadata}
        />
        {!isGuest && (
          <Button
            onClick={(e: any) => {
              downloadFile(e, historyItem.output);
            }}
            buttonTheme={ButtonThemes.Transparent}
            icon={<ImportLightIcon />}
            className="download-button"
          />
        )}
      </Controls>
    </Item>
  );
};

const Item = styled.div`
  // width: 130px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  padding: 12px 6px 12px 16px;
  border-radius: 10px;
  border: 1px solid #35354a;
  background: #20202b;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemType = styled.p`
  margin-bottom: 2px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const ItemName = styled.span`
  color: #797979;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  width: 140px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const ItemDate = styled.p`
  color: #ababb9;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  button {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .download-button {
    svg > path {
      fill: none;
      stroke: #ffffff;
    }
  }
`;

const PlayIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;

  & svg {
    width: 10px !important;
    height: 10px !important;
    fill: #000 !important;
  }

  & svg path {
    fill: #000 !important;
  }
`;

export default HistoryTabItem;
