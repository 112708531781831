import styled from "styled-components";

import { brands } from "../../../mocks/pricing";

const Header = () => {
  return (
    <Wrapper>
      <Title>Find the plan that’s right to you </Title>
      <Subtitle>
        Regardless of where you are on your journey as a creative entrepreneur, we have a pricing plan that suits your
        needs
      </Subtitle>
      <TrustedBrandWrapper>
        <span>Our trusted brands</span>
        <BrandRow>
          {brands.map((brand) => (
            <div key={brand.key}>{brand.logo}</div>
          ))}
        </BrandRow>
      </TrustedBrandWrapper>
    </Wrapper>
  );
};

const Title = styled.p`
  font-weight: 700;
  font-size: 48px;
  line-height: 54px;
  text-align: center;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.primaryText};

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    text-align: left;
  }
`;

const Subtitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #6f7074;

  max-width: 655px;
  margin: 0 auto;

  @media (max-width: 768px) {
    line-height: 24px;
    text-align: left;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 54px;

  @media (max-width: 768px) {
    padding-top: 32px;
  }
`;

const TrustedBrandWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #3b3d47;
  }

  @media (max-width: 768px) {
    align-items: start;
  }
`;

const BrandRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;

  & > div {
    display: flex;
  }

  @media (max-width: 768px) {
    justify-content: start;
  }

  @media (max-width: 500px) {
    & > div > svg {
      width: 62px;
    }
  }
`;

export default Header;
