import styled from "styled-components";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "./Tooltip";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { IMobileCard } from "../../../mocks/pricing";
import { PriceSection } from "../../../types/pricing";
import { EmptyIcon } from "../../../components/Icons/Icons";
import { getCheckoutLink, getPlanGroup } from "../DefaultUserPricing";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { getLoadingPlans, getUserPlans } from "../../../redux/reducers/plansReducer";

interface CustomProps {
  activeSection: PriceSection;
  activeGroups: PriceSection[];
}

const MobilePlan: FC<IMobileCard & CustomProps> = ({ header, features, plan, activeGroups, activeSection }) => {
  const dispatch = useDispatch();

  const plans = useSelector(getUserPlans);
  const loadingPlans = useSelector(getLoadingPlans);

  const activeUserPlans = plans.filter((plan) => !plan.isCancelled);

  const isActiveGroup = activeGroups.includes(activeSection);

  const handleChangeSubscription = (newPlanId: number) => {
    if (!activeUserPlans.length) return;
    const existingUserLicenseId =
      activeSection === PriceSection.studio
        ? activeUserPlans[0].userLicenseID
        : activeUserPlans.find((plan) => getPlanGroup(plan) === activeSection)?.userLicenseID;
    if (!existingUserLicenseId) return;
    // dispatch(updateSubscriptionServer({ existingUserLicenseId, newPlanId }));
    dispatch(
      updatePopup({
        popup: Popups.changeSubscriptionPopup,
        status: true,
        prefilled: { existingUserLicenseId, newPlanId },
      }),
    );
  };

  return (
    <Wrapper>
      <Card>
        <Header>{header}</Header>
        <FeatureWrapper>
          {features.map(({ left, right, id }) => {
            return "text" in left ? (
              <Feature key={id}>
                <Left>
                  <p>{left?.text}</p> {left.tooltip && <Tooltip title={left.tooltip} />}
                </Left>
                <Right className="right">
                  <p>{right.text}</p> {right.tooltip && <Tooltip title={right.tooltip} />}
                </Right>
              </Feature>
            ) : (
              <Feature key={id}>
                <IconWrapper>{!("text" in left) && left}</IconWrapper>
                <IconTitle>
                  <p>{right.text}</p>
                  {right.tooltip && <Tooltip title={right.tooltip} />}
                </IconTitle>
              </Feature>
            );
          })}
        </FeatureWrapper>
      </Card>

      {header !== "Free" && plan ? (
        isActiveGroup || (activeSection === PriceSection.studio && activeUserPlans.length) ? (
          activeUserPlans.some((item) => item.productName === plan.title) ? (
            <ActivePlan>
              <span>Active plan</span>
            </ActivePlan>
          ) : loadingPlans.includes(plan.id) ? (
            <CircularProgresWrapper>
              <CircularProgress color="#009af7" />
            </CircularProgresWrapper>
          ) : (
            <Button onClick={() => handleChangeSubscription(plan.id)}>
              <span>Select plan</span>
            </Button>
          )
        ) : (
          <a href={getCheckoutLink(plan.id)} target="_blank" rel="noreferrer" style={{ width: "100%" }}>
            <Button>
              <span>Select plan</span>
            </Button>
          </a>
        )
      ) : (
        <EmptyIcon />
      )}
    </Wrapper>
  );
};

const Button = styled.button`
  padding: 14px 32px;
  width: 100%;
  box-shadow: ${({ theme }) => theme.buttonShadow};
  background: ${({ theme }) => theme.primaryBackground};

  span {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-family: Mulish;
    line-height: 20px; /* 125% */
    letter-spacing: -0.41px;
    background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const ActivePlan = styled.div`
  padding: 14px 32px;
  border-radius: 12px;
  background: ${({ theme }) => theme.primaryBackground};
  border: 1px solid #0063b4 !important;
  text-align: center;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-family: Mulish;
    line-height: 20px; /* 125% */
    letter-spacing: -0.41px;
    background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const CircularProgresWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const IconWrapper = styled.div`
  background: transparent !important;
  height: 24px;
`;

const IconTitle = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.primaryText};
  text-align: left;
  width: 100%;

  &:hover .tooltip {
    opacity: 1;
  }

  @media (max-width: 768px) {
    .tooltip {
      max-width: 120px;
    }
  }
`;

const Feature = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;
  padding: 8px 12px;

  .right {
    .tooltip {
      bottom: -50px;
      left: -150px;
      max-width: 150px;
    }
  }
`;

const Left = styled.div`
  position: relative;

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.primaryText};
  }

  &:hover .tooltip {
    opacity: 1;
  }
`;

const Right = styled.div`
  position: relative;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #4d4e54;
  }

  &:hover .tooltip {
    opacity: 1;
  }
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div:nth-child(odd) {
    background-color: ${({ theme }) => theme.tableBackground};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText};
  padding: 8px 12px;
  border-bottom: 1px solid #dadbdf;
`;

const Card = styled.div`
  border: 1px solid #c8cad3;
  border-radius: 8px;
`;

const Wrapper = styled.div`
  overflow: visible;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-right: 10px;

  .primaryButton {
    width: 100%;
    height: 48px;

    .primaryButtonText {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export default MobilePlan;
