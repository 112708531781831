export const CheckGreenIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#12B76A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0964 7.39016L9.9364 14.3002L8.0364 12.2702C7.6864 11.9402 7.1364 11.9202 6.7364 12.2002C6.3464 12.4902 6.2364 13.0002 6.4764 13.4102L8.7264 17.0702C8.9464 17.4102 9.3264 17.6202 9.7564 17.6202C10.1664 17.6202 10.5564 17.4102 10.7764 17.0702C11.1364 16.6002 18.0064 8.41016 18.0064 8.41016C18.9064 7.49016 17.8164 6.68016 17.0964 7.38016V7.39016Z"
      fill="white"
    />
  </svg>
);
