import styled from "styled-components";

import SmartLink from "../components/SmartLink/SmartLink";
import TooltipCell from "../modules/PricingPage3/components/TooltipCell";
import {
  LabsIcon2626,
  DubbingIcon2626,
  AiVidiosIcon2626,
  FaceformIcon2626,
  FeaturesIcon2626,
  MicIcon2626Landing,
  VideoIcon2626Landing,
  ImageIcon2424Landing,
} from "../components/Icons/Icons";
import { IExternalLinks } from "../lib/routeUtils";
import { Link } from "react-router-dom";

const ComingSoon = styled.span`
  margin-left: 24px;
  padding: 4px 10px;
  background: #e66a63;
  color: #fff;
  border-radius: 40px;
  font-weight: 700;
  font-size: 10px;
  line-height: 200%;
  letter-spacing: -0.02em;

  @media (max-width: 925px) {
    padding: 4px;
    font-weight: 400;
    font-size: 8px;
  }

  @media (max-width: 830px) {
    padding: 4px;
    font-weight: 400;
    font-size: 6px;
  }
  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 8px;
  }
`;

const NewSpan = styled.span`
  border-radius: 40px;
  padding: 4px 10px;
  background: #007aff;
  font-weight: 700;
  font-size: 10px;
  line-height: 200%;
  letter-spacing: -0.02em;
  color: #fff;

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 8px;
    padding: 2px 4px;
  }
`;

const CircleCheckedIcon = () => {
  return (
    <img className="checkedIcon" alt="checked icon" src="/images/icons/circleCheckedIcon.svg" width="16" height="17" />
  );
};

const PrimarryCell = styled.span`
  font-weight: 600;
  background: #0063b4;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const LabsChild = styled.span`
  margin-left: 10px;

  @media (max-width: 767px) {
  }
`;

const LabsGptChild = styled.span`
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    display: inline-block;
    width: 24px;
    height: auto;
  }

  @media (max-width: 767px) {
    img {
      width: 20px;
    }
  }
`;
const SynthesysX = () => <SmartLink url={IExternalLinks.xPage}>Synthesys X</SmartLink>;

enum Columns {
  "feature" = "feature",
  "free" = "free",
  "personal" = "personal",
  "creator" = "creator",
  "business_unlimited" = "business unlimited",
}

export const defaultColumns = [
  {
    id: 1,
    header: "Features",
    accessor: Columns.feature,
    planType: "",
    buttonColor: "",
    icon: <FeaturesIcon2626 />,
  },
  {
    id: 2,
    header: "Free",
    accessor: Columns.free,
    yearlyPrice: "0",
    monthlyPrice: "0",
    planType: "",
    buttonColor: "pricingOutlineBlack",
  },
  {
    id: 3,
    header: "Personal",
    accessor: Columns.personal,
    yearlyPrice: "20/m",
    monthlyPrice: "29/m",
    yearlyTrigger: "#pkmodale895f1f871ddfd22553c80726188c551df2b4294",
    monthlyTrigger: "#pkmodal029b7f78b3903a8fa53c0f93a4412dcf5844896c",
    buttonColor: "pricingBlack",
    planType: "PERSONAL",
  },
  {
    id: 4,
    header: "Creator",
    accessor: Columns.creator,
    yearlyPrice: "41/m",
    monthlyPrice: "59/m",
    yearlyTrigger: "#pkmodal61112bdff3e11665c3400c2207075076526de8ba",
    monthlyTrigger: "#pkmodal7ff056c2adcd0049d1257aedd48d92b37950df38",
    buttonColor: "pricingBlack",
    planType: "CREATOR",
  },
  {
    id: 5,
    header: "Business Unlimited",
    accessor: Columns.business_unlimited,
    yearlyPrice: "69/m",
    monthlyPrice: "99/m",
    yearlyTrigger: "#pkmodal3052358a82dfc5607a2a167def3a5a20607d78bd",
    monthlyTrigger: "#pkmodal29ace0084cb963d1f6cacfa5ede1b98e806091cc",
    buttonColor: "pricingBlue",
    planType: "BUSINESS UNLIMITED",
  },
];

export const creditsColumns = [
  {
    id: 1,
    header: "Credits",
  },
  {
    id: 2,
    header: "125 one-time credits",
    tooltip: (
      <>
        Each plan includes 125 Unified Credits per month, with 1 credit equal to 1 second of generation time. 1 Image
        generation uses 1 credit, while a 4-second AI video consumes 40 credits. Credits automatically renew every
        month. For more details, please review our <Link to={IExternalLinks.terms}>Fair Use Policy</Link>
      </>
    ),
    priority: "",
  },
  {
    id: 3,
    header: "900 credits/month",
    tooltip: (
      <>
        Each plan includes 900 Unified Credits per month, with 1 credit equal to 1 second of generation time. 1 Image
        generation uses 1 credit, while a 4-second AI video consumes 40 credits. Credits automatically renew every
        month. For more details, please review our <Link to={IExternalLinks.terms}>Fair Use Policy</Link>
      </>
    ),
    priority: "",
  },
  {
    id: 4,
    header: "2400 credits/month",
    tooltip: (
      <>
        Each plan includes 2400 Unified Credits per month, with 1 credit equal to 1 second of generation time. 1 Image
        generation uses 1 credit, while a 4-second AI video consumes 40 credits. Credits automatically renew every
        month. For more details, please review our <Link to={IExternalLinks.terms}>Fair Use Policy</Link>
      </>
    ),
    priority: "",
  },
  {
    id: 5,
    header: "Unlimited credits/month",
    tooltip: (
      <>
        Each plan includes Unlimited Unified Credits per month, with 1 credit equal to 1 second of generation time. 1
        Image generation uses 1 credit, while a 4-second AI video consumes 40 credits. Credits automatically renew every
        month. For more details, please review our <Link to={IExternalLinks.terms}>Fair Use Policy</Link>
      </>
    ),
    priority: "",
  },
];

export const renderSpeedColumns = [
  {
    id: 1,
    header: "Render Speed for all services",
  },
  {
    id: 2,
    header: "Low Priority Rendering",
    tooltip: "",
    priority: "",
  },
  {
    id: 3,
    header: "Basic Priority Rendering",
    tooltip: "",
    priority: "",
  },
  {
    id: 4,
    header: "Medium Priority Rendering",
    tooltip: "",
    priority: "",
  },
  {
    id: 5,
    header: "High Priority Rendering",
    tooltip: "",
    priority: "",
  },
];

export const voicesTable = {
  heading: {
    icon: <MicIcon2626Landing />,
    title: "AI Voices",
  },
  columns: defaultColumns,
  table: [
    {
      id: 2,
      [Columns.feature]: "Ultra Life-like Voices",
      [Columns.free]: "700",
      [Columns.personal]: "700",
      [Columns.creator]: "700",
      [Columns.business_unlimited]: "700",
    },
    {
      id: 4,
      [Columns.feature]: "Languages",
      [Columns.free]: "140",
      [Columns.personal]: "140",
      [Columns.creator]: "140",
      [Columns.business_unlimited]: "140",
    },
    {
      id: 7,
      [Columns.feature]: (
        <TooltipCell text="Multi-Voice functionality" tooltip="Add multiple voice actors in a single voiceover" />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 8,
      [Columns.feature]: <TooltipCell text="Speech to Speech" tooltip="Change the voice of an existing voiceover" />,
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 9,
      [Columns.feature]: (
        <TooltipCell text="Speech Translation" tooltip="Translate an existing voiceover in a different language" />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
  ],
};

export const videoTable = {
  heading: {
    icon: <VideoIcon2626Landing />,
    title: "AI Humans",
  },
  columns: defaultColumns,
  table: [
    {
      id: 2,
      [Columns.feature]: "Premium Actors",
      [Columns.free]: "70+",
      [Columns.personal]: "70+",
      [Columns.creator]: "70+",
      [Columns.business_unlimited]: "70+",
    },
    {
      id: 3,
      [Columns.feature]: "Ultra Life-like Voices",
      [Columns.free]: "550+",
      [Columns.personal]: "550+",
      [Columns.creator]: "550+",
      [Columns.business_unlimited]: "550+",
    },
    {
      id: 5,
      [Columns.feature]: "Languages",
      [Columns.free]: "140",
      [Columns.personal]: "140",
      [Columns.creator]: "140",
      [Columns.business_unlimited]: "140",
    },
    {
      id: 6,
      [Columns.feature]: "Slides per video",
      [Columns.free]: "3 Slides",
      [Columns.personal]: "6 Slides",
      [Columns.creator]: "12 Slides",
      [Columns.business_unlimited]: <PrimarryCell>30 Slides</PrimarryCell>,
    },
    {
      id: 12,
      [Columns.feature]: "Video quality",
      [Columns.free]: "HD (720p)",
      [Columns.personal]: "Full HD (1080p)",
      [Columns.creator]: "Full HD (1080p)",
      [Columns.business_unlimited]: "Full HD (1080p)",
    },
    {
      id: 18,
      [Columns.feature]: (
        <TooltipCell text="Talking Photos" tooltip="Convert an existing image into a talking avatar" />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 17,
      [Columns.feature]: <TooltipCell text="Instant Face Change" tooltip="Change the face of an existing avatar" />,
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 13,
      [Columns.feature]: (
        <TooltipCell
          text="Upload voice to sync with any avatar"
          tooltip="Upload existing voiceover file and sync it with any avatar"
        />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 14,
      [Columns.feature]: "Transform your PDFs into Videos",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
  ],
};

export const dubbingTable = {
  heading: {
    icon: <DubbingIcon2626 />,
    title: "AI Dubbing",
  },
  columns: defaultColumns,
  table: [
    {
      id: 1,
      [Columns.feature]: "Video & Audio Translation",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 2,
      [Columns.feature]: "AI Translation  into 30+ Languages",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 3,
      [Columns.feature]: "Lip-sync Actors in any language",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 4,
      [Columns.feature]: "Add fixed actors to videos without faces",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 5,
      [Columns.feature]: "Voice Cloned Enabled",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 6,
      [Columns.feature]: "Add captions/subtitles",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 7,
      [Columns.feature]: (
        <TooltipCell
          text="Transfer Voice Cloning Across Languages"
          tooltip="Use your cloned voice in the translated video"
        />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 8,
      [Columns.feature]: (
        <>
          Multispeaker <ComingSoon>Coming Soon</ComingSoon>
        </>
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
  ],
};

export const imageTable = {
  heading: {
    icon: <ImageIcon2424Landing />,
    title: "AI Images ",
  },
  columns: defaultColumns,
  table: [
    {
      id: 1,
      [Columns.feature]: "Upscale",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 2,
      [Columns.feature]: "Create Variations",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 3,
      [Columns.feature]: "Consistent Story Images",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 4,
      [Columns.feature]: "Generate Personalized Characters",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 5,
      [Columns.feature]: "Pose Control",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
  ],
};

export const videoComparisonTable = {
  heading: {
    icon: <AiVidiosIcon2626 />,
    title: (
      <>
        AI Video <NewSpan>NEW</NewSpan>
      </>
    ),
  },
  columns: defaultColumns,
  table: [
    {
      id: 2,
      [Columns.feature]: "Prompt & Image to Video",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 3,
      [Columns.feature]: "Create Video Stories",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 4,
      [Columns.feature]: "Pose Control",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 5,
      [Columns.feature]: "Consistent Story Videos",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 6,
      [Columns.feature]: "Lip-Sync AI Generated Actors",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
  ],
};

export const faceformTable = {
  heading: {
    icon: <FaceformIcon2626 />,
    title: (
      <>
        Faceform <NewSpan>NEW</NewSpan>
      </>
    ),
  },
  columns: defaultColumns,
  table: [
    {
      id: 2,
      [Columns.feature]: "Transform video into another character with just one image",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 3,
      [Columns.feature]: "Swap faces seamlessly in any video or image",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 4,
      [Columns.feature]: "Replicate Media: Create new content from existing stock footage.",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 5,
      [Columns.feature]: "Convert any image to a talking person",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
  ],
};

export const labsTable = {
  heading: {
    icon: <LabsIcon2626 />,
    title: "Synthesys Labs",
  },
  columns: defaultColumns,
  table: [
    {
      id: 2,
      [Columns.feature]: (
        <>
          <LabsGptChild>
            <img src="/images/pricing/canvaIcon.webp" alt="canva" />
            <TooltipCell
              text="Canva AI Humans Intergration"
              tooltip="Create videos with Avatars directly inside of Canva"
            />
          </LabsGptChild>
        </>
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 3,
      [Columns.feature]: (
        <>
          <LabsGptChild>
            <img src="/images/pricing/canvaIcon.webp" alt="canva" />
            <TooltipCell
              text="Canva AI Video Intergration"
              tooltip="Create video stories using Synthesys AI Videos directly inside of Canva"
            />
          </LabsGptChild>
        </>
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 4,
      [Columns.feature]: (
        <>
          <LabsGptChild>
            <img src="/images/pricing/chat-gpt.webp" alt="chat-gpt" />
            <TooltipCell
              text="Chat GPT AI Voices"
              tooltip="Create voiceovers directly inside your OpenAI account using Synthesys AI Voices"
            />
          </LabsGptChild>
        </>
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
  ],
};
