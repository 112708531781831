import styled from "styled-components";
import { useState } from "react";
import { formatDistance } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow } from "@mui/material";

import MenuItem from "../../../components/ActionMenu/MenuItem";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import Textfield, { TextfieldVariant } from "../../../components/Textfield/Textfield";
import {
  DownloadIcon,
  EditIcon,
  HistoryIcon,
  LikeActiveIcon,
  LikeIcon,
  OpenIcon,
  OptionsIcon,
  TrashIcon,
} from "../../../components/Icons/Icons";
import { downloadFile } from "../../../lib/downloadFile";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { ConfirmationDelete, DeleteType } from "../../../types/confirmationDelete";
import { favoriteHistoryProjectServer, updateHistoryProjectServer } from "../../../redux/actions/profileActions";
import { UserHistoryProjectPrefilled } from "../../../types/userHistoryProjects";
import { clearCurrentHumansProject, getHumansProject } from "../../../redux/actions/humansProjectActions";
import { SignUpPopupTypes } from "../../../types/signUpPopupTypes";
import { getProfile } from "../../../redux/reducers/profileReducer";
import { useNavigate } from "react-router-dom";

interface Props {
  id: number;
  title: string;
  output: string;
  active: boolean;
  category: string;
  insertDateTime: string;
  type: string;
  coverImage: string;
  favorite: boolean | null;
  pageSize: number;
  menuOpen: number | null;
  menuOptions: string[];
  setActiveProject: any;
  option: string;
  setMenuOpen: (menuOpen: number | null) => void;
  handleActiveProject: (e: any) => void;
}

const HistoryTableRow = ({
  title,
  id,
  output,
  active,
  category,
  insertDateTime,
  type,
  coverImage,
  favorite,
  pageSize,
  menuOpen,
  menuOptions,
  option,
  setMenuOpen,
  setActiveProject,
  handleActiveProject,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;

  const [projectName, setProjectName] = useState<string>(title);

  const handleChange = (e: any) => setProjectName(e.target.value);

  const handleOpenMenu = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(menuOpen === id ? null : id);
  };

  const handleCloseMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(null);
  };

  const renameProject = (e: any) => {
    handleActiveProject(e);
    handleCloseMenu(e);
  };

  const handleKeyPress = (e: any, type?: "blur") => {
    if (e.code === "Enter" || type) {
      if (!id || !category) throw Error("Project entity is missing while renaming");
      if (id && category && projectName !== title) {
        dispatch(
          updateHistoryProjectServer({
            id,
            category,
            newTitle: projectName,
            oldTitle: title,
          }),
        );
      }
      setActiveProject(null);
    }
  };

  const handleFavorite = (e: any) => {
    if (!id || !category) throw Error("Project entity is missing when adding to favorites");

    e.stopPropagation();
    e.preventDefault();

    dispatch(favoriteHistoryProjectServer({ id, category, newFavorite: !favorite, oldFavorite: favorite }));
  };

  const handleDeleteClick = (e: any) => {
    if (!id) {
      throw Error("Project entity is missing while deleting");
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id,
      title: "Are you sure you want to delete project?",
      description: "The project will be removed and the action cannot be undone",
      typeId: 0,
      type: DeleteType.HistoryProject,
      pageSize,
      category,
      option,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const actionMenu = [
    {
      icon: <OptionsIcon />,
      options: menuOptions?.map((option) => ({
        name: option,
        icon: recogniseIcon(option),
      })),
    },
  ];

  const handlePopupOpen = () => {
    const prefilled: UserHistoryProjectPrefilled = {
      type: DeleteType.HistoryProject,
      typeId: 0,
      pageSize,
      category,
      option,
    };

    dispatch(clearCurrentHumansProject());
    dispatch(getHumansProject({ projectId: id, title, coverImage, insertDateTime, output }));

    dispatch(
      updatePopup({
        popup: Popups.historyPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleDownload = (e: any) => {
    if (!id) {
      throw Error("The ID video is missing when downloading");
    }

    if (isGuest && category === "AI Video") {
      const prefilled: SignUpPopupTypes = {
        type: "Video",
        link: {
          projectName: "ai-images",
          projectId: id,
        },
      };

      dispatch(
        updatePopup({
          popup: Popups.signUpPopup,
          status: true,
          prefilled,
        }),
      );
      return;
    }

    downloadFile(e, output || "");
  };

  const listOfPopupCategories = ["AI Human"];

  const isAudioType = type === "Audio";
  const isPopupCategory = listOfPopupCategories.includes(category);

  return (
    <StyledTableRow
      isPopupCategory={isPopupCategory}
      onClick={() => isPopupCategory && !active && !menuOpen && handlePopupOpen()}
    >
      <StyledTableCell component="th" scope="row">
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          {isAudioType ? <HistoryIcon /> : <StyledImg src={coverImage ? coverImage : "/images/placeholder.svg"} />}
          {!active ? (
            <>
              <span>{title}</span>
            </>
          ) : (
            <Textfield
              autoFocus
              variant={TextfieldVariant.project}
              value={projectName}
              placeholder="Enter project name"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              onBlur={(e) => handleKeyPress(e, "blur")}
            />
          )}
        </div>
      </StyledTableCell>
      <StyledTableCell align="left">{type}</StyledTableCell>
      <StyledTableCell align="left">{formatDistance(new Date(insertDateTime + "Z"), new Date())} ago</StyledTableCell>
      <StyledTableCell align="center">
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <IconButton
            iconButtonTheme={IconButtonThemes.Transparent}
            icon={!favorite ? <LikeIcon /> : <LikeActiveIcon />}
            onClick={(e: any) => handleFavorite(e)}
          />
        </div>
      </StyledTableCell>
      <StyledTableCell align="right" style={{ position: "relative" }}>
        <ActionWrapper>
          {actionMenu.map((m, order) => (
            <ActionMenu
              key={order}
              position={{
                x: "left",
                y: "bottom",
              }}
              open={menuOpen === id}
              menuStyle={{ top: "-12px", right: "45px", padding: "4px 4px 4px 12px" }}
              handleClose={handleCloseMenu}
              trigger={
                <IconButton
                  iconButtonTheme={IconButtonThemes.Transparent}
                  icon={m.icon}
                  onClick={(e: any) => handleOpenMenu(e, id)}
                />
              }
            >
              {m.options && (
                <MenuWrapper>
                  {m.options.map((option, index) => (
                    <MenuItem
                      key={index}
                      icon={option.icon}
                      onClick={(e: any) => {
                        e.preventDefault();
                        option.name === "Download" && handleDownload(e);
                        option.name === "Rename" && renameProject(e);
                        option.name === "Delete" && handleDeleteClick(e);
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuWrapper>
              )}
            </ActionMenu>
          ))}
        </ActionWrapper>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const recogniseIcon = (name: string) => {
  switch (name) {
    case "Download":
      return <DownloadIcon />;
    case "Rename":
      return <EditIcon />;
    case "Open this video":
      return <OpenIcon />;
    case "Delete":
      return <TrashIcon />;
    default:
      return <></>;
  }
};

const StyledImg = styled("img")`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const StyledTableRow = styled(TableRow)<{ isPopupCategory?: boolean }>`
  & td:last-of-type > div:hover {
    background-color: ${({ theme }) => theme.primaryBackground};
    border-radius: 4px;
  }

  ${({ isPopupCategory }) =>
    isPopupCategory &&
    `
    cursor: pointer;;
  `}
`;

const StyledTableCell = styled(TableCell)`
  padding: 0 12px;
  height: 47px;
  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
  color: ${({ theme }) => theme.tableText};
  border-bottom: 1px solid ${({ theme }) => theme.tableDivider};

  &:first-of-type span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 270px;
    white-space: nowrap;
    min-width: 270px;
  }

  & input {
    box-shadow: none;
    border-radius: 8px;
    max-width: 270px;
    border: 1px solid ${({ theme }) => theme.tableDivider};
  }
`;

const MenuWrapper = styled("div")`
  width: 140px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const ActionWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: auto;

  span {
    button {
      width: 40px;
      height: 40px;
      max-width: 40px;
      border: none;
      box-shadow: none;
      border-radius: 6px;

      svg > path {
        fill: ${({ theme }) => theme.tableTitleText};
      }
    }
  }

  .menu-wrapper {
    border: none;
    max-width: 180px;
    background: ${({ theme }) => theme.activeMenu};

    div {
      color: #ffffff;
      -webkit-text-fill-color: unset;
      background: none;
      padding: 3px 0px;
      font-weight: 500;
      svg > path {
        fill: #ffffff;
      }
    }
  }
`;

export default HistoryTableRow;
